@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
/* @import 'react-checkbox-tree/lib/react-checkbox-tree.css'; */

/* Celias font */

@font-face {
    font-family: "Celias-medium";
    src: local("Celias-Medium"), url(../fonts/Celias-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Celias";
    src: local("Celias"), url(../fonts/Celias.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Light";
    src: local("Celias-Light"), url(../fonts/Celias-Light.ttf) format("truetype");
}

/* Poppins font */

@font-face {
    font-family: "Poppins-regular";
    src: local("Poppins-regular"), url(../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins-bold";
    src: local("Poppins-bold"), url(../fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins-semi-bold";
    src: local("Poppins-semi-bold"), url(../fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins-Medium"), url(../fonts/Poppins-Medium.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Poppins-light";
    src: local("Poppins-light"), url(../fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
    font-family: "DMSans";
    src: local("DMSans"), url(../fonts/DMSans-18pt-Black.ttf) format("truetype");
}

@font-face {
    font-family: "Noto";
    src: local("Noto-medium"), url(../fonts/Noto_Sans/NotoSans-Medium.ttf) format("truetype");
}


:root {
    scroll-behavior: smooth;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    /* overscroll-behavior-y: none; */
    scroll-behavior: smooth;
}

#root {
    --prime1: #007dae;
    --prime2: #00ac0b;
    --prime3: #e60000;
    --prime4: #0017e6;
    --light-color1: #ffdded;
    --light-color2: #d4e6f8;
    --light-color: #e1e7f3;
    /* /// */
    --color-text: #616161;
    --color-text-btn: #ffffff;
    --card1-gradient-color1: #f12711;
    --card1-gradient-color2: #f5af19;
    --card2-gradient-color1: #7f00ff;
    --card2-gradient-color2: #e100ff;
    --card3-gradient-color1: #3f2b96;
    --card3-gradient-color2: #a8c0ff;
    --card4-gradient-color1: #11998e;
    --card4-gradient-color2: #e8ffe9;
}

html {
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {

    .bottom-fix-modal {
        position: fixed;
        bottom: 45px;
        left: 0;
        z-index: 6;
    }

    html,
    body {
        scrollbar-width: none !important;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    ::-webkit-scrollbar,
    html::-webkit-scrollbar,
    body::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
}

.noto-font {
    font-family: "Noto";
}

.DMSans {
    font-family: "DMSans";
}

.page-body {
    /* height: calc(100% - 50px) !important; */
    scroll-behavior: smooth !important;
}

body {
    font-family: "Roboto", Arial, sans-serif;
    /* background-color: #f6f9ff; */
    background-color: #f5f6f8 !important;
    font-weight: 400;
    overflow-x: hidden;
    letter-spacing: 0.3px !important;
}

.bg-body-color {
    /* background-color: #E5E9F1 !important; */
    background-color: #f5f6f8 !important;
}

.bg-light-bule {
    background-color: #e1e7f3 !important;
}

.page-main {
    height: 100% !important;
    min-height: 85vh !important;
    /* margin-top: 6px; */
    /* width: 100vw; */
    /* background-color: #f6f9ff !important; */
}

.custom-white {
    background-color: #f4f5fa;
}

.bg-color {
    background-color: #f3f3f3 !important;
}

.page-content-home {
    margin-top: 105px;
}

.page-content {
    width: 100vw;
    padding-top: 52px;
    /* padding-bottom: 50px; */
}

.page-footer {
    position: fixed;
    width: -moz-available;
    bottom: 0px;
}

.main {
    /* margin-top: 1rem; */
    width: 100vw;
}

.location-name {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;

}


small,
.small {
    font-size: 11px !important;
    font-family: "Celias-Light" !important;
    font-weight: 400 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Celias-Medium" !important;
    color: #000 !important;
    /* letter-spacing: 0.3px !important; */
    font-weight: 400 !important;
    text-transform: capitalize !important;

}

h5 {
    margin: 0;
    font-size: 17px !important;
}

/* h6 {
    font-size: 14px !important;
    letter-spacing: 0.5px;
    margin: 0;
    margin-bottom: 0px;
} */

.celias-font {
    font-family: "Celias" !important;

}

a {
    color: #212529 !important;
    text-decoration: none !important;
}

p {
    font-size: 12px;
    margin: 0px !important;
    color: #777;
    letter-spacing: 0.3px;
    font-weight: 400 !important;
    font-family: 'Celias';
}

p b {
    color: #333 !important;
}

p small,
small,
.small {
    letter-spacing: 0.2px;
}

.w-80 {
    width: 80%;
}

.w-45 {
    width: 45%;
}

.w-55 {
    width: 55%;
}

.overflowX-hidden {
    overflow-x: hidden !important;
}

.width-20 {
    width: 20%;
}

.text-darkcyan {
    color: darkcyan !important;
}

.text-bold {
    font-weight: 600 !important;
}

.border-radius {
    border-radius: 50%;
}

.brand .card,
.products .card {
    box-shadow: none !important;
}

.absoluate {
    position: absolute !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 0.5rem;
}

.pl-2 {
    padding-left: 1rem;
}

.pl-3 {
    padding-left: 1.5rem;
}

.pl-4 {
    padding-left: 2rem;
}

.pl-5 {
    padding-left: 2.5rem;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-02 {
    padding-right: 2px !important;
}

.pr-1 {
    padding-right: 0.5rem;
}

.pr-2 {
    padding-right: 1rem;
}

.pr-3 {
    padding-right: 1.5rem;
}

.pr-4 {
    padding-right: 2rem;
}

.pr-5 {
    padding-right: 2.5rem;
}

.pt-1 {
    padding-top: 0.5rem;
}

.pt-2 {
    padding-top: 1rem;
}

.pt-3 {
    padding-top: 1.5rem;
}

.pt-4 {
    padding-top: 2rem;
}

.mt-5 {
    margin-top: 2.5rem;
}

.mt-6 {
    margin-top: 3rem;
}

.mt-6rem {
    margin-top: 6rem;
}


.mt-7 {
    margin-top: 4rem !important;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.mb-5 {
    margin-bottom: 2.5rem;
}

.mb-6 {
    margin-bottom: 3rem;
}

.mb-10 {
    margin-bottom: 5rem !important;
}

.mb-2px {
    margin-bottom: 2px;
}

/* Margin */

.ml-1 {
    margin-left: 0.5rem;
}

.ml-2 {
    margin-left: 1rem;
}

.ml-3 {
    margin-left: 1.5rem;
}

.ml-4 {
    margin-left: 2rem;
}

.ml-5 {
    margin-left: 2.5rem;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-1 {
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-3 {
    margin-right: 1.5rem;
}

.mr-4 {
    margin-right: 2rem;
}

.mr-5 {
    margin-right: 2.5rem;
}

.mt-1 {
    margin-top: 0.5rem;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.5rem;
}

.mt-4 {
    margin-top: 2rem;
}

.mt-5 {
    margin-top: 2.5rem;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.mb-5 {
    margin-bottom: 2.5rem;
}

.bg-warning {
    background-color: #f4ad02 !important;
}

.bg-fff {
    background-color: #fff !important;
}

.bg-lightPage {
    background: #f3f3f3 !important;
    height: 90vh !important;
}

.bg-light {
    background: #f3f3f3 !important;
}

.bg-leaf-img {
    background-color: #d7fbdd;
    /* overflow: hidden; */
}

.bg-light-color {
    /* background-color: #e1e7f3; */
    background-color: #f1f1f1;
}

.bg-red_leaf-img {
    background-color: #ffc7cc;
    height: 120px;
    border-radius: 6px;
}

.bg-red_leaf {
    background-color: #ffc7cc;
    height: 126px;
    border-radius: 6px;
}

.bg-darkgray {
    background-color: #e6e5e5 !important;
}

.scroll-active .active {
    color: var(--prime2) !important;
    font-weight: 600;
    font-size: 15px !important;
    border-right: 2px solid var(--prime2);
}

.scroll-active {
    width: 100% !important;
}

.leaf-img {
    height: 130px;
    object-fit: contain;
    /* right: 0;
    position: relative; */
    border-radius: 6px;
}

.red_leaf-img {
    border-radius: 6px;
    height: 120px;
    object-fit: contain;
}

.bg-red_leaf-img .img-responsive {
    height: 70px !important;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.bg-halfwhite .activeTitle {
    background-color: #fff !important;
    color: #222 !important;
    padding: 16px 10px !important;
    font-size: 11px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 600;
}

.bg-prime1 {
    background-color: #0057ff !important;
}

.bg-disable {
    background-color: #464646 !important;
}

.check-button {
    margin: 8px 5px !important;
}

.check-button input {
    position: absolute;
    display: none;
    color: #333;
}

.check-button span {
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 4px 14px !important;
    border-radius: 16px;
    font-size: 12px;
}

.check-button input:checked+span {
    color: #fff;
    background-color: #6691bb;
    padding: 4px 14px !important;
    border: 1px solid #6691bb;
}


.check-button input:checked+span::before {
    /* content: '✓'; */
    padding-right: 3px;
}

.radio-button {
    margin: 8px 5px !important;
}

.radio-button input {
    position: absolute;
    display: none;
    color: #333;
}

.radio-button span {
    color: gray;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 6px 14px !important;
    border-radius: 6px;
    font-size: 12px;
}

.radio-button input:checked+span {
    color: #00ac0b;
    background-color: #fff;
    padding: 6px 14px !important;
    border: 1px solid #00ac0b;
    font-weight: bold;
}

.filter-radio-button {
    margin: 11px 5px !important;
}

.filter-radio-button input {
    position: absolute;
    display: none;
    color: #333;
}

.filter-radio-button span {
    color: gray;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 6px 14px !important;
    border-radius: 6px;
    font-size: 12px;
}

.filter-radio-button input:checked+span {
    color: #ffffff;
    background-color: #777;
    padding: 6px 14px !important;
    border: 1px solid #777;
    font-weight: bold;
}

.check-button input:checked+span::before {
    /* content: '✓'; */
    padding-right: 3px;
}

.bg-gray {
    background-color: #dfdfdf !important;
}

.bg-custom-gray {
    background-color: #e2e3eb !important;
}

.bg-halfwhite {
    background-color: #f0f0f0;
}

.bg-halfwhite .title {
    padding: 16px 10px !important;
    font-size: 11px;
    color: #777 !important;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

}

.text-prime1 {
    color: rgb(0, 87, 255) !important;
}

.text-black {
    color: #343a40 !important;
}

.text-black-color {
    color: #000 !important;
}

.text-dark {
    color: #000 !important;
    font-weight: 600 !important;

}

.text-gray {
    color: gray !important;
}


.text-gold {
    color: #ffd700 !important;
}


.text-prime2 {
    color: var(--prime2) !important;
}

.text-prime3 {
    color: var(--prime3) !important;
}

.text-prime4 {
    color: var(--prime4) !important;
}

.flex {
    display: flex;
}

.MuiDrawer-paperAnchorRight {
    width: 35%;
    padding: 30px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* .MuiDrawer-paperAnchorRight .row {
    position: sticky !important;
    position: -webkit-sticky;
} */

.MuiDrawer-paperAnchorRight .variants {
    /* min-height: auto;
    max-height: ; */
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.MuiDrawer-paperAnchorRight::-webkit-scrollbar {
    display: none;
}

.MuiDrawer-paperAnchorBottom {
    width: 100%;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: auto !important;
    overflow: hidden !important;
    max-height: 90vh !important;
}

.variant-modal .MuiDialog-paper {
    min-height: 30%;
    max-height: 100%;
    overflow-y: scroll !important;
    scrollbar-width: none;
}

.MuiDrawer-paperAnchorBottom::-webkit-scrollbar {
    display: none;
}

.MuiDrawer-paperAnchorBottom .row {
    position: sticky !important;
    position: -webkit-sticky;
}

.MuiDrawer-paperAnchorBottom .variants {
    /* min-height: auto;
    max-height: 500px; */
    width: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: #005d86 !important;
    color: #fff;
    font-weight: 600;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 4px 4px 0;
    height: 26px;
    width: 26px;
}

.MuiCollapse-root {
    z-index: 0;
    position: relative;
}

.product_image {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

/* Form class */

.form-control {
    display: block;
    width: 100%;
    height: 38px;
    /* margin-top: 4px; */
    height: calc(2.25rem + 3px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    /* background-color: #fff; */
    background-clip: padding-box;
    border-radius: 10px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    /* border-color: #ccc; */
    /* box-shadow: 0 0 8px rgba(130, 130, 130, 0.25) ; */
    box-shadow: none;
}

.justify-center {
    justify-content: center;
}

.card {
    border-radius: 0px;
    border: none;
    background-color: #fff !important;
}

.order_card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
    border-radius: 0.25rem;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    /* box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.btn {
    /* line-height: 1 !important; */
    border-radius: 5px;
    padding: 5px 16px;
    font-size: 12px;
    background-position: center;
    /* transition: background 0.8s; */
    position: relative;
    overflow: hidden;
    text-transform: uppercase !important;
    /* font-weight: 600 !important; */
    font-family: "Celias-medium" !important;
    transition: none !important;
    /* z-index: 999; */
}

.btn:before {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
}

.btn:focus:before {
    /* transition: all 0.5s ease-out; */
    opacity: 0;
    width: 160px;
    height: 160px;
    margin-top: -80px;
    margin-left: -80px;
    box-shadow: none !important;
}

.btn:active {
    box-shadow: inset 2px 2px 5px #bdbdbd;
}

.btn:hover {
    transition-duration: 0.1s;
    /* background-color: #bdbdbd; */
}

.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 12px !important;
    border-radius: 0.2rem !important;
}

.btn-circle {
    background-color: #ffffff;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.end {
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

.align-center {
    align-items: center !important;
}

.align-end {
    align-items: flex-end !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

/* .modal-backdrop {
    z-index: 800 !important;
    position: unset !important;
} */

/* .modal-content {
    margin: 2px auto;
    z-index: 1500 !important;
} */

/* .LocationPicker .modal-backdrop {
    z-index: 0 !important;
    position: unset !important;
} */

.loader-spinner {
    border: 2px solid #e5e5e5;
    border-top: 2px solid rgb(0, 87, 255);
    border-radius: 50%;
    /* width: 16px; */
    width: 18px;
    height: 16px;
    animation: spin 1s linear infinite;
    padding-top: 2px;
    padding-bottom: 2px;
}

.vendor-list-loader-spinner {
    border: 2px solid #e5e5e5;
    border-top: 2px solid rgb(0, 87, 255);
    border-radius: 50%;
    width: 16px !important;
    /* width: 18px; */
    height: 16px;
    animation: spin 1s linear infinite;
    padding-top: 2px;
    padding-bottom: 2px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cursor {
    cursor: pointer;
    /* transition: background 0.8s;
    background-position: center; */
}

.cursor:active {
    /* transform: scale(0.9);  */
    /* animation: pulse-black 2s linear; */
    /* box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.15);
    -webkit-transition: background 1s color 1s;
-moz-transition: background 1s color 1s;
transition: background 1s color 1s; */
}

.cursor-no-animate {
    cursor: pointer;
}

.btn-prime {
    background-color: var(--prime1);
    color: #fff;
    font-weight: 600 !important;
    cursor: pointer;
}

.wishlist-btn {
    width: 30px;
    height: 30px;
    /* background-color: #3c3c3c8f; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 12px;
}

.wishlist-btn-white {
    width: 30px;
    height: 30px;
    background-color: #ffffff8f;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 12px;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}


.cursor-none {
    cursor: none;
}

textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    font-size: 13px;
}

.border-right {
    border-right: 1px solid #ced4da;
    height: 100% !important;
}

.border {
    border: 1px solid #ccc !important;
    border-radius: 5px;
}

.border-gray {
    border: 1px solid #cccccc !important;
    border-radius: 5px;
}

.brand_ecom {
    border-radius: 6px;
}

.oneline {
    /* height: 18px !important; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.double {
    /* height: 35px !important; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.light-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.btn-sm {
    letter-spacing: 0.3px;
    text-transform: capitalize;
    font-weight: 500 !important;
    /* color: #fff !important; */
}

.btn-sm:focus {
    box-shadow: none !important;
}

.marg {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ewLYuJ,
.react-simple-image,
.hZyJsX {
    font-size: 30px !important;
    padding: 0 10px !important;
}

.social-icons .ReactSwipeableBottomSheet--open {
    min-height: 150 !important;
    max-height: 350px !important;
}

.variant .ReactSwipeableBottomSheet--open {
    max-height: 600px !important;
    min-height: 600px !important;
}

.line-border {
    height: 10px;
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.arrow {
    content: "";
    position: absolute;
    top: 100%;
    left: 5%;
    transform: translateX(-50%);
    width: 20px;
    z-index: 2;
    background-color: #fff !important;
}

.arrow .pin {
    width: calc(50% + 1px);
    height: 1px;
    background: #b9b9b9;
    display: inline-block;
    float: left;
    transform: rotate(25deg);
    transform-origin: 0 0;
}

.arrow .pin:last-child {
    transform: rotate(-25deg);
    transform-origin: 100% 0;
    float: right;
    margin-top: -2px;
}

.img-res {
    height: 50px;
}

.img-radius {
    border-radius: 50%;
}

.img-respone {
    height: 45px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.all_cate_img {
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.img-responsive {
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.img-cover {
    object-fit: contain;
    background: no-repeat;
    height: 130px;
}

.round_call {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    bottom: 10px;
    right: 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.track {
    bottom: 0px !important;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 11111;
}

.map_tracker {
    height: 70vh !important
}

.country-select {
    font-size: 15px !important;

}

.country-select span {
    margin-right: 10;
    font-size: 18;
}

.MuiFormLabel-root {
    margin-bottom: 0px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.75) !important;
    background-color: #fff !important;
}

.pincode {
    width: 100px;
    border: none;
    text-align: right;
    /* margin-right: 10px; */
    box-shadow: none;
}

.pincode:focus {
    box-shadow: none;
    border: none;
}

.address {
    width: 100%;
    height: 100%;
}

.wrapper {
    display: inline-flex;
    /* height: 100px; */
    width: 100%;
    align-items: center;
    /* justify-content: space-evenly; */
    border-radius: 5px;
    padding: 20px 0px;
}

.wrapper .option {
    background: #fff;
    height: 36px;
    width: fit-content;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 1px solid lightgrey;
    transition: all 0.3s ease;
    width: 100%;
}

.close-search {
    margin-right: 40px;
}

.dot {
    /* height: 15px;
    width: 15px; */
    background-color: #bbb;
    /* border-radius: 50%;
    display:relative; */
}

.wrapper .option .dot {
    height: 16px;
    width: 16px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
}

.wrapper .option .dot::before {
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    background: #005d86;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}

.wrapper input[type="radio"] {
    display: none;
}

/* #head-option-1:checked:checked~.head-option-1,
#head-option-2:checked:checked~.head-option-2,
#head-option-3:checked:checked~.head-option-3 {
    border-color: #00ac0b;
    background: #00ac0b;
}

#head-option-1:checked:checked~.head-option-1 .dot,
#head-option-2:checked:checked~.head-option-2 .dot,
#head-option-3:checked:checked~.head-option-3 .dot {
    background: #fff;
}

#head-option-1:checked:checked~.head-option-1 .dot::before,
#head-option-2:checked:checked~.head-option-2 .dot::before,
#head-option-3:checked:checked~.head-option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
} */

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2,
#option-3:checked:checked~.option-3 {
    border-color: #007dae;
    background: #007dae;
}

#option-1:checked:checked~.option-1 .dot,
#option-2:checked:checked~.option-2 .dot,
#option-3:checked:checked~.option-3 .dot {
    background: #fff;
}

#option-1:checked:checked~.option-1 .dot::before,
#option-2:checked:checked~.option-2 .dot::before,
#option-3:checked:checked~.option-3 .dot::before {
    opacity: 1;
    transform: scale(1);
}

.wrapper .option .span {
    font-size: 13px !important;
    color: #808080;
}

#option-1:checked:checked~.option-1 div>span,
#option-2:checked:checked~.option-2 div>span,
#option-3:checked:checked~.option-3 div>span {
    color: #fff !important;
}

.address-type-active {
    border-color: #007dae;
    background: #007dae;
    color: #fff;
    opacity: 1;
    transform: scale(1);
}

.address-type {
    background-color: #fff;
    color: #575757;
    border: 1ps solid #777;

}

.location_pick {
    align-items: center;
    display: flex;
    margin: 0px auto;
}

.filter-div .scrollY {
    height: 80vh !important;
    overflow-y: scroll;
    padding-bottom: 200px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.filter-div .scrollY::-webkit-scrollbar {
    display: none !important;
}

.bottom-80px {
    bottom: 80px;
    left: 0;
    position: fixed;
}

.scroll60 {
    max-height: 60vh !important;
    overflow-y: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll {
    height: 70vh !important;
    overflow: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ScrollY80::-webkit-scrollbar,
.ScrollY80-cart::-webkit-scrollbar,
.ScrollY90::-webkit-scrollbar,
.overflowY-max::-webkit-scrollbar {
    display: none !important;
}

.ScrollY80 {
    height: 80vh !important;
    /* overflow: scroll; */
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ScrollY80-cart {
    height: 100%;
    /* overflow: scroll; */
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ScrollY90 {
    height: 90vh !important;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scrollX {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
}

.overflowY-max {
    height: 100%;
    width: 100vw !important;
    background-color: #fff;
    padding: 10px;
    overflow-y: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scroll-div {
    width: 200px !important;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0px !important;
    padding: 0;
    background-color: #fff !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
}

.productimg img {
    object-fit: contain;
    width: 100%;
    height: 75px;
}

.cart_shop_border {
    width: 50px;
    height: 3px !important;
    background-color: #333 !important;
    border-radius: 3px;
    opacity: 1 !important;
    margin: 5px 0px;
}

.radius-top {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.radius-none {
    border-radius: unset !important;
}

.radius {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-20 {
    border-radius: 20px;
}

.radius-6 {
    border-radius: 6px;
}

.radius-bottom-20 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.cart_img img {
    height: 70px;
    object-fit: cover;
}

.order_img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.bg-gray-200 {
    background-color: #f7f7f7;
}

.radio-toolbar {
    margin: 10px;
}

.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}


.radio-toolbar label {
    display: inline-block;
    background-color: rgb(255, 255, 255);
    /* padding: 10px 20px; */
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
}

/* .radio-toolbar label:hover {
    background-color: rgb(255, 255, 255);
  } */

.radio-toolbar input[type="radio"]:focus+label {
    border: 1px solid rgb(228, 228, 228);
}

.radio-toolbar input[type="radio"]:checked+label {
    background-color: #bfb !important;
    border-color: #bfb;
}

.other {
    background-color: #e1e7f3 !important;
    border: none;
}

.other p {
    color: #000;
}

.typeimg {
    /* width: 30px; */
    height: 30px;
    object-fit: contain;
    filter: contrast(0.7);
}

.img-opacity {
    filter: opacity(0.3);
}

.bg-delivery {
    border-radius: 8px;
    background-color: #f3f3f3 !important;
}

.card-bg {
    width: 100%;
    /* margin-top: 1rem; */
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #fff;
    text-align: left;
    border-radius: 10px;
    height: 85vh;
}

.h75 {
    height: 75vh !important;
}

.h100 {
    height: 100vh !important;
}

.card-bg .bg-gray {
    border-radius: 0px !important;
}

.filter-text {
    color: gray;
    font-size: 12px !important;
}

.filter_scroll .title {
    /* padding: 10px !important; */
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    font-family: "Roboto", Arial, sans-serif !important;
}

.filter_scroll .MuiAccordionSummary-content {
    margin: 0px !important;
}

.filter_scroll .MuiSvgIcon-root {
    fill: currentColor;
    font-size: 1.2rem !important;
}



.footer {
    background-color: #172337;
    width: 100%;
    height: auto;
    padding: 40px 40px 10px;
    /* margin-top: 20px; */
    position: relative;
    bottom: 0px !important;
    z-index: 35;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize !important;
}

.footer .top p {
    color: #fff;
    font-size: 12px !important;
    letter-spacing: 0.5px;
    line-height: 20px;
    padding-bottom: 5px;
}

.hover_line {
    cursor: pointer !important;
}

.hover_line:hover {
    color: #7b93ff !important;
}

.footer p b {
    color: #6e6e6e !important;
    letter-spacing: 0.7px;
    font-size: 11px !important;
}

.commonHeader {
    background-color: #005d86;
    /* background: linear-gradient(180deg, #028cca, #005d86); */
    justify-content: space-between;
    align-items: center;
    padding: 10px 5em !important;
    height: auto;
    box-shadow: 2px 0px 6px #ccc;
    width: 100%;
    display: flex;
}

.commonHeader .flex {
    align-items: center;
    justify-content: space-between;
}

.commonHeader h5 {
    margin: 0px;
    font-weight: 500 !important;
    color: #fff !important;
}

.map_container {
    width: 100% !important;
    height: 80vh !important;
    top: 0;
    position: relative !important;
    left: 0;
}


.scrolling-card {
    /* height: 100vh; */
    width: 100vw !important;
    background-color: #fff;
    /* padding: 10px; */
    z-index: 0;
}

.navfooter {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    /* z-index: 4; */
    z-index: 9999;
    align-items: center;
    text-align: center;
    scroll-behavior: smooth;
}

.bottom-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* padding: 5px 0; */
    z-index: 9999;
    background: #fff;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* box-shadow: 2px 0px 6px #ccc; */
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset; */
    padding: 5px 0px;
    scroll-behavior: smooth;
    /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.show-bottom-tabs {
    padding-bottom: 55px;
}

.row {
    margin: 0px !important;
}

.navfooter .col-2 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
}

.mobile-menu {
    cursor: pointer;
}

.footmenuActive {
    padding: 7px 0px;
    border-top: 4px solid #0058b0;
    border-top-left-radius: 5px;
}

.footer-icon {
    width: 22px;
}

.space {
    justify-content: space-between;
}

.header .flex h5 {
    font-weight: 500 !important;
    margin: 0px;
}

.brandCenter {
    font-size: 20px;
    padding: 0px 10px;
    color: #000 !important;
    letter-spacing: 0.2px;
    font-family: "Kaushan Script", cursive;
}

.HeaderContent {
    font-size: 18px;
    /* padding: 0px 10px; */
    color: #1e1e1e !important;
    letter-spacing: -0.1px;
    /* font-family: "DMSans-ExtraBold"; */
    font-family: "Poppins-bold" !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
}

.logo-container,
.logo-nav {
    display: flex;
    align-items: center;
}

.logo {
    width: 23px;
    height: 23px;
    margin: 5px;
}

.header-transparent {
    background-color: transparent !important
}

.header-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 5px;
    /* z-index: 1030; */
}

.thumb-img-fluid {
    width: 50px;
    height: 100px;
}

/* .logo-container {
    width: 25%;
} */

.input-icons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* background-color: #005d86; */
    width: 400px;
    height: 36px;
    align-items: center;
}

.input-icons-1 {
    height: 45px;
}

.input-icons-filter {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #005d86; */
    width: 190px;
    height: 36px;
    align-items: center;
}

.input-icons input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 30px;
}

.price input {
    font-size: 12px !important;
}

input::placeholder,
textarea::placeholder {
    font-size: 12px !important;
    color: #ccc !important;
    letter-spacing: 0.5px;
    font-weight: 500 !important;
    font-family: "Poppins-regular" !important;
}

.search-bar {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20;
    border-bottom-right-radius: 20;
}

.cursor {
    cursor: pointer;
}

.right-data {
    float: right;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #000;
}

.header .right-data p {
    color: #000;
}

.line {
    line-height: 0.8 !important;
}

.right-data h5 {
    margin: 0px;
}

.currency_box {
    display: flex;
    align-items: center;
    padding: 3px;
    margin-right: 10px;
}

.country_box {
    display: flex;
    align-items: center;
    padding: 3px;
    margin-right: 10px;
}

.text-start p small {
    color: #666;
}


.border-right-dark {
    border-right: 1px solid #bbb;

}

.login p {
    color: #005bf0 !important;
}

.header-home {
    height: 105px !important;
}

.header {
    /* background: linear-gradient(180deg, #028cca, #005d86); */
    /* background-color: #005d86; */
    /* justify-content: space-between;
          align-items: center; */
    background-color: #fff !important;
    padding: 10px 2rem;
    height: 50px;
    /* box-shadow: 2px 0px 6px #e2e2e2; */
    width: 100%;
    top: 0;
    display: block;
    transition: top 0.3s;
    position: fixed;
    top: 0;
    /* z-index: 10; */
    z-index: 10;
    /* overflow: hidden; */
}

/* Currency Selection */

.currency_box .curren {
    border-color: none;
}

.currency_box .curren {
    right: 135px;
    position: absolute !important;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #00000045;
    /* width: 200px; */
    z-index: 1000;
}

.currency_box .curren::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 20px;
    transform: rotate(180deg);
    margin-right: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: #ececec transparent transparent transparent;
}

.currency_box p {
    font-size: 12px;
}

/* Country box */

.country_box .country {
    border-color: none;
}

.country_box .country {
    /* right: 100px; */
    position: absolute !important;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #00000045;
    z-index: 1000;
}

.country_box .country::after {
    content: "";
    position: absolute;
    bottom: 100%;
    /* left: 10%; */
    transform: rotate(180deg);
    margin-left: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: #ececec transparent transparent transparent;
}

.country_box p {
    font-size: 12px;
}

/* Lang box */
.lang_box .lang {
    right: 50px;
    position: absolute !important;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 0px 3px 0px #00000045;
    z-index: 1000;
}

/* .css-8xdd8h-control {
    min-width: 100px !important;
    width: 150px !important;
  } */

.lang_box .lang::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 20px;
    transform: rotate(180deg);
    margin-left: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: #e4e4e4 transparent transparent transparent;
}


.country,
.curren,
.lang {
    font-size: 12px !important;
}

.curren label,
.lang label,
.country label {
    color: gray;
    padding-left: 5px;
}

.css-1wa3eu0-placeholder {
    font-size: 12px;
}

.login {
    display: flex;
    align-items: flex-end;
    color: #4481eb !important;
}

.sub-header {
    height: 35px !important;
}

.MuiButton-root {
    text-transform: capitalize !important;
}

/* .css-8xdd8h-control {
    min-width: 130px !important;
    max-width: 140px !important;
  } */

.brand_img {
    width: 100% !important;
    object-fit: contain;
    height: 30px !important;
}

.deliverytype {
    width: 100%;
}

.mob_sidemenu {
    z-index: 11;
    height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* transition : margin-left 0.3s ease; */
}

.mob_sidemenu::-webkit-scrollbar {
    display: none !important;
}

.new_sidemenu {
    width: 100%;
    background: #fff;
    margin-left: 105%;
    transition: margin-left 0.3s ease;
    /* transition-property: margin-left;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s; */
    box-shadow: 0px 1px 5px #ccc;
    position: fixed;
    height: 100%;
    z-index: 11;
}

.new_sidemenu_active {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 60px;
    padding-bottom: 55px;
    height: 100%;
    /* z-index: 11; */
    z-index: 1333;
    margin-left: 0;
    overflow-y: auto;
    /* transition: margin-right 0.3s ease-in; */
    width: 100%;
}

.header .MuiBadge-badge {
    height: 16px !important;
    min-width: 16px !important;
    width: 16px !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    overflow: hidden;
}

.header.header-badge-padd .MuiBadge-badge {
    right: 2px;
    top: 2px;
}

.header .right-data .flex img {
    width: 27px;
}

.header .MuiBadge-root img {
    width: 18px !important;
}

.bg-sm-primary {
    background-color: #0058b0;
    box-shadow: 2px 0px 6px #ccc;
}

.bg-sm-primary h5 {
    color: #fff !important;

}

.close_btn {
    background-color: rgba(0, 0, 0, 0.432);
    border-radius: 50%;
    width: 40px !important;
    height: 40px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

.text-end {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}

.sticky-header {
    position: sticky;
    position: -webkit-sticky;
    top: 35px;
    width: 100%;
    left: 0;
    z-index: 9;
}

.sticky-header-address-select {
    position: sticky;
    position: -webkit-sticky;
    top: 58px;
    width: 100%;
    left: 0;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2 !important;
}

.noti-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.offer-img {
    width: 100%;
    height: 60px;
    object-fit: contain;
}

.active-border {
    border: 1px solid #007dea !important;
    border-radius: 5px;
}

.active-border p {
    color: #838383 !important;
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 31px;
    left: -50%;
    z-index: 2;
}

.stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 31px;
    left: 50%;
    z-index: 2;
}

.stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
}

.stepper-item.active {
    font-weight: bold;
}

.stepper-item.completed .step-counter {
    background-color: #0cbb00;
    ;
}

.stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #0cbb00;
    ;
    width: 100%;
    top: 31px;
    left: 50%;
    z-index: 3;
}

.stepper-item:first-child::before {
    content: none;
}

.stepper-item:last-child::after {
    content: none;
}

.step-name {
    font-size: 13px;
    padding-bottom: 4px;
}

.stepper-item.completed .step-name {
    color: #000 !important;
}

.stepper-item .step-name {
    color: gray !important;
}

.card .row .col-md-4,
.card .row .col-6 {
    padding: 0px 10px;
}

.searchbox {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 20px;
    /* background-color: #005d86; */
    width: 100% !important;
    height: 36px;
    align-items: center;
}

.searchbox input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: max-content;
}

.ordersearch {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20;
    border-bottom-right-radius: 20;
    /* width: 50px !important; */
}

.img-box {
    /* border: 1px solid #ccc; */
    border-radius: 6px;
    width: 100%;
    height: 85px;
    object-fit: contain;
}

.img-box img {
    width: 100%;
    height: 85px !important;
    object-fit: contain;
}


.empty-img {
    width: 100%;
    height: 50px;
    object-fit: contain;
}


/* // Order Tracking css // */

.wizard {
    display: grid;
    grid-template-columns: 320px 1fr;
    font-family: "Roboto", Arial, sans-serif;
}

.process {
    /* box-m: border-box; */
    align-self: center;
    /* justify-self: center; */
    padding: 30px 0;
    width: 100%;
}

ul {
    list-style: none;
}

.process ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.process ul li.step .name {
    display: flex;
    align-items: center;
    color: #939393;
    padding-left: 10px;
}

.process .name .pt-2 {
    padding-left: 10px;
}

.process ul li.step .name:before {
    content: '';
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
    background: #ddd;
}

.process ul li.step.completed .name {
    color: #222;
}

.process ul li.step.completed .name:before {
    content: '';
    color: white;
    font-size: 0.8em;
    text-align: center;
    background: #00c200;
}

.process ul li.step.current .name {
    color: #000;
}

.process ul li.step.current .name:before {
    background: #03e203;
    box-shadow: 0 0 18px #00fc22;
    animation: 1500ms linear infinite pulse;
}

.process ul li.step:not(:last-child):after {
    height: 35px;
    width: 3px;
    content: '';
    display: block;
    background-color: #ddd;
    margin-left: 7px;
    /* margin-bottom: 8px; */
}

.process ul li.step.completed:not(:last-child):after {
    background-color: #00c200;
}

.process.done ul li.step .name {
    color: #33673b;
}

.process.done ul li.step .name:before {
    background: #33673b;
    box-shadow: none;
    /* content: '\2713'; */
    color: white;
    font-size: 0.8em;
    text-align: center;
}

.process.done ul li.step:not(:last-child):after {
    background-color: #33673b;
    box-shadow: 0 0 8px #88c492;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 2px #00fc22;
    }

    80% {
        box-shadow: 0 0 18px #00fc22;
    }

    100% {
        box-shadow: 0 0 2px #00fc22;
    }
}

.overflow-scroll {
    overflow-y: scroll;
    width: 100%;
}

.custom-shadow {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
}

.shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.shadow-bottom {
    /* box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px; */
    /* box-shadow: 2px 0px 6px #ccc; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important; */
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 4px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
}

.shadow-head {
    box-shadow: 2px 0px 6px #ccc;
}


.order-track {
    margin-top: 5px;
    padding: 0 1rem;
    padding-top: 0px;
    border-top: 1px solid #e7e7e7;
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
}

.order-track-step {
    display: flex;
    height: 7rem;
}

.order-track-step:last-child {
    overflow: hidden;
    height: 7rem;
}

.order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
}

.order-track-status {
    margin-right: 1rem;
    position: relative;
}

.order-track-status-dot {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #ddd;
}

.order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #ddd;
}

.order-track-status-dot.completed {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #019901;
}

.order-track-status-line.completed {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #019901 !important;
}

.order-track-status-dot.current {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #019901;
    animation: 1500ms linear infinite pulse;
    box-shadow: 0 0 18px #00ff22;
}

.order-track-text-stat {
    font-weight: 500;
    margin-bottom: 3px;
}

.order-track-text-sub {
    font-size: 1rem;
    font-weight: 300;
}

.order-track {
    transition: all .3s height 0.3s;
    transform-origin: top center;
}

figure {
    display: flex;
    margin-top: 2rem;
}

figure img {
    width: 8rem;
    height: 8rem;
    /* border-radius: 15%; */
    /* border: 1.5px solid #f05a00; */
    margin-right: 1.5rem;
    /* padding:1rem; */
    object-fit: contain;
}

figure figcaption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

figure figcaption h4 {
    font-size: 1.2rem;
    font-weight: 500;
}

figure figcaption h6 {
    font-size: 1rem;
    font-weight: 300;
}

figure figcaption h2 {
    font-size: 1.3rem;
    font-weight: 500;
}

.img-summary img {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.img-summary {
    padding: 10px;
}

.btn-danger.disabled,
.btn-danger:disabled {
    font-size: 8px !important;
}

.truck {
    width: 25px;
}

.truck-icon {
    width: 15px;
}


.change-icon {
    width: 16px;
    height: 16px;
}

.no-res {
    width: 100%;
    height: 25px;
    object-fit: contain;
}

.sector legend {
    left: 10px !important;
    width: fit-content !important;
}

fieldset {
    position: relative;
    border: 1px solid #e4e3e3;
    border-radius: 3px;
    margin-top: 5px;
}

legend {
    position: absolute;
    top: -12px;
    width: auto;
    /* padding: 0 5px; */
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    right: 13px;
    margin-bottom: 0px !important;
}

.legendLeft {
    position: absolute;
    top: -10px;
    width: fit-content;
    padding: 2px 8px;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    left: 13px;
    margin-bottom: 0px !important;
    border-radius: 6px;
    letter-spacing: 0.3px;
}

.btn-order {
    border-radius: 20px !important;
    padding: 5px 10px !important;
    border-color: #cacaca;
}

.order-btn {
    border-color: green;
    background-color: rgba(25, 153, 25, 0.07);
    border-radius: 20px !important;
    padding: 5px 10px !important;
}

.order-btn p {
    color: green !important;
}

.type img {
    width: 100%;
    height: 20px;
    object-fit: contain;
}

.type {
    width: 10% !important;
    padding-right: 5px;
}


.round-shop {
    width: 100%;
    height: 50px;
    object-fit: cover;
    border-radius: 6px;
}

.item-card {
    background-color: #f4f8ff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.bank-img {
    width: 20px;
    height: 20px;
}

.MuiSelect-select {
    min-width: 80px !important;
}

.text-brown {
    color: brown;
}


.bg-white .similiar .alice-carousel__stage-item {
    margin: 0px !important;
}

.inset-border {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* Product Details */


.bg-white {
    background-color: #fff !important;
}

.prod-name-mob {
    font-size: 16px;
    color: #000;
    font-family: "Poppins";
    font-weight: 500;
    letter-spacing: 0.5px;
}

.prod-name {
    line-height: 26px !important;
    color: #000 !important;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}

.golden {
    color: orange;
}

.green {
    color: green;
    font-weight: 600;
    font-size: 13px;
}

.react-slider img {
    height: 100px;
    width: 100%;
    object-fit: contain;
}

.react-slider__areaZoom img {
    /* border: none !important; */
    height: 500px !important;
    width: 100%;
    object-fit: contain;
}

.react-slider__btnPrev,
.react-slider__btnNext {
    display: none !important;
}

.react-slider__ul {
    display: flex;
    flex-flow: inherit !important;
    margin: 20px 0 0 0;
    width: 100%;
    overflow: scroll;
    padding-left: 0px !important;
}

.react-slider__ul li.active img {
    border: 2px solid #005d86 !important;
}

.color-box {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-right: 5px;
    border: thin solid darkgray;
    margin-bottom: 5px;
    padding: 2px;
    cursor: pointer;
}

.activeCss {
    padding: 2px;
    box-shadow: 0px 0px 3px 1px #ccc;
    border: 3px solid #f3f3f3;
    width: 20px;
    height: 20px;
    /* background-color: orange; */
    border-radius: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.unit-box {
    width: max-content;
    height: max-content;
    border-radius: 7px;
    margin-right: 8px;
    margin-bottom: 5px;
    padding: 6px 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.unit-box div {
    margin: 0;
}

.activeunits {
    padding: 2px;
    /* box-shadow: 1px 1px 8px 0px #005d86; */
    border: 1.2px solid #0057FF !important;
    width: max-content;
    height: max-content;
    border-radius: 7px;
    margin-right: 8px;
    margin-bottom: 5px;
    padding: 6px 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.activeunits div {
    color: #0057ff !important;
    font-weight: 500;
    margin: 0;
}

.groc_activeunits {
    padding: 2px;
    /* box-shadow: 1px 1px 8px 0px #005d86; */
    border: 2px solid #e60000;
    width: 60px;
    height: 30px;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 2px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.groc_activeunits p {
    text-transform: uppercase;
    color: #e60000;
    font-weight: 600;
    margin: 0;
}

.desc .tab-content {
    border: 1px solid #dee2e6;
    padding: 16px;
}

.scroll_tab.nav-tabs {
    border-bottom: none !important;
}

.scroll_tab {
    width: 500px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tab-content li {
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #777;
}

.desc .nav-link {
    font-size: 13px;
    padding: 1rem !important;
    letter-spacing: 0.4px;
    font-weight: 600;
}

.desc li::marker {
    color: #ccc !important;
    font-size: 15px;
}

.react-slider__picture {
    min-height: auto !important;
}

.react-slider__ul img {
    height: 70px !important;
}

.delivery hr {
    margin: 5px 0px !important;
}

.delivery_para {
    border-right: 1px solid #ccc;
}

.delivery_time {
    color: #005d86 !important;
}

.prod .btn {
    border-radius: 5px !important;
    box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.06);
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-family: "Roboto", Arial, sans-serif !important;
    transition: none !important;
}

.similiar {
    /* overflow-y: hidden !important;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; */
    /* width: 100vw; */
    padding: 10px 0px;
    margin: 0 auto;
    display: flex;
}

.similiar_card {
    /* height: 280px; */
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin: 0 10px;
}

.similiar_card img {
    height: 150px;
    object-fit: contain;
}

.btn-arrow {
    border-radius: 7px !important;
    padding: 10px 5px !important;
    box-shadow: 0px 0px 3px 0px #ccc;
}

.customer_rating {
    display: flex;
}

.progress {
    display: flex;
    height: 0.4rem !important;
}

.rating_star {
    background-color: #23af00;
    padding: 0px 5px;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    height: fit-content;
}

.review {
    max-height: 250px;
    overflow: hidden;
}

.w-auto {
    width: auto;
}

.btn-light {
    width: 100%;
    color: #000 !important;
    background-color: #ffffff;
    /* border: 1px solid #b9b9b9 !important; */
    font-weight: 600 !important;
    cursor: pointer;
    text-align: center;
    padding: 6px 16px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 1.5;
    box-shadow: none !important;
}

.btn-border {
    color: #000 !important;
    background-color: #ffffff;
    border: 1px solid #b9b9b9 !important;
    cursor: pointer;
    font-size: 12px !important;
}


.MuiDialog-paper {
    overflow-y: unset !important;
}

.MuiSnackbarContent-message {
    padding: 9px 4px 4px !important;
    font-size: 13px;
    letter-spacing: 0.5px;
    justify-content: center;
    align-items: center;
}

.MuiSnackbarContent-root {
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
}

#menu-toggle {
    display: none;
}

#menu-toggle-web {
    display: none;
}

.socialmenu {
    position: fixed;
    bottom: -14px;
    left: 0;
    /* width: 100%; */
    background-color: rgb(255, 255, 255);
    transform: translateY(100%);
    transition: 0.3s;
    z-index: 5;
    text-decoration: none !important;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding: 20px;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    height: 200px;
}

.socialmenu li a:hover {
    background-color: #006fa7;
}

.socialmenu li {
    width: 20%;
    justify-content: center;
    margin-top: 10px;
}

.socialmenu li {
    list-style: none !important;
}

#menu-toggle:checked~.socialmenu {
    transform: translateY(0);
}

#menu-toggle-web:checked~.socialmenu {
    transform: translateY(0);
}

.social_icon {
    width: 20px !important;
    height: 20px !important;
}

.thumbs .btn-prev {
    position: absolute;
    top: 8px;
    left: 0;
    /* font-size: 50px; */
    /* color: #c7cdde; */
    cursor: pointer;
}

.thumbs .btn-prev,
.thumbs .btn-next {
    position: absolute;
    top: 0px !important;
    /* font-size: 50px; */
    /* color: #c7cdde; */
    cursor: pointer;
    right: 0;
}

.thumbs .btn-prev {
    left: 0 !important;
}

.thumbs {
    max-width: 100%;
    margin: 30px auto;
    padding: 0 10px !important;
    position: relative;
    height: 100px;
    margin: 10px auto !important;
    display: flex;
    background-color: #fff !important;
}

.item::before {
    content: attr(data-value);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 300;
    user-select: none;
    color: #fff;
    background-color: #c7cdde;
}

.thumb {
    /* border: 1px solid transparent !important; */
    /* padding: 3px; */
    border-radius: 5px;
    border: 0;
}

.thumb img {
    width: 100px;
    height: 70px;
    object-fit: contain;
    /* padding: 10px; */
    margin-bottom: 0 !important;
    border: none !important;
}

.alice-carousel__stage-item .__cloned .thumb {
    border: 1px solid var(--prime1) !important;
    padding: 3px;
    border-radius: 5px;
}

.bg-white .alice-carousel__stage-item .thumb img {
    width: 100% !important;
    height: 70px !important;
}

.thumbs .alice-carousel__stage-item {
    width: 100px !important;
    height: 100px !important;
    margin: 0px 5px !important;
}

.thumbs .alice-carousel {
    margin: 0px auto !important;
    padding: 0px;
}

.bg-white .sectorSlide .alice-carousel .alice-carousel__stage-item {
    width: 100% !important;
    margin: 0px !important;
}

.bg-white .sectorSlide .alice-carousel .alice-carousel__stage-item img {
    width: 100% !important;
    height: 330px !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiAccordion-root:before {
    background-color: #fff !important;
}

.MuiAccordionSummary-root {
    padding: 0px 4px !important;
}

.MuiAccordionDetails-root {
    padding: 8px 3px 0px !important;
}



.MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0 !important;
}

.MuiIconButton-root {
    padding: 0px 7px !important;
}

.MuiTypography-body1 {
    width: 100%;
}

.MuiList-padding {
    padding: 0px !important;
}

.select__option label {
    margin-bottom: 0px !important;
}

.filter_scroll {
    width: 100%;
    min-height: auto;
    /* max-height: 80vh;
      overflow-y: scroll; */
}

.filter_scroll::-webkit-scrollbar {
    display: none;
}

.over-scroll,
.select__menu-list {
    min-height: auto;
    /* max-height: 150px; */
    width: 100%;
    /* overflow-
    y: scroll !important; */
}

.over-scroll label {
    font-size: 13px !important;
    color: gray;
}

.over-select {
    height: 300px;
    width: 100%;
}

.css-26l3qy-menu {
    box-shadow: none !important;
}

.over-scroll::-webkit-scrollbar,
.select__menu-list::-webkit-scrollbar,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar {
    width: 4px;
}

/* Track */

.over-scroll::-webkit-scrollbar-track,
.select__menu-list::-webkit-scrollbar-track,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-track,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}

/* Handle */

.over-scroll::-webkit-scrollbar-thumb,
.select__menu-list::-webkit-scrollbar-thumb,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-thumb,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-thumb {
    background: #777;
    border-radius: 10px;
}

/* Handle on hover */

.over-scroll-::-webkit-scrollbar-thumb:hover,
.select__menu-list::-webkit-scrollbar-thumb-hover,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-thumb-hover,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-thumb-hover {
    background: #333;
}

.css-9gakcf-option {
    background-color: #fff !important;
    color: #000 !important;
}

.css-1pahdxg-control {
    box-shadow: none !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}

.fix {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff;
    padding: 10px 0px;
}

.title {
    color: #222 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 13px;
}

label {
    font-size: 13px;
    margin-bottom: 8px;
}

.checkbox {
    margin-right: 0.56em;
}

.sub-category .card img {
    width: 70%;
    height: 150px;
    margin: 0px auto;
    padding: 0;
}

.product_img {
    /* min-width: 80px;
      max-width: 100%; */
    width: 100%;
    object-fit: contain;
    height: 140px !important;
    /* display: flex;
      justify-content: center;
      align-items: center; */
}

.card-img-list {
    width: 100%;
    height: 100px;
    object-fit: contain;
    position: relative;
}

.save {
    margin: 5px 0px;
    color: #008000 !important;
    font-size: 11px !important;
    font-weight: 600;
    letter-spacing: 0.4px;
}


.variant-box {
    align-items: center;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 3px;
    /* padding: 5px; */
    text-align: center;
    /* margin-top: 10px; */
}

.variant-box p {
    font-size: 9px !important;
    color: #222;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 5px !important;
}

.add {
    align-items: center;
    padding-top: 10px !important;
}

.add .pl-0 {
    padding-left: 0px !important;
}

.add .pr-0 {
    padding-right: 0px !important;
}

.list {
    width: 250;
}

.fullList {
    width: "auto";
}

.add .col-6 {
    width: 45% !important;
    margin: 0px auto;
}

/* .r-border{
      border-right: 1px solid #cccccc !important;
  } */

.md-none .filter_row {
    width: 100%;
    position: -webkit-sticky;
    position: fixed;
    top: 58px;
    padding: 10px 5px;
    background-color: #fff;
    z-index: 2;
    border: 1px solid #f2f2f2;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.fixed-top {
    width: 100%;
    padding: 10px 5px;
    background-color: #fff;
    /* z-index: 1; */
    border: 1px solid #f2f2f2;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

.md-none .head-room {
    height: 30px !important;
}

.MuiCollapse-root {
    z-index: 0;
    position: relative;
}

.MuiRating-label {
    font-size: 15px;
    margin-bottom: 0px;
}

.preview {
    width: 100%;
    height: 70px;
    padding: 0px;
    object-fit: cover;
    background-color: #e9f4fb;
    border-radius: 4px;
}

.main-preview {
    width: 100%;
    height: 150px;
    padding: 0px;
    object-fit: cover;
    background-color: #e9f4fb;
    border-radius: 4px;
}

.main-video-preview {
    width: 100%;
    height: 230px;
    padding: 0px;
    object-fit: cover;
    background-color: #e9f4fb;
    border-radius: 4px;
}

.main-50-preview {
    width: 100%;
    height: 75px;
    padding: 0px;
    object-fit: cover;
    background-color: #e9f4fb;
    border-radius: 4px;
}

.selling_img {
    width: 100%;
    height: 90px;
    object-fit: contain;
}

.selling_bg {
    /* background-color: #eeaf77; */
    background-color: #ffc1c6;
    border-radius: 6px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container img {
    width: 100%;
    height: 120px;
    object-fit: contain;
}

.thumbnail-image {
    border-radius: 10px !important;
}

.sector .card {
    background-color: #fff;
    /* border: 1px solid #f3f3f3;
    border-radius: 4px; */
}

.sector-img {
    width: 100%;
    height: 45px;
    object-fit: contain;
    background: no-repeat;
    /* padding: 2px; */
    border-color: transparent;
}

.coming-head {
    position: absolute;
    max-width: 50%;
    max-height: 50%;
    top: 50%;
    left: 50%;
    overflow: visible;
}

.coming-img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin-top: -50%;
    margin-left: -50%;
}

.viewnxt-img {
    height: 16px;
}

.discount {
    background-color: red;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 10px;
    border-radius: 6px;
    color: #fff;
}

.wishlist {
    height: 25px;
    width: 25px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first {
    position: absolute;
    width: 100%;
    padding: 9px;
}

.dress-name {
    font-size: 13px;
    font-weight: bold;
    width: 75%;
}

.new-price {
    font-size: 13px;
    font-weight: bold;
    color: red;
}

.old-price {
    font-size: 8px;
    font-weight: bold;
    color: grey;
}

.creme {
    background-color: #fff;
    border: 2px solid grey;
}

.creme:hover {
    border: 3px solid grey;
}

.creme:focus {
    background-color: grey;
}

.red {
    background-color: #fff;
    border: 2px solid red;
}

.red:hover {
    border: 3px solid red;
}

.red:focus {
    background-color: red;
}

.blue {
    background-color: #fff;
    border: 2px solid #40c4ff;
}

.blue:hover {
    border: 3px solid #40c4ff;
}

.blue:focus {
    background-color: #40c4ff;
}

.darkblue {
    background-color: #fff;
    border: 2px solid #01579b;
}

.darkblue:hover {
    border: 3px solid #01579b;
}

.darkblue:focus {
    background-color: #01579b;
}

.yellow {
    background-color: #fff;
    border: 2px solid #ffca28;
}

.yellow:hover {
    border-radius: 3px solid #ffca28;
}

.yellow:focus {
    background-color: #ffca28;
}

.item-size {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid grey;
    color: grey;
    font-size: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.rating-star {
    font-size: 10px !important;
}

.rating-number {
    font-size: 10px;
    color: grey;
}

.buy {
    font-size: 12px;
    color: purple;
    font-weight: 500;
}

.voutchers {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    margin: 0px auto;
    padding: 5px;
}

.voutcher-divider {
    display: flex;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

/* .voutcher-left {
      width: 60%
  } */

.voutcher-name {
    color: green;
    font-size: 10px;
    font-weight: 500;
}

.voutcher-code {
    color: red;
    font-size: 11px;
    font-weight: bold;
}

.voutcher-right {
    width: 40%;
    background-color: purple;
    color: #fff;
}

.discount-percent {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 5px;
}

.off {
    font-size: 14px;
    position: relative;
    bottom: 5px;
}


.sector_sub_img {
    /* width: 100%; */
    height: 120px;
    object-fit: contain;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.sector_sub_img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.sec_prod {
    width: 100%;
    display: flex;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.sec_btn {
    padding: 20px !important;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);
    background-color: transparent !important;
    border-radius: 5px !important;
    align-items: center;
    justify-content: center;
    display: flex;
}

.sec_w {
    width: 30% !important;
    margin: 10px;
}

.img_slide {
    height: 260px;
    width: 100%;
}

.card-wrap {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    color: var(--color-text);
    /* box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px, rgba(0, 0, 0, 0.15) 0px 6px 9px; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.card-wrap.res {
    border: 1px solid #f1bd6480;
}

.card-wrap.ecom {
    border: 1px solid #de00ff52;
}

.card-wrap.groc {
    border: 1px solid #9fb3f65c;
}


/* .card-wrap:hover{
    transform: scale(1.1);
  } */
.card-header {
    height: 150px;
    width: 100%;
    background: red;
    border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
    display: grid;
    place-items: center;
}

.card-header i {
    color: #fff;
    font-size: 72px;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin: 0 auto;
}

.card-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.card-text {
    text-align: center;
    font-size: 12px;
    margin-bottom: 20px;
}

.card-btn {
    margin-top: 10px;
    border: none;
    border-radius: 100px;
    padding: 5px 25px;
    color: #fff;
    margin-bottom: 15px;
    font-size: 12px;
}

.card-header img {
    object-fit: contain;
    width: 100%;
    height: 120px;
}

.card-header.one {
    background: linear-gradient(to bottom left,
            var(--card1-gradient-color1),
            var(--card1-gradient-color2));
}

.card-header.two {
    background: linear-gradient(to bottom left,
            var(--card2-gradient-color1),
            var(--card2-gradient-color2));
}

.card-header.three {
    background: linear-gradient(to bottom left,
            var(--card3-gradient-color1),
            var(--card3-gradient-color2));
}

.card-header.four {
    background: linear-gradient(to bottom left,
            var(--card4-gradient-color1),
            var(--card4-gradient-color2));
}

.card-btn.one {
    background: linear-gradient(to left,
            var(--card1-gradient-color1),
            var(--card1-gradient-color2));
}

.card-btn.two {
    background: linear-gradient(to left,
            var(--card2-gradient-color1),
            var(--card2-gradient-color2));
}

.card-btn.three {
    background: linear-gradient(to left,
            var(--card3-gradient-color1),
            var(--card3-gradient-color2));
}

.card-btn.four {
    background: linear-gradient(to left,
            var(--card4-gradient-color1),
            var(--card4-gradient-color2));
}

.card-header:first-child {
    border-radius: 100% 0% 100% 0% / 0% 50% 50% 100% !important;
}

.sectorSlide .alice-carousel__stage-item * {
    line-height: initial;
    width: 100% !important;
    height: 130px !important;
    object-fit: fill !important;
}

.sectorSlide.productdots .alice-carousel__stage-item * {
    line-height: initial;
    width: 100% !important;
    height: 300px !important;
    object-fit: contain !important;
}

.bg-lightblue {
    background-color: #e0ebff !important;
}

.bg-lightgreen {
    /* background-color: #f8fbf6 !important; */
    background-color: #e4f3f9 !important;
}

.sectorSlide .alice-carousel__dots {
    /* margin: 5px 3px 5px !important; */
    padding: 0 6px;
    list-style: none;
    text-align: end;
    z-index: 0 !important;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.brand .alice-carousel__dots {
    margin: 0px !important;
    padding: 0;
    list-style: none;
    text-align: center;
    z-index: 0 !important;
    position: initial;
    bottom: 0px;
    width: 100%;
}

.brand .alice-carousel__dots-item:not(.__custom):hover,
.brand .alice-carousel__dots-item:not(.__custom).__active {
    width: 6px !important;
    height: 6px !important;
    border-radius: 10px;
    background-color: var(--prime1) !important;
}


.sectorSlide .alice-carousel__wrapper {
    z-index: 0;
}

.sectorSlide .alice-carousel__dots-item:not(.__custom) {
    background-color: #ebebeb !important;

}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 6px !important;
}

.sectorSlide .alice-carousel__dots-item:not(.__custom):hover,
.sectorSlide .alice-carousel__dots-item:not(.__custom).__active {
    width: 7px !important;
    height: 7px !important;
    border-radius: 10px;
    background-color: var(--prime1) !important;
    /* -webkit-transition:  background-color  0s, width .9s;
              -o-transition:  background-color  0s, width .9s;
              transition:  background-color  0s, width .9s;
              -webkit-transition-delay: .5s, .5s, 0s;
                   -o-transition-delay: .5s, .5s, 0s;
                      transition-delay: .5s, .5s, 0s; */
}

.bg-white .alice-carousel__stage-item {
    margin: 0px 5px !important;
}

.profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.sidemenu .flex:hover p {
    color: #0073e6;
    font-weight: 500;
}

.sidemenu .active {
    color: #0073e6;
    font-weight: 600;
}

.mob_sidemenu {
    height: 100vh;
}


/* .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  } */

.head-filter {
    top: 8vh;
    background-color: #fff;
    padding: 5px;
}

/* .head-filter .headroom {
    left: 0;
    right: 0;
    z-index: 1;
  } */

.head-filter .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 21vh !important;
    background-color: #fff;
}

.head-filter .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    top: 16vh !important;
    background-color: #fff;
}

.fixed-cart-header {
    scrollbar-width: none !important;
    overflow-y: scroll;
    padding-bottom: 10px;
    padding-top: 5px;
    position: fixed !important;
    overflow-x: hidden;
    background-color: #fff;
    top: 50px;

}

.fixed-search-bar {
    position: fixed !important;
    top: 50px;
    z-index: 1;
}

.md-mt-90 {
    margin-top: 90px;
}

@media (max-width: 786px) {
    .fixed-cart-header {
        width: 100%;
        left: 0;
    }

    .fixed-search-bar {
        width: 100%;
    }

    .mb-sm-5 {
        margin-bottom: 2.5rem;
    }

    .pb-sm-5 {
        padding-bottom: 2.5rem;
    }

    .sm-mt-70 {
        margin-top: 70px;
    }

    .sm-mt-115px {
        margin-top: 115px;
    }

    .sm-mt-100px {
        margin-top: 100px;
      }

    .sm-pe-0 {
        padding: 0px;
    }

    .sm-w-100 {
        width: 100%;
    }

    .pb-sm-1 {
        padding-bottom: 0.5rem;
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 35px !important;
        position: sticky;
        position: -webkit-sticky;
        top: 60px;
        z-index: 1;
        background-color: #fff !important;
        padding: 10px 5px !important;
        /* border-bottom: 1px solid #d0d0d0; */
    }

    .border-bottom-sm {
        border-bottom: 1px solid #ccc !important;
    }

    /* .head-room{
          height: 7em !important;
      } */
    .MuiDrawer-paperAnchorBottom {
        padding: 20px 10px !important;
    }

    .variant .MuiDrawer-paperAnchorBottom {
        padding: 0px !important;
    }

    .sort_filter .MuiDrawer-paperAnchorBottom {
        padding: 0px !important;
    }

    .MuiDrawer-paperAnchorBottom .variants {
        padding: 0px 15px !important;
    }

    .variant-modal .MuiDrawer-paperAnchorBottom {
        /* height: 70vh !important; */
        padding: 0 !important;
    }

    .sm-none {
        display: none !important;
    }

    .sm-show {
        width: 100%;
        margin: auto;
    }


    .md-none {
        display: block;
    }

    .wrapper .option {
        width: 100%;
        /* margin: 0 10px; */
    }

    .commonHeader {
        padding: 15px !important;
        width: 100% !important;
    }

    .header {
        height: 60px;
        padding: 5px 12px 5px 12px !important;
        width: 100% !important;
        left: 0;
    }

    .MuiBadge-root {
        margin-right: 0px !important;
    }

    .logo-nav {
        padding-left: 0px !important;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .brandCenter {
        font-size: 18px;
    }

    .right-data h5 {
        font-size: 16px !important;
    }

    .right-data .mr-2 {
        margin-right: 0.5rem !important;
    }

    .input-icons {
        width: 100% !important;
    }

    /* .brand_img {
        width: 100% !important;
    } */

    /* .logo-container {
        width: 60% !important;
    } */

    .deliverytype .bg-white {
        width: 100%;
        padding: 10px 0 20px;
    }

    .deliverytype {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        /* padding-top: 100px; */
    }

    .scrolling-card {
        /* overflow-y: scroll; */
        overflow-y: auto;
        /* height: 100vh; */
        /* overflow-x: hidden !important; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .scrolling-card::-webkit-scrollbar {
        display: none;
    }

    .scrolling-card .row.center {
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .delivery_top {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
    }

    .image {
        display: none;
    }

    /* .brand_img {
        width: 100% !important;
    } */

    .header .flex h5 {
        font-weight: 500 !important;
        margin: 0px;
        /* font-size: 13px !important; */
    }

    .header .right-data p {
        color: #000;
        font-size: 11px !important;
        letter-spacing: 0.3px;
    }

    .overflow-scroll {
        overflow-y: scroll;
        width: 100%;
        height: 100vh;
        padding-top: 400px;
    }

    .truck {
        width: 20px !important;
        height: 20px !important;
    }

    .react-slider__btnPrev,
    .react-slider__btnNext {
        display: block !important;
    }

    .react-slider__ul {
        display: none;
    }

    .react-slider__areaZoom img {
        border: none !important;
        height: 300px !important;
    }

    .socialmenu li {
        width: 39% !important;
    }

    .bg-white .alice-carousel__stage-item .card-img {
        object-fit: contain;
        height: 100px !important;
        width: 100% !important;
    }

    .thumbs {
        margin: 0px !important;
        max-width: 100% !important;
    }

    .w-sm-50 {
        width: 50%;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .mt-sm-4{
        margin-top: 2rem;
      }

      .sm-top-20 {
        top: 20px !important;
      }
}

/* @media screen and (min-width: 1200px) and (max-width: 1400px )  {
    .fixed-order-head, .fixed-cart-header {
        width: 1140px !important;
    }
}

@media screen and (min-width: 1400px)  {
    .fixed-order-head, .fixed-cart-header {
        width: 1320px !important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px ) {
    .fixed-cart-header , .fixed-order-header {
        width: 960px !important;

    }

} */




@media (min-width: 786px) {

    .md-fixed-top-search {
        position: fixed;
        width: 30%;
        top: 50px;
        z-index: 2;
    }

    .bg-darkgreen-1 {
        background-color: #17791e;
        /* bottom: 50px; */
        /* bottom: 45px !important; */
        position: fixed;
        padding: 16px 10px;
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
    }

    .web-custom-bottom-cart-btn {
        position: fixed !important;
        bottom: 0px !important;
        left: 1% !important;
        right: 0;
    
      }

      .mt-md-0{
        margin-top: 0;
      }

    .mt-md-9rem {
        margin-top: 9rem;
    }

    .rating-model .checkout-bottom {
        width: 50% !important;
        left: 25%;
        bottom: 30px !important;
    }

    .fixed-search-bar {
        width: 71%;
    }

    .fixed-summary {
        position: fixed !important;
        overflow-x: hidden;
        background-color: #fff;
        top: 72px;
        right: 14%;
        width: 23.8%;
    }

    .md-mt-180 {
        margin-top: 180px;
    }

    .w-md-35per {
        width: 35% !important;
    }

    .custom-container {
        /* width: 70%; */
        width: 35% !important;
        margin: auto;
        left: 1%;
    }

    .sector_page,
    .food-page {
        width: 70%;
        margin: auto;
    }

    .fixed-cart-header {
        width: 34.8%;

    }

    .map-header {
        width: 70% !important;

    }

    .address-map-header {
        width: 70%;
    }

    .fixed-order-head {
        padding-bottom: 45px;
        position: fixed !important;
        top: 50px;
        width: 70%;
        z-index: 9;
    }

    .fixed-order-filter {
        padding-bottom: 45px;
        position: fixed !important;
        width: 17%;
    }

    .fixed-delivery-type {
        position: fixed !important;
        /* overflow-x: hidden; */
        background-color: #f6f9ff;
        top: 115px;
        width: 35%;
        z-index: 1;
    }

    .fixed-place-order {
        position: fixed;
        top: 260px;
        right: 14.6%;
        width: 23%;
    }

    /* .fixed-cart-header {
    width: 65%;
    } */

    .md-ml-25per {
        margin-left: 25%;
    }

    .md-ml-200px {
        margin-left: 200px;
    }

    .md-mt-200 {
        margin-top: 200px;
    }

    .md-mt-125 {
        margin-top: 125px;
    }

    .md-mt-60 {
        margin-top: 60px;
    }

    .md-w-50 {
        width: 50% !important;
    }

    .md-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .md-none {
        display: none !important;
    }

    .px-md-2 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .pb-md-2 {
        padding-bottom: 1rem;
    }

    .margin-footer {
        margin-bottom: 375px !important;
    }

    .pb-md-3 {
        padding-bottom: 1rem;
    }

    .md-block {
        display: block !important;
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 40px !important;
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 50px;
        z-index: 2;
        padding: 10px !important;
        background-color: #fff;
        border-bottom: 1px solid #d0d0d0;
    }

    .header {
        /* height: 105px !important; */
        height: 50px !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
    }

    .page-content-home {
        margin-top: 105px;
    }

    .page-content {
        /* margin-top: 105px; */
        margin-top: 50px;
    }

    .scroll {
        height: 100%;
        overflow: none;
    }

    .navfooter {
        display: none;
    }

    .sm-show {
        display: none !important;
    }

    .scrolling-card {
        width: 500px;
        margin: 0px auto;
    }

    .scrolling-card .row.center {
        padding: 1.5rem;
    }

    .mdb-2 {
        padding-bottom: 1rem !important;
    }

    .bg-white .alice-carousel__stage-item .card-img {
        object-fit: contain;
        height: 150px !important;
        width: 100% !important;
    }

    .md-position-absolute {
        position: absolute;
    }

    .r_-17px {
        right: -17px;
    }
}

@media (max-width: 548px) {
    /* .page-main {
        margin-top: 16rem !important;
    } */

    .position-fixed-top50px {
        width: 100%;
    }

    .rating-model .makeStyles-paper-2 {
        height: 100vh;
    }

    .video-lightbox .yarl__navigation_next,
    .video-lightbox .yarl__navigation_prev {
        display: block !important;
    }

    .yarl__navigation_prev,
    .yarl__navigation_next {
        display: none;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-text-end {
        text-align: end;
    }

    .p-sm-0 {
        padding: 0px !important;
    }

    .px-sm-0 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .border-top-sm {
        border-top: 1px solid #dee2e6;
    }

    .page-footer {
        position: fixed;
        width: -moz-available;
        bottom: 0px;
        left: 0px;
    }

    .smp-0 {
        padding: 0px !important;
    }

    .wrapper .option {
        width: 100%;
    }

    .sm-small-text {
        font-size: 11px;
        font-weight: 600;
        color: #6e6e6e;
    }

    .sm-small-col {
        line-height: 12px;
    }

    .sec_w {
        width: 50% !important;
        margin: 10px;
    }

    .sector_sub_img {
        width: 120px;
        height: 120px;
        object-fit: contain;
    }

    .sector .sector_mob {
        padding: 0px;
    }

    .sector .sector_mob .voutchers h5 {
        font-size: 14px !important;
    }

    .sector .sector_mob .voutchers {
        width: 100% !important;
    }

    .sec_btn {
        display: none !important;
    }

    .card-btn {
        padding: 5px 20px;
    }

    .sectorSlide .alice-carousel__stage-item * {
        line-height: initial;
        width: 100%;
        height: 130px !important;
        object-fit: fill !important;
    }

    .bg-white .alice-carousel__stage-item img {
        width: 100% !important;
        height: 120px !important;
        object-fit: contain !important;
        background: no-repeat;
    }


}

@media(min-width: 546px) {
    .position-fixed-top50px {
        width: inherit;
    }

    

    .bg-white .alice-carousel__stage-item img {
        line-height: initial;
        width: 100% !important;
        height: 120px !important;
        object-fit: contain !important;
        background: no-repeat;
        margin-bottom: 10px;
    }

    .sm-d-none {
        display: none !important;
    }

    .pickup-drop-container {
        max-width: 540px;
    }

    .yarl__navigation_prev,
    .yarl__navigation_next {
        display: block;
    }

    /* .bg-red_leaf{
        background-color: #fff;
        height: 120px;
        border-radius: 6px;
    } */

    .md-text-center {
        text-align: center;
    }

    .border-right-md {
        border-right: 1px solid #dee2e6;
    }

    .border-left-md {
        border-left: 1px solid #dee2e6;
    }

    .md-text-right {
        text-align: end;
    }

    .pt-md-0 {
        padding-top: 0;
    }


}

@media (max-width: 599.95px) {
    .makeStyles-paper-2 {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
}

@media(min-width: 920px) {
    .border-left {
        height: 100%;
    }

    .lg-none {
        display: none !important;
    }

    .lg-block {
        display: block !important;
    }

    .makeStyles-paper-23 {
        scrollbar-width: none !important;
    }

}

.px-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.ps-12 {
    padding-left: 12px;
}

.header-transparent {
    background-color: transparent !important
}

/* Sign up */

/* //////////// Delivery modal ////////////// */
/* .bg-clr{
    background-image: linear-gradient(to right bottom, #051937, #194363, #36728e, #5ba4b6, #8ad8dc);
} */

.bg-clr {
    background: rgb(255, 255, 255);
    border-radius: 10px;
}

.bg-clr .modalcontainer {
    position: relative;
    width: 100%;
    background: rgba(36, 36, 36, 0.15);
    min-height: 600px;
    overflow: hidden;
    z-index: 0;
}

.forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.panels-container {
    /* background-color: #fff !important; */
}

.signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
}

form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

form.sign-up-form {
    opacity: 0;
    z-index: 1;
}

form.sign-in-form {
    z-index: 2;
}

.bg-clr .title {
    /* border-bottom:solid rgba( 89, 149, 253, 0.95 ) 3px; */
    font-size: 1.6rem;
    color: #222 !important;
    margin-bottom: 10px;
}

.input-field {
    max-width: 380px;
    width: 100%;
    background: rgba(225, 225, 225, 0);
    box-shadow: 0 8px 32px 0 rgba(97, 97, 97, 0.37);
    backdrop-filter: blur(18.5px);
    -webkit-backdrop-filter: blur(18.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* border: 1px solid #fff; */
    margin: 10px 0;
    height: 48px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.input-field i {
    text-align: center;
    line-height: 48px;
    color: #222;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 14px;
    color: #222;
}

.input-field input::placeholder {
    color: #222;
    font-weight: 500;
}

.social-text {
    padding: 0.7rem 0;
    font-size: 13px;
    color: #222 !important;
}

.social-media {
    display: flex;
    justify-content: center;
}

.social-icon {
    background: rgba(74, 144, 226, 0.35);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #fff;
    border-radius: 50%;
    border: 1px solid #c4c4c487;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
}

.social-icon:hover {
    color: #222 !important;
    transform: translateY(-0.25em);
    border-color: #4481eb;
}

.bg-clr .btn {
    width: 100px;
    background: rgba(89, 149, 253, 0.95);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(18.5px);
    -webkit-backdrop-filter: blur(18.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    outline: none;
    height: 40px;
    border-radius: 49px;
    color: #fff;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-family: "Roboto", Arial, sans-serif !important;
    margin: 10px 0;
    cursor: pointer;
    /* transition: 0.5s; */
    transition: none !important;
}

.bg-clr .btn:hover {
    background-color: #4d84e2;
}

.panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.bg-clr .container:before {
    content: "";
    position: absolute;
    height: 200%;
    width: 100%;
    top: 0%;
    right: 48%;
    transform: translateY(-50%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
    background: rgb(94, 152, 251);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.bg-clr .image {
    width: 40%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
    margin: 0px auto;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    z-index: 6;
}

.left-panel {
    pointer-events: all;
    padding: 0rem 7rem 4rem 1rem;
}

.right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
}

.right-panel .content {
    pointer-events: all;
    justify-content: center;
}

.panel .content {
    color: #fff;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
}

.panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.bg-clr .btn.transparent {
    margin: 0;
    background: none;
    border: 2px solid #fff;
    width: 130px;
    height: 41px;
    font-weight: 600;
    font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
    transform: translateX(800px);
}

/* ANIMATION */

.bg-clr .container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
}

.bg-clr .container.sign-up-mode .left-panel .image,
.bg-clr .container.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
}

.bg-clr .container.sign-up-mode .signin-signup {
    left: 25%;
}

.bg-clr .container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
}

.bg-clr .container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
}

.bg-clr .container.sign-up-mode .right-panel .image,
.bg-clr .container.sign-up-mode .right-panel .content {
    transform: translateX(0%);
}

.bg-clr .container.sign-up-mode .left-panel {
    pointer-events: none;
}

.bg-clr .container.sign-up-mode .right-panel {
    pointer-events: none;
}

@media (max-width: 576px) {
    .left-panel {
        padding: 2rem 2rem;
    }

    .bg-clr .modalcontainer {
        min-height: 600px;
        height: 90vh;
    }

    .bg-clr {
        justify-content: center;
        align-items: center;
        display: flex;
        /* width: 90vw; */
        margin-top: 5px;
    }
}

@media (min-width: 576px) {

    .right-panel .image,
    .right-panel .content {
        transform: translateX(200px);
    }

    .bg-clr {
        margin-top: 5px;
    }
}

@media (min-width: 786px) {
    .md-pt-0 {
        padding-top: 0 !important;
    }

    .right-panel .image,
    .right-panel .content {
        transform: translateX(450px);
    }

    .md-mt-100px {
        margin-top: 100px;
    }

    .md-mt-125px {
        margin-top: 125px;
    }
}

@media (min-width: 920px) {

    .right-panel .image,
    .right-panel .content {
        transform: translateX(600px);
    }
}

@media (min-width: 1200px) {

    .right-panel .image,
    .right-panel .content {
        transform: translateX(700px);
    }
}

@media (min-width: 1400px) {

    .right-panel .image,
    .right-panel .content {
        transform: translateX(800px);
    }
}

@media (max-width: 786px) {
.sm-fixed-top-search {
    position: fixed;
    width: 80%;
    top: 50px;
    z-index: 2;
}

    .bg-clr .modalcontainer {
        min-height: 600px;
        height: 90vh;
    }

    .right-panel .image,
    .right-panel .content {
        transform: translateX(350px);
    }

    .signin-signup {
        width: 100%;
        top: 95%;
        transform: translate(-50%, -100%);
        transition: 1s 0.8s ease-in-out;
    }

    .signin-signup,
    .bg-clr .container.sign-up-mode .signin-signup {
        left: 50%;
    }

    .panels-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
    }

    .panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 2.5rem 8%;
        grid-column: 1 / 2;
    }

    .right-panel {
        /* padding: 11rem 2rem; */
        grid-row: 1 / 3;
    }

    .left-panel {
        /* padding: 2rem 2rem; */
        grid-row: 1 / 2;
    }

    .image {
        width: 200px;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.6s;
    }

    .panel .content {
        padding-right: 15%;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.8s;
    }

    .panel h3 {
        font-size: 1.2rem;
    }

    .panel p {
        font-size: 0.7rem;
        padding: 0.5rem 0;
    }

    .btn.transparent {
        width: 110px;
        height: 35px;
        font-size: 0.7rem;
    }

    .bg-clr .container:before {
        width: 1500px;
        height: 1500px;
        transform: translateX(-50%);
        left: 30%;
        bottom: 68%;
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }

    .bg-clr .container.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 32%;
        right: initial;
    }

    .bg-clr .container.sign-up-mode .left-panel .image,
    .bg-clr .container.sign-up-mode .left-panel .content {
        transform: translateY(-300px);
    }

    .bg-clr .container.sign-up-mode .right-panel .image,
    .bg-clr .container.sign-up-mode .right-panel .content {
        transform: translateY(0px);
    }

    .right-panel .image,
    .right-panel .content {
        transform: translateY(300px);
    }

    .bg-clr .container.sign-up-mode .signin-signup {
        top: 5%;
        transform: translate(-50%, 0);
    }

    .panel .content {
        padding: 0.5rem 1rem;
    }

    .bg-clr .container {
        padding: 1.5rem;
    }

    .bg-clr .container:before {
        bottom: 72%;
        left: 50%;
    }

    .bg-clr .container.sign-up-mode:before {
        bottom: 28%;
        left: 50%;
    }

    form {
        padding: 0 1.5rem;
    }
}




/* Ecommerce */

.sub {
    padding: 0px 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100% !important;
}

.sub .active {
    /* border-right: 2px solid #0058b0 !important; */
    border: 1px solid #bbb;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none !important;
}

.sub_clr {
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sub .row h6 {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000 !important;
    font-size: 13px !important;
}

.sub .row p {
    font-size: 11px;
    color: #959595 !important;
}

.sub .row {
    padding: 16px;
    /* width: 350px; */
    width: 100%;
}

.sub .row .col-6 {
    width: 45%;
    margin-right: 8px;
    margin-bottom: 10px;
}

.sub .border img {
    width: 100%;
    height: 70px;
    object-fit: contain;
}

.order_detail .MuiAccordionSummary-content {
    margin: 0 !important;
}

.order_detail .MuiAccordionSummary-root {
    min-height: 30px !important;
}

@media(max-width: 786px) {
    .sub img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }

    .px-sm-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .category .flex {
        width: 100vw !important;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .category .flex::-webkit-scrollbar {
        display: none;
        width: 0px !important;
    }

    .sub .border-right {
        border-right: none !important;
    }

    .sub .active {
        background-color: #ddd;
    }

    .pb-sm-2 {
        padding-bottom: 1rem !important;
    }

    .pt-sm-2 {
        padding-top: 1rem !important;
    }

}


.category .flex {
    width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sub .MuiAccordionSummary-root {
    margin: 10px 0px;
    padding: 5px 70px 0px 0px !important;
}

@media (min-width: 768px) {
    .sub_clr {
        margin-right: 16px;
    }

    .sub .col-md-6 {
        width: 47% !important;
    }

    .bottom-fix-modal {
        /* position: fixed; */
        bottom: 45px;
        left: 0;
        z-index: 6;
    }

}


.ecomcate {
    width: 100%;
    height: 80px;
    object-fit: contain;
    background: no-repeat;
}

.ecomcate-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    background: no-repeat;
}

.font {
    font-size: 10px !important;
}

.shadow-none {
    box-shadow: none !important;
}

.sellingProd {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.dashboard-category .alice-carousel__stage-item {
    display: inline-flex;
    justify-content: center;
}

.FashionDashboard-category {
    text-align: center;
}

.FashionDashboard-category .card {
    background-color: transparent !important;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.FashionDashboard-category .card img {
    width: 60px;
    height: 70px;
    margin-bottom: 10px;
    /* margin: 0px auto; */
    object-fit: contain;
    background-color: #fff;
    padding: 4px;
    border-radius: 15px;
    /* box-shadow: 0 10px 30px -8px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.FashionDashboard-category p {
    color: #333 !important;
    font-weight: 600 !important;
    font-size: 11px !important;
}

@media(min-width: 786px) {
    .react-swipeable-view-container {
        width: 500px;
        margin: 0px auto;
        /* height: 500px !important; */
        box-shadow: none !important;
    }

    .bottom-md-0 {
        bottom: 0 !important;
    }

    .md-scrollX {
        overflow-x: scroll;
        scrollbar-width: none;
    }

    .md-scrollX::-webkit-scrollbar {
        height: 4px;             
        width: 4px;             
        border: 1px solid #d5d5d5;
      }

      .w-md-500px {
        width: 500px !important; 
      }

      .w-md-430px {
        width: 430px !important; 
      }

    .map-over .react-swipeable-view-container {
        width: 35% !important;
        margin: 0px auto;
        /* height: 500px !important; */
        box-shadow: none !important;
    }

    .custom-swipe-shadow {
        box-shadow: none !important;
    }

    .map-over .ReactSwipeableBottomSheet--closed {
        box-shadow: none !important;
    }

    .variantSwipe .hr-drawer {
        width: 5% !important;
        height: 5px !important;
    }
}

.product .ReactSwipeableBottomSheet--open::-webkit-scrollbar {
    display: none;
}

.product .ReactSwipeableBottomSheet--open {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    min-height: 400px;
    max-height: 600px;
    /* background-color: #f3f3f3 !important; */
}

.card-img {
    width: 100%;
    height: 120px !important;
    object-fit: contain !important;
}

.ecom-card-img {
    width: 100%;
    height: 120px !important;
    object-fit: contain !important;
    /* position: relative;
    z-index: -1; */
}

.ecom-list-img {
    width: 100%;
    height: 120px !important;
    object-fit: contain !important;
    position: relative;
    z-index: 0;
    padding-top: 10px;
}

.container-img {
    position: relative;
    text-align: center;
    padding: 0.5rem;
}

.react-swipeable-view-container {
    box-shadow: none !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    z-index: 1300;
    position: relative;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiAccordion-root:before {
    background-color: #fff !important;
}

.MuiAccordionSummary-root {
    padding: 0px 4px !important;
}

.MuiAccordionDetails-root {
    padding: 8px 3px 0px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0 !important;
}

.MuiIconButton-root {
    padding: 0px 7px !important;
}

.MuiTypography-body1 {
    width: 100%;
}

.MuiList-padding {
    padding: 0px !important;
}

.select__option label {
    margin-bottom: 0px !important;
}

.filter_scroll {
    width: 100%;
    min-height: auto;
    /* max-height: 80vh;
      overflow-y: scroll; */
}

.filter_scroll::-webkit-scrollbar {
    display: none;
}

.over-scroll,
.select__menu-list {
    min-height: auto;
    /* max-height: 150px; */
    width: 100%;
    /* overflow-y: scroll !important; */
}

.over-select {
    height: 300px;
    width: 100%;
}

.css-26l3qy-menu {
    box-shadow: none !important;
}

.over-scroll::-webkit-scrollbar,
.select__menu-list::-webkit-scrollbar,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar {
    width: 4px;
}

/* Track */

.over-scroll::-webkit-scrollbar-track,
.select__menu-list::-webkit-scrollbar-track,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-track,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}

/* Handle */

.over-scroll::-webkit-scrollbar-thumb,
.select__menu-list::-webkit-scrollbar-thumb,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-thumb,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-thumb {
    background: #777;
    border-radius: 10px;
}

/* Handle on hover */

.over-scroll-::-webkit-scrollbar-thumb:hover,
.select__menu-list::-webkit-scrollbar-thumb-hover,
.MuiDrawer-paperAnchorBottom .variants::-webkit-scrollbar-thumb-hover,
.MuiDrawer-paperAnchorRight .variants::-webkit-scrollbar-thumb-hover {
    background: #333;
}

.css-9gakcf-option {
    background-color: #fff !important;
    color: #000 !important;
}

.css-1pahdxg-control {
    box-shadow: none !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}

.fix {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff;
    padding: 10px 0px;
}

.title {
    color: #222 !important;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 13px;
}

label {
    font-size: 13px;
    margin-bottom: 8px;
}


.sub-category .card img {
    width: 70%;
    height: 150px;
    margin: 0px auto;
    padding: 0;
}

.product_img {
    /* min-width: 80px;
      max-width: 100%; */
    width: 100%;
    object-fit: contain;
    height: 140px !important;
    /* display: flex;
      justify-content: center;
      align-items: center; */
}

.card-img-list {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.save {
    margin: 5px 0px;
    color: #008000 !important;
    font-size: 11px !important;
    font-weight: 600;
    letter-spacing: 0.4px;
}



.add {
    align-items: center;
    padding-top: 10px !important;
}

.add .pl-0 {
    padding-left: 0px !important;
}

.add .pr-0 {
    padding-right: 0px !important;
}

.list {
    width: 250;
}

.fullList {
    width: "auto";
}

.add .col-6 {
    width: 45% !important;
    margin: 0px auto;
}

/* .r-border{
      border-right: 1px solid #cccccc !important;
  } */


.md-none .head-room {
    height: 40px !important;
}

.MuiCollapse-root {
    z-index: 0;
    position: relative;
}

.subCategory {
    text-align: center;
    /* margin-top: 10px; */
}

.subCategory .card {
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
    width: 120px;
    height: 80px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    border-radius: 8px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.bg-none {
    background-color: transparent !important;
    box-shadow: none !important;
}

.subCategory .card img {
    width: 100% important;
    height: 60px !important;
    object-fit: contain;
}

.subCategory p {
    color: #333 !important;
}

.subCategory .cursoual-row::-webkit-scrollbar {
    display: none !important;
}

.subCategory .cursoual-flex {
    display: flex;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
}

.subCategory .cursoual-flex button {
    padding: 0 5px;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.subCategory .cursoual-row {
    display: flex;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    width: 100%;
    padding: 10px 0px;
    margin: 0 auto;
    /* background-color: #fff; */
}

.subCategory .cursoual-img {
    width: 25%;
    padding: 0 10px;
    text-align: center;
}

.subCategory .cursoual-img p {
    padding-top: 5px;
    text-align: center;
    width: 100% !important;
}

.cursoual-img p {
    font-size: 11px !important;
    padding-top: 5px;
    font-weight: 500 !important;
}

.card .row .col-md-4,
.card .row .col-6 {
    padding: 0px;
}

.filtersticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

.card-bg::-webkit-scrollbar {
    display: none;
}

.category-card .col-2,
.subCategory-card .col-1 {
    padding: 0px;
}

.subCategory-card h6 {
    font-size: 14px !important;
}

.subCategory-card p {
    font-size: 13px !important;
}

.cover img {
    width: 100%;
}

.cover .top-left {
    position: absolute;
    top: 10rem;
    margin-left: 1rem;
}

.top-left h4 {
    color: gold !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    text-shadow: 1px 2px 5px #000000;
    font-size: 35px;
}

.top-left h6 {
    color: #fff !important;
    margin-bottom: 10px;
    font-weight: 500 !important;
    text-shadow: 1px 2px 5px #000000;
    letter-spacing: 0.4px;
    font-size: 24px !important;
}

.top_brand {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #fdfcf4;
    border: 2px solid lightgoldenrodyellow; */
    background-color: #f9f9fd;
    border: 2px solid #f3f5fb;
    border-radius: 5px;
    height: 50px;
}


.brand .card,
.products .card {
    border-radius: 6px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.products .card img {
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.brand .card img {
    width: 100%;
    height: 50px;
    object-fit: contain;
    background: no-repeat;
}

.brand .card {
    background-color: #d7fbdd !important;
    cursor: pointer;
    border: 2px solid #cdfcdb;
}

small sup s {
    font-size: 9px;
}

.wishlist-heart {
    width: 30px;
    height: 30px;
    background-color: #fdfdfde6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}


@media (min-width: 786px) {
    .cover.sub .subBanner {
        width: 100% !important;
        height: 150px;
        object-fit: cover;
    }
}

.variant-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.estimate {
    font-size: 9px;
    letter-spacing: 0.4px;
    color: #a3a3a3;
    line-height: 16px;
}

.variants_clr {
    /* background-color: gray; */
    border: 1px solid #bdbcbc !important;
    border-radius: 50%;
    margin-right: 5px;
    width: 16px;
    height: 16px;
}

.tab-flex.border-none {
    border-bottom: none !important;
}

.tablist {
    overflow-y: scroll;
    scrollbar-width: none !important;
    -ms-scrollbar-width: none !important;
    position: fixed;
    /* border-top-left-radius: 20px;
border-top-right-radius: 20px; */
    box-shadow: 2px 0px 7px #ccc;
}

.tablist::-webkit-scrollbar {
    display: none !important;
}


@media(min-width: 786px) {
    .tablist.sticky_drawer {
        position: relative !important;
    }

    .mob_sidemenu {
        height: 100% !important;
    }

    .card.sidemenu {
        height: 100vh !important;
    }
}

.mt-8 {
    margin-top: 8rem;
}



/* Restaurants */

.sub {
    padding: 0px 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100% !important;
}

.sub .active {
    /* border-right: 2px solid #0058b0 !important; */
    border: 1px solid #bbb;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none !important;
}

.sub_clr {
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sub .row h6 {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000 !important;
    font-size: 13px !important;
}

.sub .row p {
    font-size: 11px;
    color: #959595 !important;
}

.sub .row {
    padding: 16px;
    /* width: 350px; */
    width: 100%;
}

.sub .row .col-6 {
    width: 45%;
    margin-right: 8px;
    margin-bottom: 10px;
}

.sub .border img {
    width: 100%;
    height: 70px;
    object-fit: contain;
}

@media(max-width: 786px) {
    .sub img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }

    .category .flex {
        width: 100vw !important;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .category .flex::-webkit-scrollbar {
        display: none;
        width: 0px !important;
    }

    .sub .border-right {
        border-right: none !important;
    }

    .sub .active {
        background-color: #ddd;
    }

}


.category .flex {
    width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.shop_accrodion .MuiAccordionSummary-root {
    padding: 0px 7px 0px !important;
    min-height: 58px !important;
}

.sub .MuiAccordionSummary-root {
    margin: 10px 0px;
    padding: 5px 70px 0px 0px !important;
}


.foodimg {
    width: 80px;
    height: 80px;
    position: relative;
    top: 7px;
    right: 3px;
}

.resBanner {
    width: 100%;
    border-radius: 6px;
    height: 180px;
    object-fit: fill;
}

.food-img {
    width: 100%;
    height: 70px;
    object-fit: contain;
}

.banner-img {
    width: 100% !important;
    height: 250px !important;
    object-fit: fill;
    border-radius: 5px !important;
    cursor: pointer;
}

.alice-carousel__dots {
    margin: 0px !important;
}

.today .alice-carousel {
    overflow-y: hidden !important;
}

.today .alice-carousel__wrapper {
    /* transform: rotate(90deg) !important; */
}

.today .alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
    background-color: #00ac0b !important;
}

.today .alice-carousel .alice-carousel__stage-item * {
    overflow-y: hidden !important;
    touch-action: pan-y !important;
}

.today .alice-carousel__dots-item:not(.__custom) {
    width: 20px !important;
    height: 3px !important;
    cursor: pointer;
    border-radius: 3px;
}

.food-name {
    /* font-size: 11px !important; */
    font-size: 10px !important;
    color: #000 !important;
    /* font-weight: 600; */
    font-family: "Celias";
}

.recommend {
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.head-img {
    width: 20px !important;
    height: 20px !important;
    object-fit: contain;
}

.next-img {
    width: 18px !important;
    height: 18px !important;
    object-fit: contain;
    padding-left: 4px;
}

.near-img,
.near-img-1 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.near-img-1 {
    height: 110px;
}


.near-img-1-unavailable {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    filter: grayscale(95%);
}

.near-img-1-unavailable {
    height: 110px;
}


.near-img-gray,
.near-img-gray-1 {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    /* filter: grayscale(100%); */
}

.near-img-gray-1 {
    height: 110px;
}

.bottom-offer {
    position: relative;
    bottom: 20px;
    height: 1rem;
    width: 100%;
}

.option-offer {
    text-align: center;
    align-items: center;
    width: 80%;
    padding: 2px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    line-height: 12px;
    margin: 0px auto;
}

.bottom-offer .offer,
.offer {
    text-align: center;
    align-items: center;
    width: 90%;
    padding: 2px 4px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    line-height: 13px;
    margin: 0px auto;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-offer p b,
.offer p b {
    color: #00ac0b !important;
    /* line-height: 20px; */
    letter-spacing: 0.3px !important;
    font-size: 12px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-family: "Celias";
    text-transform: uppercase;
}

@keyframes drop {
    0% {
        top: -350px;
    }

    100% {
        top: 0;
    }
}

.bottom-offer .offer small,
.offer small {
    color: #00ac0b !important;
    letter-spacing: 0.5px !important;
    font-size: 9px;
}

.nearfreetext {
    padding: 3px 10px;
    background-color: #dbffdd;
    border-radius: 5px;
    color: #00ae03 !important;
    font-size: 11px !important;
    letter-spacing: 0.4px !important;
    font-weight: 700 !important;
    text-align: center;
}

.res-search {
    border-radius: 10px !important;
    width: 100%;
    border: 1px solid #f2f2f2;
    padding: 8px;
    background: #f2f2f2;
    display: flex;
    color: #999;
    align-items: center;
}

.res-search span {
    font-size: 13px;
}

.res-search img {
    width: 15px;
}

.res-search-input {
    border: none !important;
    background: #f2f2f2;
    width: 100%;
    box-shadow: none !important;
}

.res-search-input:focus,
.res-search-input::after,
.res-search-input::before {
    box-shadow: none !important;
    border: none !important;
}

.letter-space {
    letter-spacing: 0.5px !important;
}

@media(min-width: 912px) {
    .banner-img {
        height: 300px !important;
    }

    .resBanner {
        height: 320px !important;
        /* object-fit: cover !important; */

    }


}




.select__option {
    font-size: 13px !important;
}

.select__control {
    right: inherit !important;
}

.w-12px {
    width: 12px;
    height: 12px;
}

.w-10px {
    width: 10px;
    height: 10px;
}

.w-17px {
    width: 17px;
    height: 17px;
}

.w-15 {
    width: 15%;
}

.w-6 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w20 {
    width: 20%;
}

.dashboard {
    width: 100%;
    background: white;
    /* margin-top: 10px; */
}

.w-10per {
    width: 10%;
}

.w-20per {
    width: 20%;
}

.w-30per {
    width: 30%;
}

.w-50per {
    width: 50%;
}

.w-60per {
    width: 60%;
}

.w-70per {
    width: 70%;
}

.w-90per {
    width: 90%;
}

.w-16per {
    width: 16%;
}

.w-84per {
    width: 84%;
}

.w-80per {
    width: 80%;
}


.w-22per {
    width: 22%;
}

.w-78per {
    width: 78%;
}

.w-10 {
    width: 16px;
    height: 16px;
}

.w-50px {
    width: 50px;
}

.w-98 {
    width: 98%;
}

.w-15per {
    width: 15%;
}

.w20 {
    width: 20%;
}

.w-20 {
    width: 20px;
    height: 20px;
}

.w-13 {
    width: 13px;
    height: 13px;
}

.w-15 {
    width: 15px;
    height: 15px;
}

.directions_width {
    width: 25px;
    height: 20px;
}

.w-35 {
    width: 35%;
}

.restaurant-panel {
    /* padding: 15px; */
    border-right: 1px solid var(--secondary-color);
}

.nav-bar {
    display: flex;
    align-items: center;
    line-height: 1;
}

.menu {
    margin-right: 28px;
}

.search-bar {
    width: 100%;
}

.search-bar .form-control {
    --input-bg: var(--secondary-color-lighter);
    width: 100%;
    border-color: transparent;
    border-radius: 15px;
}

.search-bar .btn {
    --btn-bg: var(--secondary-color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 6px 18px 18px;
    border-color: transparent;
    border-radius: 15px;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    transition: none !important;
    font-family: "Roboto", Arial, sans-serif !important;
}

.welcome-banner {
    position: relative;
    padding: 64px 180px 72px 324px;
    background: var(--danger-color-lighter);
    border-radius: 15px;
    white-space: nowrap;
}

.welcome-banner .delivery {
    position: absolute;
    top: -22px;
    left: 40px;
    width: 240px;
    height: 240px;
}

.welcome-banner h1 {
    margin: 0;
    font-size: 18px;
    color: var(--danger-color);
}

.welcome-banner p {
    margin: 14px 0 0 0;
    color: var(--secondary-color-darker);
}

.welcome-banner .learn-more {
    position: absolute;
    right: 36px;
    bottom: 36px;
    display: flex;
    align-items: center;
    color: var(--danger-color);
    text-decoration: none;
}

.welcome-banner .learn-more .arrow {
    margin-left: 12px;
    fill: currentColor;
}

#restaurants .section-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#restaurants .section-titles .section-title {
    font-size: 21px;
    font-weight: bold;
}

.date-select {
    display: flex;
    align-items: center;
    padding: 14px;
}

.date-select .clock {
    margin-right: 9px;
    fill: currentColor;
}

.date-select .chevron {
    margin-left: 15px;
    fill: currentColor;
}

.categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category-list {
    display: flex;
    padding: 0;
    margin-top: 10px;
    overflow-x: scroll;
    width: 100%;
    list-style-type: none;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    /* height: 150px; */
    justify-content: flex-start;
    align-items: center;
    /* padding-left: 14%; */
}

.category-list p {
    font-size: 11px !important;
}

.category-list::-webkit-scrollbar {
    display: none;
}

.category-list .alice-carousel__stage-item img {
    line-height: initial;
    width: 50px !important;
    height: 50px !important;
    object-fit: contain;
    margin-bottom: 10px;
}

.category-list .alice-carousel__wrapper {
    /* height: 130px !important; */
}

.category-list__item {
    box-sizing: border-box;
    width: 90%;
    height: 90px;
    margin: 10px;
    padding: 6px;
    border-radius: 12px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    transition: 0.3s;
    text-align: center;
    cursor: pointer;
}

.category-list__item:not(:last-child) {
    /* margin-right: 18px; */
}

.category-list__item a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    width: 80px;
}

.category-list__item .category-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 56px;
    background: white;
    border: 1px solid var(--secondary-color-lighter);
    border-radius: 50%;
}

.category-list__item .category-icon svg {
    fill: black;
    transform: scale(1.2);
}

.category-list__item .category-name {
    padding: 9px 18px 16px 18px;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.category-list__item:target {
    background: var(--warning-color);
}

.category-list__item:target .category-icon {
    border-color: transparent;
}

.category-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
}

.restaurant-list {
    /* display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 36px 27px;
      padding: 0;
      margin: 0;
      list-style-type: none; */
    /* width: 100vw;
      overflow-x: scroll;
      -ms-overflow-style: none; 
      scrollbar-width: none; */
}

.restaurant-list::-webkit-scrollbar {
    display: none;
}

.restaurant-list__item_card {
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.restaurant-list__item .restaurant-image {
    width: 100%;
    /* height: 160px !important; */
    border-radius: 6px;
    /* border-top-right-radius: 6px; */
    background-color: #f6f6f6;
    object-fit: cover !important;

}

.restaurant-list__item .container,
.ecom-list__item .container {
    position: relative;
    text-align: center;
    color: white;
    padding: 0px;
}

.restaurant-list__item .top-right,
.ecom-list__item .top-right {
    /* position: absolute;
    top: 3px;
    right: 3px;
    background-color: #fff;
    padding: 4px 6px;
    border-radius: 3px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */

    padding: 5px 6px;
    position: absolute;
    right: 0;
    background-color: #fff;
    line-height: 14px;
    letter-spacing: 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px inset !important; */
    border-top-left-radius: 2px;
    border-top-right-radius: 4px;
    /* width: 30px;
    height: 30px; */
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom-left-radius: 10px;
    animation: drop forwards 0s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.restaurant-list__item .top-right p b {
    color: #00ac0b !important;
    letter-spacing: 0.2px !important;
    font-family: "Poppins";
    font-size: 11px;
    font-weight: 500;
}

.restaurant-list__item .restaurant-name {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #fff !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.restaurant-list__item .restaurant-info,
.ecom-list__item .ecom-info {
    margin-top: 0px;
    align-items: flex-start;
}

.restaurant-list__item .restaurant-info p,
.ecom-list__item .ecom-info p {
    font-size: 11px;
    color: #eee !important;
}

.restaurant-list__resta_card,
.ecom-list_card {
    /* max-height: 110px; */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #ccc;
}

.restaurant-list__resta_card_wbb {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #ccc;
}

.restaurant-list__resta_card_wobb {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    /* border-bottom: 1px solid #ccc; */
}

.ecom-list_shop {
    height: 130px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}


.pl-02 {
    padding-left: 2px !important;
}

.restaurant-info .css-text-901oao {
    /* margin-bottom: 4px; */
}

.restaurant-list__item .restaurant-rate {
    /* margin-right: 9px; */
}

.restaurant-list__item .restaurant-rate>* {
    display: inline-flex;
    vertical-align: middle;
}

.restaurant-list__item .restaurant-rate svg {
    margin-right: 6px;
}

.restaurant-list__item .restaurant-category {
    color: #5e5e5e;
}

@media(min-width:786px) {
    .restaurant-list__item .restaurant-category {
        text-align: right;
    }
}

.user-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.user-info .buy-count {
    margin-left: 30px;
    width: 40px;
    height: 40px;
    padding: 10px;
    color: black;
    border-radius: 15px;
}

/* #order {
      margin-top: 15px;
    } */



.input-spinner .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: var(--secondary-color-lighter);
    padding: 12px;
    border-radius: 10px;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    font-family: "Roboto", Arial, sans-serif !important;
    transition: none !important;
}

.input-spinner .btn:hover,
.input-spinner .btn:focus,
.input-spinner .btn:active {
    border-color: var(--secondary-color-lighter);
}

.input-spinner .form-control {
    --border-color: var(--secondary-color-lighter);
    box-sizing: border-box;
    max-width: 48px;
    text-align: center;
    border-left: none;
    border-right: none;
}

.input-spinner .plus {
    border-left: none;
    padding-left: 0;
}

.input-spinner .minus {
    padding-right: 0;
}

.select__multi-value__label {
    font-size: 11px !important;
}

.select__value-container {
    padding: 3px !important;

}

.select__dropdown-indicator {
    display: none !important;
}

@media (max-width: 546px) {
    .restaurant-panel {
        /* padding: 15px !important; */
    }

    .sm-mt-60px {
        margin-top: 60px;
    }

    .pt-sm-5 {
        padding-top: 2.5rem;
    }

    .category-list__item {
        margin: 5px;
    }

    .category-list .alice-carousel__stage-item img {
        line-height: initial;
        width: 40px !important;
        height: 40px !important;
        object-fit: contain;
        margin-bottom: 10px;
    }

    .fda_section_row .section_product_tile {
        margin-top: 0px !important;
    }

    .fda_section_row hr {
        background-color: #bbb !important;
    }

}


@media (max-width: 786px) {
    .category-list__item {
        margin: 5px;
    }

    .fda_section_row .section_meta {
        margin-top: 10px;
    }
}


.fda_section_product_tile {
    position: relative;
    padding-bottom: 25px !important;
    padding-top: 25px;
}

.fda_section_product_tile:nth-child(odd) {
    background: rgba(0, 0, 0, 0.02);
}

.fda_title_row {
    font-size: 14px;
    font-weight: 500;
    color: rgba(38, 29, 86, 1);
}

.fda_section_row {
    margin-top: 20px;
}

.fda_section_row .section_product_tile {
    /* max-width: 272px; */
    margin-top: 20px;
}

.fda_section_row div>span {
    display: block;
}

.fda_section_row img {
    position: relative;
    width: 100px;
    border-radius: 100%;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
        inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
        inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
        0 8px 7px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.fda_section_row .section_meta {
    /* margin-left: 35px; */
    line-height: 18px;
}



.fda_section_row .product_title {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}

.fda_section_row .product_rating {
    font-size: 10px;
    color: #ffc75b;
}

.fda_section_row .product_rating .rating_avg {
    margin-left: 4px;
    color: #575757;
}

.fda_section_row .product_price {
    font-size: 11px;
    font-weight: 600;
    color: #4a4a4a;
}

.smalltext {
    font-size: 11px !important;
    letter-spacing: 0px !important;
    color: #00ac0b;
    font-family: "notosans" !important;
}

.del-icon {
    width: 16px !important;
    height: 16px !important;
}

.text-green {
    color: #00ac0b !important;
}

.text-green-500 {
    color: #50b156 !important;
}

.text-orange {
    color: #ffa500 !important;
}


.underline {
    text-decoration-color: #b1b1b1 !important;
}

.v-small {
    font-size: 11px !important;
    font-family: "Celias" !important;
}

.v-extra-small {
    font-size: 10px !important;
    font-family: "Celias" !important;
    text-decoration: line-through;
}

.brands {
    padding: 4px;
    line-height: 14px;
    letter-spacing: 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.deals {
    background-color: #ececfb;
}


.brands p {
    letter-spacing: -0.1px !important;

}

.foodbrand {
    /* background-color: #0b1a1c; */
}

.foodbrand img {
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.foodbrand .card {
    height: 103px;
    border-radius: 5px;
    border: 1px solid #ebebeb;
    box-shadow: none !important;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important; */
}

.today-deal .border {
    border-radius: 5px;
    border: 1px solid #ebebeb;
    box-shadow: none !important;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important; */
}

.today-deal img {
    width: 100%;
    height: 50px;
    object-fit: contain;
    background: no-repeat;
}


.text-gray-500 {
    color: rgba(107, 114, 128, 0.6) !important;
}

.type-img-big {
    width: 19px;
    height: 19px;
}

.type-img {
    width: 14px;
    height: 14px;
}

.type-img-small {
    width: 11px;
    height: 11px;
}

.star-rate {
    background-color: #03ae03;
    border-radius: 8px;
    padding: 1px 5px;
}

.star-rate p {
    font-size: 10px !important;
}

.sticky-section {
    /* top: 100px;
    position: fixed;
    overflow: hidden;
    width: 100% !important;
    height: 100vh !important;
    width: 25% !important; */
}

.smooth b {
    scroll-behavior: smooth;
    animation: wipe-enter 1s 1;
    transform: scaleY(1deg);
    transition: 2s ease;
    -moz-transition: 2s ease;
    -webkit-transition: 2s ease;
}

/* .food-page {
    background-color: #dfe8e1 !important;
} */

.bg-fff {
    background-color: #fff !important;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.bg-light-blue {
    background-color: #e0ebff !important;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.border-top-dash {
    border-top: 1px dashed #999;
}

.border-top-dashed {
    border-top: 1px dashed #d6d6d6;
}

.border-top-dot {
    border-top: 2px dotted #ccc;
}

.border-bottom-dot {
    border-bottom: 2px dotted #ccc;
}

.border-left {
    border-left: 1px solid #dadada !important;
}

.discount-img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.discount-hight {
    height: 60px;
    padding: 10px 6px;
}

.discount-hight p {
    line-height: 20px;
}

.shop_img {
    width: 100%;
    object-fit: contain;
    height: 130px;
}

.fda_food_row {
    overflow-y: scroll;
    height: 100vh;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.fda_food_row::-webkit-scrollbar {
    display: none;
}

.content {
    padding: 1.5rem;
}

/* .fda_food_row > .col-9 {
    max-width: 245px;
  }
  
  .fda_food_row > div:not(:first-child) {
    margin-left: -15px;
  } */
/* #section1 {
    height: 600px;
    background-color: pink;
  }
  
  #section2 {
    height: 600px;
    background-color: yellow;
  } */

#sidebarWrap ul {
    /* position: -webkit-sticky;
    position: sticky;
    width: 200px;
    float: left;
    top: 10px; */
    /* position:-webkit-sticky; position:sticky; top:0; */
    position: fixed;
    top: 180px;
    width: 200px;
    height: 200px;
}

#sidebar ul li a {
    cursor: pointer;
    color: #444444 !important;
}

#sidebar ul li {
    padding: 5px 0px;
}

#fda_product_tile {
    margin-top: 80px;
    margin-bottom: 20px;
    text-align: center;
}

#fda_product_tile span {
    display: block;
}

.fda_food_row div.food_tile {
    background-color: rgba(95, 95, 95, 0.05);
    font-size: 11px;
    padding: 0 0px 10px;
    border-radius: 25px;
    margin-bottom: 70px !important;
    text-align: center;
}

.fda_food_row div.food_tile.active {
    background-color: rgba(220, 230, 252, 1);
}

.fda_food_row div.food_tile .fda_product_img {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    padding: 20px;
    /* background-color: rgba(0, 0, 0, 0.05); */
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
        inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
        inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
        0 8px 7px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: -60px;
    margin-bottom: 18px;
}

div.food_tile.active .fda_product_img {
    background-color: rgba(220, 230, 252, 0.85);
}

.food_name {
    font-size: 14px;
    font-weight: 600;
    color: rgba(38, 29, 86, 1);
    /* margin-bottom: 12px; */
}

.food_detail {
    font-size: 12px;
    color: rgb(98, 98, 98);
    margin-bottom: 15px !important;
    text-align: center;
}

#food_meta {
    margin: 0;
    padding: 0;
}

#food_meta li {
    list-style: none;
    float: left;
    width: 50%;
    margin-bottom: 25px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(38, 29, 86, 1);
    text-align: center;
}

#food_meta li div {
    font-size: 12px;
    font-weight: 500;
    color: rgba(38, 29, 86, 0.5);
}

.btn-default {
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 15px;
    padding: 8px 40px !important;
    border-radius: 20px !important;
    font-size: 13px !important;
    font-weight: 300;
    letter-spacing: 0.5px;
    background-color: transparent;
    width: 100%;
    color: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
    display: flex;
}

#fda_product_tile span {
    display: block;
}

.btn-default:hover {
    border: 1px solid rgba(0, 0, 0, 0.04);
    background-color: rgb(0, 34, 51);
    color: #fff;
    box-shadow: 0 5px 1px rgba(0, 34, 51, 0.5);
    font-weight: 600;
}

/** End Section Body Part 1 **/

/** Start Section Body Part 2 **/

.food-list {
    display: grid;
    row-gap: 33px;
    /* padding: 10px 0px 10px 10px; */
    margin: 20px 0 0 0;
    font-size: 10px;
    font-weight: bold;
    list-style-type: none;
}

.food-list__item {
    /* align-items: center;
      display: flex;
      justify-content: center; */
    background-color: #fff;
    box-shadow: 0px 0px 4px #e4e4e4;
    border-radius: 5px;
    padding: 10px;
}

.food-list__item:not(:last-child) {
    grid-template-columns: 78px 20px 106px 1fr;
}

.food-list__item:last-child {
    grid-template-columns: 78px 20px 1fr;
}

.food-list__item .food-image {
    width: 100%;
    height: 32px;
    border-radius: 5px;
}

.food-list__item .food-price {
    color: var(--secondary-color-darker);
    justify-self: flex-end;
}

.order-panel {
    padding: 20px;
    background: var(--secondary-color-lightest);
}


.food_list_scroll {
    width: 100%;
    min-height: fit-content;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    /* background-color: #fbfbfb; */
    padding: 10px 12px;
    /* -ms-overflow-style: none !important;
    scrollbar-width: none !important; */
}

/* .food_list_scroll::-webkit-scrollbar {
      display: none;
    } */

.total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total-price .price {
    font-size: 22px;
    font-weight: bold;
}

.food_list_fixed p {
    font-size: 13px !important;
    color: #444444 !important;
    letter-spacing: 0.4px;
}

.food_list_fixed {
    padding: 10px;
}

#order .section-title {
    font-size: 18px;
    font-weight: bold;
}

.order-info {
    /* width: 240px;
      margin-top: 10px;
      padding: 10px 0px;
      color: white;
      background: var(--info-color);
      border-radius: 15px; */
}

.order-info .address,
.order-info .delivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-info .address-edit {
    color: var(--warning-color);
    text-decoration: none;
}

.order-info .delivery {
    margin-top: 20px;
}

.order-info .delivery .delivery-time {
    display: flex;
    align-items: center;
}

.order-info .delivery .time-btn {
    width: 40px;
    height: 40px;
    padding: 10px;
    color: var(--warning-color);
    background: var(--warning-color-transparent);
    border-color: transparent;
    border-radius: 15px;
}

.order-info .delivery .time-btn svg {
    fill: currentColor;
}

.order-info .delivery .time {
    margin-left: 12px;
}

.order-info .delivery .delivery-choose-time {
    color: var(--warning-color);
    text-decoration: none;
}

.buy-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 34px;
    border-top: 2px solid var(--secondary-color-lighter);
}

.buy-action .persons {
    font-size: 10px;
    font-weight: bold;
}

.buy-action .input-spinner {
    margin-top: 16px;
}

.buy-action .checkout-btn {
    display: flex;
    justify-content: space-between;

    align-items: center;
    /* padding: 30px; */
    color: white;
    border-radius: 10px;
}

.buy-action .checkout-btn .arrow {
    margin-left: 16px;
}

.navbar .active {
    color: #023 !important;
    border-right: 3px solid #023;
    font-weight: 600 !important;
}

.bottom_data .center {
    bottom: 60px;
    position: fixed;
    width: 100%;
    /* scroll-behavior: smooth; */
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    z-index: 5;
}

.bottom_data_viewcart .center {
    bottom: 120px !important;
    /* scroll-behavior: smooth; */
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    position: fixed;
    width: 100%;
    z-index: 3;
}

.bottom_data_viewcart .center.center-menu {
    width: 80%;
    margin-left: 10%;
}

.bottom_data h6,
.bottom_data_viewcart h6 {
    color: #fff !important;
}

.bottom_data .bg-dark,
.bottom_data_viewcart .bg-dark {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.8) !important;
    cursor: pointer;
    z-index: 2;
}

.bottom_data .footer-hidden-center {
    bottom: 60px;
    position: fixed;
    width: 100%;
    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
    z-index: 5;
    align-items: center;
    justify-content: center;
    display: flex;
    left: 0;
}

.bottom_data_viewcart .footer-hidden-center {
    bottom: 70px !important;
    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
    position: fixed;
    width: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;
    display: flex;
    left: 0;
}

.food_marg {
    margin: 15px 15px 60px !important;
}

.fda_img {
    align-items: center;
    display: flex;
    justify-content: center;
}

.md-padding {
    padding-right: 1.5rem;
}

.img-food-banner {
    width: 100%;
    height: auto;
}

@media (max-width: 546px) {
    .fda_food_row {
        /* padding-top: 18% !important; */
        /* overflow-y: hidden !important; */
        /* height: 100% !important; */
    }

    /* .card.bg-gray{
      background-color: #f5f5f5 !important;
    } */
    .fda_food_row div.food_tile .fda_product_img {
        width: 130px;
        height: 130px;
        padding: 10px;
    }

    .order-panel {
        padding: 0px !important;
    }

    .food_name {
        text-align: left !important;
        margin-bottom: 4px !important;
    }

    .food_detail {
        text-align: left !important;
        margin-bottom: 5px !important;
    }

    .fda_img .fda_product_img {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 0px !important;
    }

    .type_icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .fda_food_row div.food_tile {
        padding: 15px 0px !important;
        margin: 0px !important;
        background-color: #fff !important;
        /* box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);; */
    }

    .fda_img {
        padding: 0px !important;
    }

    #food_meta li div {
        text-align: left !important;
    }

    #food_meta li .amount {
        font-size: 14px !important;
        color: #222222 !important;
    }

    #food_meta li {
        margin-bottom: 0px !important;
        width: 100%;
    }

    .food_tile .btn-default {
        padding: 5px 10px !important;
        margin-bottom: 0px !important;
        /* box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
      inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
      inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
      0 8px 7px rgba(0, 0, 0, 0.15); */
        border: 1px solid #d0d0d0 !important;
    }

    .food_tile .flex_row {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .fda_food_row div.food_tile .fda_product_img {
        margin-bottom: 0px !important;
        width: 100px !important;
        height: 100px !important;
        padding: 8px !important;
    }

    .food_marg {
        margin: 10px 15px 0px !important;
        padding: 10px 0px 0px;
    }

    .section_card {
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .food_list {
        padding: 10px !important;
    }

    .content {
        padding: 0rem !important;
    }
}





@media (max-width: 786px) {
    .type_icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    .fda_img .fda_product_img {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 0px !important;
    }

    .fda_food_row .center {
        /* padding: 10px; */
    }

    .fda_food_row {
        /* padding-top: 20% !important; */
        overflow-y: hidden !important;
        height: 100% !important;
    }

    .order-panel {
        padding: 0px !important;
    }

    .view-cart-footer {
        background-color: #fff;
        bottom: 50px;
        position: fixed;
        /* padding: 16px 10px; */
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
    }

    .footer-hidden-view-cart-footer {
        background-color: #fff;
        bottom: 0px;
        position: fixed;
        /* padding: 16px 10px; */
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
    }

    .custom-padding {
        padding: 16px 10px;
    }

    .bg-darkgreen {
        /* background-color: #00ac0b; */
        background-color: #17791e;
        /* bottom: 50px; */
        bottom: 45px !important;
        position: fixed;
        padding: 16px 10px;
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
    }

    .bg-darkgreen-1 {
        background-color: #17791e;
        /* bottom: 50px; */
        /* bottom: 45px !important; */
        bottom: 43px !important;
        position: fixed;
        padding: 16px 10px;
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
        width: 100%;
        left: 0;
    }

    .footer-hidden-bg-darkgreen {
        background-color: #00ac0b;
        bottom: 0px;
        position: fixed;
        padding: 16px 10px;
        margin-top: 10px;
        color: #fff;
        z-index: 3;
        align-items: center;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        transition: 0.5s;
    }

    .shop .MuiDrawer-paperAnchorBottom {
        height: 100vh !important;
        border-radius: 0px;
        overflow-y: scroll !important;
    }

    .variant_drawer .MuiDrawer-paperAnchorBottom {
        height: 100vh !important;
        overflow-y: scroll !important;
        padding: 0 !important;
    }

    .shop .row .col-3 {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
    }


    .food_tile .btn-default {
        padding: 5px 10px !important;
        margin-bottom: 0px !important;
        /* box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
      inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
      inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
      0 8px 7px rgba(0, 0, 0, 0.15); */
        border: 1px solid #d0d0d0 !important;
    }

    .food_tile .flex_row {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .food_name {
        /* display: flex; */
    }

    .food_name .flex {
        text-align: left !important;
        width: 100%;
        display: flex !important;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .md-padding {
        padding-right: 0px !important;
        padding: 10px 10px !important;
    }

    /* nav ul {
      display: flex !important;
      position: sticky;
      top: 0px;
      text-align: left !important;
      overflow-x: scroll;
      -ms-overflow-style: none !important;
      scrollbar-width: none !important;
    }
  
    nav ul::-webkit-scrollbar {
      display: none;
    }
  
    nav li {
      box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
        inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
        inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
        0 8px 7px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin: 0px 5px 10px;
    }
    .pad {
      padding: 0px !important;
    }
  
    nav li .active{
      background-color: #023;
      border-radius: 20px;
      box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
      inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
      inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
      0 8px 7px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.1);
    }
  
    .navbar .active{
      color: #ffffff !important;
      border-right: none !important;
      font-weight: 600 !important;
    } */
}

@media (max-width: 920px) {
    .fda_food_row div.food_tile .fda_product_img {
        width: 130px;
        height: 130px;
        padding: 10px;
        margin-top: -50px;
    }
}

@media (min-width: 920px) {


    /* .img-food-banner {
        width: 100%;
        height: 200px;
    } */

    .fda_food_row div.food_tile .fda_product_img {
        width: 120px;
        height: 120px;
        padding: 10px;
        margin-top: -50px;
    }

    .food_name .flex {
        text-align: center !important;
        justify-content: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.fda_food_row div.food_tile:hover .fda_product_img {
    /* transform: rotateX(360deg);
      -moz-transform: rotateX(360deg);
      -webkit-transform: rotateX(360deg); */
    transform-origin: center;
    -moz-transform-origin: center;
    -webkit-transform-origin: center;
    transition: 2s ease;
    -moz-transition: 2s ease;
    -webkit-transition: 2s ease;

    animation: rotation 4s;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.15),
        inset 0 4px 0 rgba(0, 0, 0, 0.05), inset 0 -4px 0 rgba(0, 0, 0, 0.05),
        inset 0 10px 10px rgba(0, 0, 0, 0.09), 0 1px 0px rgba(0, 0, 0, 0.1),
        0 8px 7px rgba(0, 0, 0, 0.15);
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end !important;
    padding-top: 0rem !important;
    padding-bottom: 0.5rem;
    width: 100% !important;
}

.size-15 {
    font-size: 15px !important;
}

nav {
    white-space: nowrap;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* Only stick if you can fit */
@media (min-height: 546px) {

    /* nav ul {
      position: sticky;
      top: 0;
    } */
    .background-clr p {
        font-size: 12px;
    }
}

nav ul li a {
    display: block;
    padding: 0.5rem 1rem;
    color: #222222 !important;
    text-decoration: none;
    font-weight: 500;
}

nav ul li a.current {
    background: black !important;
    font-weight: 600 !important;
}

nav ul li {
    cursor: pointer;
}

#food_meta li div {
    text-align: center !important;
}

.background-clr {
    background-color: #023 !important;
    color: #fff !important;
}

/* section {
      padding: 2rem;
      margin: 0 0 2rem 0;
    } */

.react-switch-bg {
    height: 10px !important;
    width: 37px !important;
    margin: 0px;
    position: relative;
    background: #fff !important;
    border-radius: 5px !important;
    cursor: pointer;
    transition: background 0.25s ease 0s;
    box-shadow: none !important;
    border: 1px solid #eeeff0;
    /* left: 15px;
    top: 10px; */
    /* height: 10px !important; */
}

.react-switch-handle {
    background: none !important;
    box-shadow: none !important;
    top: -8px !important;
}

.switch_img {
    width: 15px;
}

.food_type_img {
    width: 10px !important;
}

.sm-center {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px 0px;
}

.menu-center {
    display: flex;
    flex-direction: row;
}

.menu-center:before,
.menu-center:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #333;
    margin: auto;
}

.MuiMenu-paper {
    /* width: 90%;
    padding: 8px;
    left: 16px !important;
    bottom: 50px !important;
    min-height: auto !important;
    overflow-y: scroll;
    max-height: 90vh !important;
    bottom: 20px !important;
    border-radius: 24px !important; */
    max-height: 70% !important;
}

.MuiPopover-root {
    /* background-color: #4848489c !important; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
    backdrop-filter: blur(20px);
}


.menu-card {
    padding: 8px;
    background-color: #fff;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.menu-close-btn {
    bottom: 60px !important;
    left: 0;
    position: fixed;
    width: 100%;
    transition: none !important;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow-y: hidden;
}

.menu-close-btn .btn {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.8) !important;
    cursor: pointer;
    z-index: 2;
    transition: none !important;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    overflow-y: hidden;
}

.MuiListItem-root.Mui-selected {
    background-color: transparent !important;

}

.MuiListItem-button,
.MuiListItem-root button {
    transition: none !important;
}

.MuiListItem-root.Mui-selected a.active p,
.MuiListItem-root.Mui-selected a.active p :hover {
    background-color: transparent !important;
    color: #00ac0b !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
}

.MuiList-root .MuiListItem-root.Mui-selected {
    list-style: circle !important;
}

.MuiPopover-root a {
    color: #222222;
}

div.MuiPopover-root {
    background-color: #0000004f !important;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: none !important;

}

.MuiMenu-paper {
    bottom: 170px;
    position: fixed !important;
}

.MuiMenuItem-root .flex {
    width: 100%;
}

.food_detail.marg {
    height: 34px !important;
}

.productImage {
    object-fit: contain;
    width: 100%;
    height: 200px;
}

.checkout-bottom {
    left: 0;
    bottom: 45px !important;
    bottom: 43px !important;
    position: fixed !important;
    border: none;
    z-index: 2;
    align-items: center;
    justify-content: center;
    display: flex;
    scroll-behavior: smooth;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s ease-in-out;
    z-index: 999;
}

.footer-hidden-checkout-bottom {
    left: 0;
    /* bottom: 0px !important; */
    bottom: 45px !important;
    position: fixed !important;
    border: none;
    z-index: 1;
    scroll-behavior: smooth;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s ease-in-out;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 999;
}

.bottom-fixed {
    left: 0;
    bottom: 43px !important;
    position: fixed !important;
    width: 45%;
    background-color: #fff;
    /* justify-content: center; */
    /* display: flex; */
    align-items: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    /* height: 50px !important; */
    border: none;
    z-index: 1;
}

.bottom-fixed.total .text-primary {
    border: 1px solid #003a57 !important;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

.bottom-fixed .text-primary p {
    color: #023 !important;
}

.justify-end {
    justify-content: flex-end;
}

.hr-drawer {
    width: 10%;
    background-color: #c5c5c5;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.54rem !important;
}

.hr-drawer-white {
    width: 10%;
    background-color: #fff;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.54rem !important;
}

.sticky_drawer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0px 5px;
    z-index: 122;
}

.ReactSwipeableBottomSheet--open {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    scrollbar-width: none !important;
}

.ReactSwipeableBottomSheet--closed {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 15px 3px 6px;
}

.bg-white-1 {
    background-color: #ffffff !important;
}

.bg-green {
    background-color: #00ac0b !important;
}

.bg-dark-green {
    background-color: darkgreen !important;
}


.MuiDrawer-paperAnchorRight {
    padding: 0px !important;
}

.filterMenu-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}

.filterMenu-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 236px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 17%;

}

.filterMenu-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 236px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 17%;


}


.filterOrder-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}

.filterOrder-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 236px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 30%;
    margin-right: 0px auto;
}

.filterOrder-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 236px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 30%;
    margin-right: 0px auto;


}

.subCategory {
    text-align: center;
    margin-top: 10px;
}

.subCategory .card {
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
    width: 120px;
    height: 80px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.subCategory .card img {
    width: 100% important;
    height: 60px !important;
    object-fit: contain;
}

.subCategory p {
    color: #333 !important;
}

.subCategory .cursoual-row::-webkit-scrollbar {
    display: none !important;
}

.subCategory .cursoual-flex {
    display: flex;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
}

.subCategory .cursoual-flex button {
    padding: 0 5px;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.subCategory .cursoual-row {
    display: flex;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    width: 100%;
    padding: 10px 0px;
    margin: 0 auto;
    /* background-color: #fff; */
}

.subCategory .cursoual-img {
    width: 25%;
    padding: 0 10px;
    text-align: center;
}

.subCategory .cursoual-img p {
    padding-top: 5px;
    text-align: center;
    width: 100% !important;
}

.cursoual-img p {
    font-size: 11px !important;
    padding-top: 5px;
    font-weight: 500 !important;
}

.card .row .col-md-4,
.card .row .col-6 {
    padding: 0px;
}



.card-bg::-webkit-scrollbar {
    display: none;
}

.category-card .col-2,
.subCategory-card .col-1 {
    padding: 0px;
}

.subCategory-card h6 {
    font-size: 14px !important;
}

.subCategory-card p {
    font-size: 13px !important;
}

.cover.sub {
    padding: 5px 0px 0px !important;
}

.cover.sub .subBanner {
    width: 100%;
    height: 130px;
    object-fit: cover;
}

.cover .top-left {
    position: absolute;
    top: 10rem;
    margin-left: 1rem;
}

.top-left h4 {
    color: gold !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    text-shadow: 1px 2px 5px #000000;
    font-size: 35px;
}

.top-left h6 {
    color: #fff !important;
    margin-bottom: 10px;
    font-weight: 500 !important;
    text-shadow: 1px 2px 5px #000000;
    letter-spacing: 0.4px;
    font-size: 24px !important;
}

.brand .card,
.products .card {
    border-radius: 6px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.products .card img {
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.text-dark-green {
    color: darkgreen !important;
}

.border-darkgreen {
    border: 1px solid darkgreen;
    border-radius: 6px;
}

.text-green {
    color: #00ac0b !important;
}


.h-50vh {
    height: 50vh !important;
}

.bg-gray {
    background-color: #f3f3f3 !important;
}

@media (max-width: 786px) {
    .subCategory.container {
        padding: 0 10px !important;
    }



    .cover .top-left {
        position: absolute;
        top: 6rem;
        margin-left: 1rem;
    }

    .top-left h4 {
        font-size: 24px !important;
    }

    .top-left h6 {
        font-size: 20px !important;
    }

    .cursoual-img {
        width: 23% !important;
        /* padding: 0 10px; */
        text-align: center;
    }

    .cursoual-row {
        width: 100% !important;
        overflow-x: scroll;
    }

    .subCategory .cursoual-row .card {
        width: 70px;
        height: 60px;
    }

    .subCategory .cursoual-row .card img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }

}



/* Grocery */
.offer p .space {
    letter-spacing: 0.1px !important;
}

.allcategory {
    width: 100%;
    height: 130px;
    object-fit: cover;
}

.sub {
    padding: 0px 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    width: 100% !important;
}

.sub .active {
    /* border-right: 2px solid #0058b0 !important; */
    border: 1px solid #bbb;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none !important;
}

.sub_clr {
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sub .row h6 {
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000 !important;
    font-size: 13px !important;
}

.sub .row p {
    font-size: 11px;
    color: #959595 !important;
}

.sub .row {
    padding: 16px;
    /* width: 350px; */
    width: 100%;
}

.sub .row .col-6 {
    width: 45%;
    margin-right: 8px;
    margin-bottom: 10px;
}

.sub .border img {
    width: 100%;
    height: 70px;
    object-fit: contain;
}

@media(max-width: 786px) {
    .sub img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }

    .category .flex {
        width: 100vw !important;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .category .flex::-webkit-scrollbar {
        display: none;
        width: 0px !important;
    }

    .sub .border-right {
        border-right: none !important;
    }

    .sub .active {
        background-color: #ddd;
    }

    .bottom-fix {
        position: fixed;
        bottom: 40px;
        left: 0;
        z-index: 6;
    }

}




.category .flex {
    width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sub .MuiAccordionSummary-root {
    margin: 10px 0px;
    padding: 5px 70px 0px 0px !important;
}


@media (min-width: 768px) {
    .sub_clr {
        margin-right: 16px;
    }

    .sub .col-md-6 {
        width: 47% !important;
    }

    .cover.sub .subBanner {
        width: 100%;
        height: 150px !important;
        object-fit: cover;
        border-radius: 6px;

    }

    .md-bottom-fix {
        position: fixed;
        bottom: 0px;
        /* left: 0; */
        z-index: 6;
    }

    .bottom-fixed {
        left: auto !important;
        bottom: 0px !important;
        position: fixed;
        width: 35.6% !important;
        background-color: #fff;
        /* justify-content: center; */
        /* display: flex; */
        align-items: center;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        /* height: 50px !important; */
        border: none;
    }

    .md-bottom-0 {
        bottom: 0px !important;
    }

    .filter_modal .bottom-fix {
        position: fixed;
        bottom: 0px;
        left: 0;
        /* z-index: 6; */
        width: 50% !important;
    }

    .addon-modal .bottom-custom-fixed {
        bottom: 0 !important;
        width: 35% !important;
        right: 0;
        left: 65% !important;
        border-bottom-left-radius: 20px;
    }

    .loction-picker-modal .map-bottom-div {
        position: absolute;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.freeBanner {
    width: 100%;
    height: 100px;
    object-fit: fill;
    border-radius: 5px;
    cursor: pointer;
}

.category {
    width: 100%;
    height: 80px;
    object-fit: contain;
    border-radius: 5px;
    /* background-color: #fff0d4; */

}

.cat p {
    line-height: 18px !important;
    font-weight: 600 !important;
    color: #333 !important;
    padding-top: 5px;
    font-size: 11px !important;
}


.disc {
    position: absolute;
    left: 0;
    background-color: #e60000;
    letter-spacing: 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important; */
    border-top-left-radius: 3px;
    width: 60px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1;

}

.disc_ecom {
    position: absolute;
    left: 0;
    background-color: var(--prime1);
    letter-spacing: 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important; */
    border-top-left-radius: 3px;
    width: 60px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1;
}

.disc_groce {
    position: absolute;
    left: 0;
    background-color: var(--prime3);
    letter-spacing: 0px !important;
    /* box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset !important; */
    border-top-left-radius: 3px;
    width: 60px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1;
}

@keyframes drop {
    0% {
        top: -350px;
    }

    100% {
        top: 0;
    }
}

.seemore {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.prods-img {
    width: 100%;
    height: 130px;
    object-fit: contain;
}

.prods_groc-img {
    width: 100%;
    height: 90px;
    object-fit: contain;
}

.text-red {
    color: #e60000 !important;
}

.text-custom-green {
    color: #00ac0b !important;
}

.Grocebottom-offer {
    position: relative;
    bottom: 20px;
    height: 1rem;
}

.Grocebottom-offer .Groceoffer,
.Groceoffer {
    text-align: center;
    align-items: center;
    width: 80%;
    padding: 2px 4px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    line-height: 13px;
    margin: 0px auto;
}

.Grocebottom-offer p b,
.Groceoffer p b {
    color: #e60000 !important;
    letter-spacing: -0.5px !important;
    font-size: 12px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ecom-offer {
    position: relative;
    bottom: 20px;
    height: 1rem;
}

.ecom-offer .ecomoffer,
.ecomoffer {
    text-align: center;
    align-items: center;
    width: 80%;
    padding: 2px 4px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    line-height: 13px;
    margin: 0px auto;
}

.ecom-offer p b,
.ecomoffer p b {
    color: #007dae !important;
    letter-spacing: -0.5px !important;
    font-size: 12px;
    animation: drop forwards 0.8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes drop {
    0% {
        top: -350px;
    }

    100% {
        top: 0;
    }
}


.option-round-div {
    /* border: 2px solid lightgoldenrodyellow; */
    border-radius: 50%;
    /* background-color: #fdfcf4; */
    width: 55px;
    height: 55px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    /* background-color: #e9f4fb; */
    /* border: 2px solid #e9f4fb; */
    margin: 0px auto;
}


.round-div {
    border: 2px solid lightgoldenrodyellow;
    border-radius: 50%;
    background-color: #fdfcf4;
    width: 80px;
    height: 80px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
}

.resta-round-div {
    border: 2px solid #dffad2;
    border-radius: 50%;
    background-color: #f6fff4;
    width: 80px;
    height: 80px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: rgba(210, 255, 221, 0) 0px 30px 60px -12px inset, rgb(250, 255, 251) 0px 18px 36px -18px inset;
}

.round-brand {
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.ecom-round-div {
    border-radius: 50%;
    background-color: #f9f9fd;
    border: 2px solid #f3f5fb;
    width: 80px;
    height: 80px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.ecom-round-brand {
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.container-fluid {
    margin: 0px auto;
}

.dashboard-category {
    text-align: center;
}

.dashboard-category .card {
    background-color: transparent !important;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
}

.dashboard-category .card img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    /* margin: 0px auto; */
    object-fit: contain;
    background-color: #fff;
    padding: 4px;
    border-radius: 15px;
}

.dashboard-category p {
    color: #333 !important;
    font-weight: 600 !important;
}

.cursoual-row::-webkit-scrollbar {
    display: none !important;
}

.cursoual-flex {
    display: flex;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
}

.cursoual-flex button {
    padding: 0 5px;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.cursoual-row {
    display: flex;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    width: 100%;
    padding: 10px 0px;
    margin: 0 auto;
    /* background-color: #fff; */
}

.cursoual-img {
    width: 20% !important;
    padding: 0 10px;
    text-align: center;
}

.cursoual-img p {
    padding-top: 5px;
    width: 100px;
}

.sr-only {
    display: none !important;
}

.swipe-img {
    margin-top: 10px;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0.5rem !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 1.5rem !important;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    background-color: #000;
}

.carousel-control-next,
.carousel-control-prev {
    top: 10px !important;
    width: 4% !important;
}

.bg-card {
    background-color: #fff;
}

@media (max-width: 786px) {
    .cursoual-img p {
        font-size: 11px !important;
        padding-top: 5px;
        font-weight: 500 !important;
        color: #505050 !important;
    }

    .cursoual-img {
        width: 30% !important;
        padding: 0 10px;
        text-align: center;
    }

    .cursoual-row {
        /* width: 100vw !important; */
    }

    /* .dashboard-category .card {
      width: 50px;
      height: 55px;
    } */

    /* .dashboard-category .card img {
      width: 50px;
      height: 50px;
    } */
    .swipe-img {
        height: 200px !important;
        padding: 10px !important;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 1rem !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: 10% !important;
    }

    .bg-white .border {
        margin: 2px;
    }
}


.bg-offer {
    background-color: #757575 !important;
    /* box-shadow: 2px 0px 6px #ccc; */
}


.bg-white .card-img {
    width: 100%;
    height: 150px !important;
    object-fit: contain;
}

@media(min-width: 786px) {
    .bg-white .bg-white_card {
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    .bg-white .border {
        border: none !important;
        margin: 0 7px 14px;
    }

    .react-tabs__tab-panel {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0px !important;
    }

    .react-tabs .page-main .bg-fff {
        /* overflow-y: scroll; */
        overflow-y: auto;
        scrollbar-width: none !important;
    }
}

.nearfreedel {
    padding: 3px 0px;
    /* background-color: #ffe0e0; */
    border-radius: 5px;
    color: #e60000 !important;
    font-size: 11px !important;
    letter-spacing: 0.4px !important;
    font-weight: 700 !important;
    text-align: center;
}

.filter-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    /*     z-index: 1; */
    background: white;
}

.main-nav,
.main {
    position: relative;
}

.main-nav {
    background: #fff;
    height: 80px;
    z-index: 150;
    margin-bottom: -80px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
}

.main-nav a {
    text-decoration: none;
    color: tomato;
    font-size: 1rem;
    padding-left: 10px;

}

header,
.main-nav-scrolled {
    position: fixed;
    width: 100%;
    top: 0;
}

.main {
    background: #f2f2e8;
    padding: 110px 50px 50px;
    column-count: 2;
    column-gap: 40px;
}


.grocery-banner {
    width: 100%;
    height: 150px;
    object-fit: fill;
}

.banner {
    height: 120px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    background-color: #d4e6f8 !important;
    /* border-radius: 6px; */
    border: 2px solid #fff;
}

.banner-image {
    height: 100px;
    object-fit: contain;
    width: 100%;
}

.filter-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}

.filter-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 100px !important;
    box-shadow: none !important;
}

.filter-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 100px !important;
    box-shadow: none !important;
}

.subcategory-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}


.subcategory-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 150px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: #fff;
}

.subcategory-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 150px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    background-color: #fff;
}

.prodview {
    width: 100%;
    height: 250px;
    object-fit: contain;
}

.MuiTabs-scroller {
    position: sticky !important;
    position: -webkit-sticky;
    top: 50px !important;
}

.pos-abs {
    position: relative;
    bottom: 80px;
}

.h-110px {
    height: 110px;
}

.overflow-x {
    overflow-x: scroll;
    scrollbar-width: 0;
    width: 100vh;
}

.active-bar {
    border-bottom: 2px solid #ea0000 !important;
    font-size: 13px;
    padding-bottom: 10px;
}

.non-active-bar {
    font-size: 13px;
    padding-bottom: 10px;
}

.ecom_active-bar {
    border-bottom: 2px solid var(--prime1) !important;
    font-size: 13px;
    padding-bottom: 10px;
}

.ecom_non-active-bar {
    font-size: 13px;
    padding-bottom: 10px;
}

.bg-red {
    background-color: #ea0000 !important;
}

.w-95 {
    width: 95% !important;
}

.search-img {
    width: 18px;
    height: 18px;
}

.font-small {
    font-size: 10px !important;
}


.filter2-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}

.filter2-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 150px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

}

.filter2-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 150px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.Subcategory_filter-headroom .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
    top: 100px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.Subcategory_filter-headroom .headroom--scrolled {
    transition: transform 200ms ease-in-out;
}

.Subcategory_filter-headroom .headroom--pinned {
    position: fixed;
    transform: translate3d(0px, -100%, 0px) !important;
    top: 100px !important;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}


.light-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.side {
    border-right: 1px solid #ccc !important;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 20%;
    height: 70vh;
}

.side li {
    width: 100%;
    padding: 7px;
    border-bottom: 1px solid #ccc;
}

.side .active-tab {
    /* background-color: #dadeff !important; */
    color: var(--prime1) !important;
}

.tabs_select {
    border-bottom: 1px solid var(--prime1);
    color: var(--prime1);
    font-weight: 600;
}

.coupon {
    width: 100%;
    height: 100px;
    background: radial-gradient(circle at right bottom, transparent 10px, #fafafa 0) top right / 50% 50px no-repeat,
        radial-gradient(circle at left bottom, transparent 10px, #fafafa 0) top left / 50% 50px no-repeat,
        radial-gradient(circle at right top, transparent 10px, #fafafa 0) bottom right / 50% 50px no-repeat,
        radial-gradient(circle at left top, transparent 10px, #fafafa 0) bottom left / 50% 50px no-repeat;
    filter: drop-shadow(1px 1px 2px #c5c5c5);
}

.coupon-img {
    width: 100%;
    height: 80px;
    object-fit: contain;
}

.coupon-row {
    height: 120px;
}

.border-right-dot {
    border-right: 1px dashed #cecece;
}

.border-none {
    border: none !important;
}

/* .round-border {
    border-radius: 50%;
    border: 2px solid #ffd6d6;
    background-color: #ffeef1;
    width: 80px;
    height: 80px;
    overflow: hidden;
} */

.round-cat {
    position: relative;
    width: 80px;
    height: 85px;
    object-fit: contain;
}

.catimg {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.bg-gray-500 {
    background-color: #fff7f7;
}

.bg-red-500 {
    /* background-color: #fca1a1; */
    background: -webkit-linear-gradient(110deg, #ffc7c7 60%, #ffd7d7 60%);
    background: -o-linear-gradient(110deg, #ffc7c7 60%, #ffd7d7 60%);
    background: -moz-linear-gradient(110deg, #ffc7c7 60%, #ffd7d7 60%);
    background: linear-gradient(110deg, #ffc7c7 60%, #ffd7d7 60%);
    border-radius: 5px;

    /* color: #fff !important;
    background: -webkit-linear-gradient(110deg, #ffbdbd 40%, rgba(0, 0, 0, 0) 30%), -webkit-radial-gradient(farthest-corner at 0% 0%, #fd9a9a 70%, #ffcaca 70%);
    background: -o-linear-gradient(110deg, #ffbdbd 40%, rgba(0, 0, 0, 0) 30%), -o-radial-gradient(farthest-corner at 0% 0%, #fd9a9a 70%, #ffcaca 70%);
    background: -moz-linear-gradient(110deg, #ffbdbd 40%, rgba(0, 0, 0, 0) 30%), -moz-radial-gradient(farthest-corner at 0% 0%, #fd9a9a 70%, #ffcaca 70%);
    background: linear-gradient(110deg, #ffbdbd 40%, rgba(0, 0, 0, 0) 30%), radial-gradient(farthest-corner at 0% 0%, #fd9a9a 70%, #ffcaca 70%); */

}










.subCategory {
    text-align: center;
    margin-top: 10px;
}

.subCategory .card {
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
    width: 120px;
    height: 80px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    background-color: #ffffff;
    border-radius: 8px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.subCategory .card img {
    width: 100% important;
    height: 60px !important;
    object-fit: contain;
}

.subCategory p {
    color: #333 !important;
}

.subCategory .cursoual-row::-webkit-scrollbar {
    display: none !important;
}

.subCategory .cursoual-flex {
    display: flex;
    /* box-shadow: 0 0 8px rgba(172, 172, 172, 0.25) ; */
}

.subCategory .cursoual-flex button {
    padding: 0 5px;
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 5px !important;
}

.subCategory .cursoual-row {
    display: flex;
    overflow-y: hidden !important;
    overflow-x: scroll !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    width: 100%;
    padding: 10px 0px;
    margin: 0 auto;
    /* background-color: #fff; */
}

.subCategory .cursoual-img {
    width: 25%;
    padding: 0 10px;
    text-align: center;
}

.subCategory .cursoual-img p {
    padding-top: 5px;
    text-align: center;
    width: 100% !important;
}

.cursoual-img p {
    font-size: 11px !important;
    padding-top: 5px;
    font-weight: 500 !important;
}

.card .row .col-md-4,
.card .row .col-6 {
    padding: 0px;
}



.card-bg::-webkit-scrollbar {
    display: none;
}

.category-card .col-2,
.subCategory-card .col-1 {
    padding: 0px;
}

.subCategory-card h6 {
    font-size: 14px !important;
}

.subCategory-card p {
    font-size: 13px !important;
}

.cover img {
    width: 100%;
}

.cover .top-left {
    position: absolute;
    top: 10rem;
    margin-left: 1rem;
}

.top-left h4 {
    color: gold !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    text-shadow: 1px 2px 5px #000000;
    font-size: 35px;
}

.top-left h6 {
    color: #fff !important;
    margin-bottom: 10px;
    font-weight: 500 !important;
    text-shadow: 1px 2px 5px #000000;
    letter-spacing: 0.4px;
    font-size: 24px !important;
}

@media (max-width: 786px) {
    .subCategory.container {
        padding: 0 10px !important;
    }

    .sticky-sm-61px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 61px;
        z-index: 3;
    }

    .cover img {
        height: 170px;
    }

    .cover .top-left {
        position: absolute;
        top: 6rem;
        margin-left: 1rem;
    }

    .top-left h4 {
        font-size: 24px !important;
    }

    .top-left h6 {
        font-size: 20px !important;
    }

    .cursoual-img {
        width: 23% !important;
        /* padding: 0 10px; */
        text-align: center;
    }

    .cursoual-row {
        width: 100% !important;
        overflow-x: scroll;
    }

    .subCategory .cursoual-row .card {
        width: 70px;
        height: 60px;
    }

    .subCategory .cursoual-row .card img {
        width: 100%;
        height: 50px;
        object-fit: contain;
    }

}

.h-70 {
    height: 70vh;
}


/* ////// */
.react-tabs .bg {
    background-color: rgb(255, 255, 255) !important;
    /* padding: 5px; */
    border-bottom: 2px solid #e4e4e4;
    display: inline-grid !important;
}


.fix-height {
    height: 80vh;
    overflow: hidden;
}

.h-30px {
    height: 30px;
}

.h-40px {
    height: 40px;
}

.h-33px {
    height: 33px;
}

.h-35px {
    height: 35px;
}

.h-46px {
    height: 46px;
}

.react-tabs .bg {
    border-bottom: none !important;
}

.img-bottom-right {
    position: absolute;
    bottom: 10px;
    right: 0px;
}

.img-top-right {
    position: absolute;
    top: 3px;
    right: 0px;
}

.tab-flex {
    margin-right: 10px;
    display: flex;
    cursor: pointer;
}

.tab-flex::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

.tablist .cursoual-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tablist {
    z-index: 9 !important;
    /* background-color: #fff; */
}

.react-tabs__tab--selected::after {
    border: none !important;
    display: none !important;
}

.react-tabs__tab--selected {
    border-bottom: 2px solid var(--prime1) !important;
    color: var(--prime1) !important;
    box-shadow: none !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 20px;
    font-weight: 600;
}



.react-tabs__tab--selected p {
    color: var(--prime1) !important;
    font-weight: 600;
}

.overflowYScroll-400 {
    height: 400px;
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.overflowYScroll {
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.overflowY {
    overflow-y: scroll;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    overflow-x: hidden;
}

@media(max-width: 560px) {
    .tab-flex {
        /* width: 80vw; */
        margin-right: 0px !important;
    }

    .tablist {
        padding: 0px !important;
    }

    .cursoual-flex button {
        padding: 0px !important;
    }

    .cart-swipesheet-bottom-div {
        bottom: 40px !important;
    }

    .cart-swipesheet-bottom-div-gray {
        bottom: 40px !important;
    }

}

@media(min-width: 786px) {

    .md-h-300px {
        height: 300px;
    }

    .sticky-md-190px {
        position: sticky;
        position: -webkit-sticky;
        top: 190px;
        z-index: 1;
    }

    .w-md-75 {
        width: 75%;
    }

    .w-md-50 {
        width: 50%;
    }

    .sticky-md-210px {
        position: sticky;
        position: -webkit-sticky;
        top: 210px;
        z-index: 1;
    }

    .overflowYScroll {
        height: 100vh;
        overflow-y: scroll;
        -ms-overflow-style: none !important;
        scrollbar-width: none !important;
    }

    .overflowYScroll::-webkit-scrollbar {
        display: none;
    }

    .flash-big {
        height: 130px !important;
        /* border: 1px solid #dee2e6; */
        border: none !important;
        padding: 6px !important;
        border-radius: 6px;
        background-color: #d7fbdd;
    }

    .flash-big img {
        object-fit: contain;
        background-color: #fff !important;
        height: 80px !important;
        padding: 5px;
        border-radius: 6px;
    }

    .flash-small {
        /* border: 1px solid #dee2e6; */
        border-radius: 6px;
        padding: 6px !important;
        height: 130px !important;
        background-color: #d7fbdd;
    }

    .flash-small img {
        background-color: #fff !important;
        height: 80px !important;
        padding: 5px;
        border-radius: 6px;
        object-fit: contain;
    }

    .sticky-md-59px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 59px;
        z-index: 3;
    }

    .sticky-md-50px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 50px;
        z-index: 3;
    }

    .sticky-md-60px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 60px;
        z-index: 3;
    }

    .sticky-md-100px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 100px;
        z-index: 2;
    }

    .sticky-md-110px {
        position: sticky;
        position: -webkit-sticky;
        top: 110px;
        z-index: 3;
    }

    .md-w-100 {
        width: 100% !important;
    }

    .l-md-14 {
        left: 14px !important;
    }

    .l-md-10 {
        left: 10px !important;
    }

    .l-md-0 {
        left: 0px !important;
    }

    .l-md-20 {
        left: 20px !important;
    }

    .left-md-18 {
        left: 18px;
    }

    .r-md-0 {
        right: 0;
    }

    .sticky-md-60px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 60px;
        z-index: 2;
    }


    .sticky-md-125px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 125px;
        z-index: 2;
    }

    .sticky-md-80px {
        position: sticky;
        position: -webkit-sticky;
        /* top: 105px; */
        top: 80px;
        z-index: 2;
    }

    .sticky-md-72px {
        position: sticky;
        position: -webkit-sticky;
        top: 72px;
        z-index: 2;
    }

    
.web-overflowXScroll {
    overflow-x: scroll;
    width: 100%;
    /* scrollbar-width: none !important;
    -ms-scrollbar: none; */
    overflow-y: hidden;
}

.web-overflowXScroll::-webkit-scrollbar {
    height: 5px;
}

.web-overflowXScroll-scrollbar {
    overflow-x: scroll;
    width: 100%;
    /* display: none !important; */
}

.web-overflowXScroll-scrollbar::-webkit-scrollbar {
    /* display: none !important; */
}
  
  /* Track */
  .web-overflowXScroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 6px;
  }
   
  /* Handle */
  .web-overflowXScroll::-webkit-scrollbar-thumb {
    background: #d4d4d4; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  .web-overflowXScroll::-webkit-scrollbar-thumb:hover {
    background: #666; 
    border-radius: 6px;
  }

}

/* .react-tabs__tab--selected {
    border-bottom: 3px solid #0096d6 !important;
    margin: 0 !important;
} */


.react-tabs__tab {
    padding: 10px 20px !important;
    margin: 0px !important;
    font-size: 12px;
    color: #777;
}

.overflowXScroll {
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none !important;
    -ms-scrollbar: none;
    overflow-y: hidden;
}

.overflowXScroll::-webkit-scrollbar {
    display: none !important;
}

.overflowXScroll-scrollbar {
    overflow-x: scroll;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.overflowXScroll-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.bg-gray-300 {
    background-color: #e1e7f3 !important;
}

.flash-big {
    height: 235px;
    padding: 8px 0px;
    border-bottom: 1px solid #dee2e6;

}

.flash-small {
    height: 150px;
    padding: 8px 0px;
}

.flash-big img {
    object-fit: contain;
    background: no-repeat;
    height: 180px;
}

.flash-small img {
    object-fit: contain;
    background: no-repeat;
    height: 90px;
}

.category-css {
    width: 60px;
    height: 60px;
    border: 1px solid #b7b7b7;
    border-radius: 50%;
    background-color: #e3e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.non-flex-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.main-category-css {
    background-color: beige;
    border-radius: 4px;
}


.read-or-hide {
    color: rgb(3, 74, 149);
    cursor: pointer;
}

.displayedTime .border {
    border: none !important;
}

[data-darkreader-inline-color] {
    color: none !important;
}

.grocery_ul li {
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 22px;
}

.custom-bottom-sheet {
    z-index: 8;
    display: none;
    background-color: "white";
}

.custom-bottom-sheet__body {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

@media (max-width: 767px) {
    .custom-bottom-sheet {
        display: unset;
    }

    .img_over_content_shop {
        padding-bottom: 16px !important;
    }

    .sm-shop-item {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
        /* bottom: 20px; */
        border-left: none !important;
    }

    .pt-sm-2 {
        padding-top: 1rem;
    }

    .sticky-sm-60px {
        position: sticky;
        position: -webkit-sticky;
        z-index: 3;
        top: 59px;
    }

    .sticky-sm-57px {
        position: sticky;
        position: -webkit-sticky;
        z-index: 2;
        top: 57px;
    }

    .sticky-sm-145px {
        position: sticky;
        position: -webkit-sticky;
        top: 145px;
        z-index: 1;
    }

    .sticky-sm-150px {
        position: sticky;
        position: -webkit-sticky;
        top: 150px;
        z-index: 1;
    }

}

.styles-module_wrapper__1I_qj {
    z-index: 99 !important;
}

/* .styles-module_navigation__1pqAE, .styles-module_close__2I1sI {
    opacity: 0.4 !important;
} */



.step {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.v-stepper {
    position: relative;
    /*   visibility: visible; */
}


/* regular step */
.step .circle {
    background-color: white;
    border: 3px solid gray;
    border-radius: 100%;
    width: 20px;
    /* +6 for border */
    height: 20px;
    display: inline-block;
}

.step .line {
    top: 20px;
    left: 9px;
    height: 120px;
    height: 100%;
    position: absolute;
}

.step.completed .circle {
    visibility: visible;
    background-color: rgb(2, 123, 55);
    border-color: rgb(2, 123, 55);
}

.step.completed .line {
    border-left: 3px solid rgb(2, 123, 55);
}

.step.active .circle {
    visibility: visible;
    border-color: rgb(2, 123, 55);
}

.step.active .line {
    border-left: 3px solid rgb(2, 123, 55);
}

.step:last-child .line {
    border-left: 3px solid white;
    z-index: -1;
    /* behind the circle to completely hide */
}

.step .contents {
    margin-left: 20px;
    display: inline-block;
}

.MuiDropzoneSnackbar-successAlert {
    background-color: #4caf50;
    text-align: left !important;
}

.MuiDropzoneArea-root {
    min-height: 80px !important;
    cursor: pointer;
    margin-top: 15px !important;
}

.MuiDropzoneArea-text {
    margin-top: 12px !important;
    font-size: 15px !important;
}

.MuiDropzoneArea-icon {
    width: 30px !important;
    height: 30px !important;
}

.MuiGrid-spacing-xs-1 {
    margin-top: 15px !important;
}


.css-b62m3t-container {
    position: absolute !important;
    box-sizing: border-box;
    z-index: 30;
    background: #fff;
    right: 0px;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
}

.track-icon {
    width: 25px;
}

.tab-hidden input[type="radio"]:checked {
    visibility: hidden;
}

.tab-hidden input[type="radio"] {
    visibility: hidden;
    display: none;
}

.tab-hidden label input:checked {
    color: var(--prime1) !important;
}

.btn-outline-green {
    border: 1px solid #00ac0b;
    color: #00ac0b;
    background-color: #fff;
}

.btn-outline-prime1 {
    border: 1px solid #007dae;
    color: #007dae;
    background-color: #fff;
}

.border-prime1 {
    border: 1px solid #0058b0 !important;
}

.map-over {
    z-index: 9;
    position: absolute;
    height: auto !important;
    border-radius: 0 !important;
    width: 100%;
}

.bottom {
    bottom: 0px;
    position: fixed;
    left: 0;
}

.tab-active {
    color: #007dae !important;
    border-bottom: 2px solid #007dae;
}

.prod_img {
    border-radius: 6px;
    width: 100%;
    height: 70px;
    object-fit: contain;
}

.order_cancel {
    padding: 3px 6px;
    background: bisque;
    border-radius: 10px;
}

.order_process {
    padding: 3px 6px;
    background: #dcffd9;
    border-radius: 10px;
    color: green !important;
}

.order_completed {
    padding: 3px 6px;
    background: bisque;
    border-radius: 10px;
}

/* .accordion-tabs */

.sticky-115px {
    position: sticky;
    position: -webkit-sticky;
    top: 115px;
}

.w-80px {
    width: 80px;
}

.left-none {
    left: auto !important;
}

.fs-7 {
    font-size: 13px !important;
}

.sticky-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 11;
    border-bottom: none !important;
    background-color: #fff;
}

.extra-small {
    font-family: "Celias-Light" !important;
    font-size: 10px !important;
    font-weight: 300;
    letter-spacing: 0.4px !important;
}

.help-btn {
    position: fixed;
    right: 6px;
}

.btn-green {
    background: #00ac0b;
    color: #fff;
}

.btn-green:hover {
    background-color: #e8ffe9;
}

.text-darkgray {
    color: #555 !important;
}

.MuiSlider-rail {
    border: 2px solid #dcdee6;
}

.MuiSlider-track {
    border: 2px solid #6ba7f4;
}

.MuiSlider-root {
    color: #007dae !important;
}

/* ////// Chat box */

.cs-chat-container .cs-message-input {
    padding: 13px 0px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none !important;
}

.cs-message-input {
    position: fixed !important;
    bottom: 0px;
    width: 100%;
}

.cs-message-input__content-editor-wrapper {
    background-color: #dae4ec !important;
}

.cs-message-input__content-editor-container {
    background-color: #dae4ec !important;
}

.cs-message-input__content-editor {
    background-color: #dae4ec !important;
}

.cs-button--attachment {
    color: #555 !important;
}

.cs-button--send {
    color: #007dae !important;
}


.cs-message--incoming .cs-message__content {
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 2px;
}

.cs-message--outgoing .cs-message__content {
    background-color: #007dae !important;
    color: #fff !important;
}

.cs-message-separator::before,
.cs-message-separator::after {
    background-color: #e1edf7 !important;
}

.cs-message-separator {
    color: #6ea9d7 !important;
    font-size: 0.7em !important;
    letter-spacing: 0.4px;
}

.cs-message-list__scroll-wrapper {
    padding: 0.5em 1.2em 0 .8em !important;
}

.cs-message-list .cs-message-separator {
    margin: 1.2em 0 !important;
}

.cs-message__content {
    font-size: 12px !important;
    letter-spacing: 0.3px !important;
}

.cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
    border-radius: .7em .7em 0 .7em !important;
}

.cs-message.cs-message--incoming.cs-message--first .cs-message__content {
    border-radius: .7em .7em .7em 0 !important;
}

.cs-message.cs-message--incoming.cs-message--last .cs-message__content {
    border-radius: 0 .7em .7em .7em !important;
}

.cs-message-list .cs-typing-indicator {
    bottom: 11px !important;
    font-size: 12px !important;
    right: 0px !important;
    background-color: #fdfdfd !important;
}

.cs-typing-indicator__dot {
    background-color: #007dae !important;
}

.cs-typing-indicator__text {
    color: #007dae !important;
}

.cs-message__avatar {
    width: 30px !important;
}

.cs-avatar.cs-avatar--md {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
}

.cs-avatar>img {
    height: 30px !important;
    object-fit: contain;
}

.cs-message--incoming.cs-message--avatar-spacer {
    margin-left: 37px !important;
}

.cs-message-input__content-editor-container {
    font-size: 13px !important;
}

.cs-message--outgoing .cs-message__content .cs-message__image-content img {
    border-radius: 6px;
    object-fit: cover;
    background: #fff !important;
}

.cs-message__content {
    padding: 0px !important;
}

.cs-message__custom-content,
.cs-message__html-content {
    padding: .6em .9em !important;
}

.ps__rail-y {
    width: 4px !important;
}

.ps__thumb-y {
    background-color: #cecece !important;
    width: 3px !important;

}

.ps-0_7rem {
    padding-left: 0.7rem;
}

.bottom-fix-ab {
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 6;
}







.active-shop-name {
    color: #00ac0b;
    font-weight: bold;
}

.active-shop-border {
    border: 1px solid #00ac0b;
}

.active-ecomshop-name {
    color: #007dae;
    font-weight: bold;
}

.active-ecomshop-border {
    border: 1px solid #007dae;
}

.active-groceshop-name {
    color: #e60000;
    font-weight: bold;
}

.active-groceshop-border {
    border: 1px solid #e60000
}

.delivery_man_img {
    height: 35px;
    object-fit: cover;
    border-radius: 5px;
}

.border-bottom-prime1 {
    border-bottom: 2px solid #007dae;
}

.glassy {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.food-customer-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.food-customer-profile-div {
    /* background-color: #eaf0eb !important; */
}

.border-top-radius {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.border-bottom-radius {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.restaurant-list__item,
.ecom-list__item {
    /* padding: 0px 8px !important; */
    position: relative;
}

.img_over_content {
    position: absolute;
    /* background: linear-gradient(to top, rgb(0, 0, 0), transparent); */
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 0;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
    width: 100%;
}

.img_over_content_foods {
    position: relative;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 52px;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.img_over_content_resta {
    position: relative;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 39px;
    padding: 10px 5px 3px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 600;
}

.img_over_content_ecom {
    position: relative;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    bottom: 28px;
    padding: 10px 5px 3px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 600;
}

.img_over_content_shop {
    position: relative;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    /* bottom: 113px; */
    bottom: 145px;
    padding: 3px;
    font-weight: 600;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
}

.video_over_content {
    position: relative;
    background: linear-gradient(to top, rgb(0, 0, 0), transparent);
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    bottom: 37px;
    padding: 3px;
    font-weight: 600;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.near-shop-name {
    position: relative;
    bottom: 25px;
}

/* .img_over_content_shop:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.8) 100%
    );
} */

.img_over_content_top {
    position: relative;
    background: linear-gradient(to bottom, rgb(0, 0, 0), transparent);
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent); */
    top: 0px;
    padding: 3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-weight: 600;
}

.over_content_top {
    position: relative;
    bottom: 100px;
    right: 0px;
    left: auto;
    width: 100%;
    margin: auto;
    background: linear-gradient(to bottom, rgb(0, 0, 0), transparent);
    padding: 2px 3px 0;
}


.text-white {
    color: #fff !important;
}

.bottom-60 {
    position: relative;
    bottom: 60px;
}

.free-shipping-icon {
    width: 25px;
    height: 25px;
}

.h-100vh {
    height: 100vh;
}

.h-120vh {
    height: 120vh;
}

.h-85vh {
    min-height: 85vh;
}

.paymentpage-h {
    height: calc(100vh - 100px);
}

.food_banner_img {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    object-fit: fill;
}

.subcate_banner_img {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    object-fit: cover;
}

.resta_shop_img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.resta_shop_item {
    height: 230px;
    position: relative;
}


.wishlist-top {
    /* position: absolute;
    right: 10px;
    top: 10px;
    left: 90px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center; */
    position: absolute;
    /* left: 88px; */
    /* left: 90px; */
    /* top: 10px; */
    width: 26px;
    height: 25px;
    /* width: 35px;
    height: 35px; */
    border-radius: 50%;
    background-color: #d2d2d299;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 2px; */
    margin-top: 5px;
    margin-left: 5px;
}

.wishlist-top-css {
    position: absolute;
    /* left: 88px; */
    /* left: 90px; */
    /* top: 10px; */
    width: 26px;
    height: 25px;
    /* width: 35px;
    height: 35px; */
    border-radius: 50%;
    background-color: #d2d2d299;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 2px; */
    margin-top: 5px;
    margin-left: 5px;

}

.vendor-list-wishlist {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.sticky-menu {
    position: sticky;
    position: -webkit-sticky;
    top: 20px;
    z-index: 99;
    /* left: 0; */
}


.navbar-fixed-top {
    position: relative;
}

.navbar-fixed-top .custom-navbar {
    position: fixed;
    top: 105px;
    /* right: 25px; */
    z-index: 9;
    background-color: #fff;
}

.navbar-fixed-top .custom-navbar-cart {
    position: fixed;
    top: 105px;
    width: 30%;
    z-index: 9;
    background-color: #fff;
}


.search-div {
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    height: 38px;
    /* border: none !important; */
}


.search-div-filter {
    /* background-color: #4f4f4f; */
    color: #fff;
    height: 36px;
    /* border-radius: 10px;
    border: none !important; */
    padding: 0px 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    right: 16px;
    position: absolute;
    cursor: pointer;
}

.search-alice .alice-carousel {
    padding: 0px !important;
}

.search-resta-img {
    width: 100%;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
}

.resta-radio-button {
    margin-bottom: 10px;
    margin-right: 6px;
}

.resta-radio-button input:checked+span {
    color: #fff;
    background-color: #788279;
    padding: 4px 14px !important;
    border: 1px solid #788279;
}

.resta-radio-button span {
    color: #666666;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px !important;
    border-radius: 16px;
    font-size: 12px;
    /* margin-right: 5px; */
}

.resta-radio-button input {
    position: absolute;
    display: none;
    color: #333;
}

.shop_accrodion .MuiAccordionSummary-content {
    margin: 0 !important;
}

.shop_accrodion .MuiSvgIcon-root {
    font-size: 1.3rem;
    color: #222;
}

.btn-radius {
    border-radius: 20px !important;
}



.all_food_checkbox {
    display: inline-block;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border: 1px solid #bbb;
    border-radius: 3px;
}


.opacity {
    opacity: 0.5 !important;
}

.resta_imgs {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.food_search {
    background-color: transparent !important;
    color: #fff;
}

.food_search::placeholder {
    color: #fff !important;
}

.food_search_black {
    background-color: transparent !important;
    color: #222;
}

.food_search_black::placeholder {
    color: #222;

}

.drawer-fixed {
    position: fixed;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    padding: 12px 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.shop_icon_img {
    width: 40px;
    height: auto;
}

.ecom-brand-img {
    width: 100%;
    height: 50px;
    object-fit: contain;
    padding: 5px;
    /* border-radius: 50%;
    background-color: #fff; */
}

.ecom-brand {
    border: 2px solid var(--light-color2);
    border-radius: 50%;
    background-color: #f3f8fd;
    width: 80px;
    height: 80px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
}

.right-10 {
    right: 10px;
}

.shop-menu {
    /* position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
    top: 0px; */
    width: 100%;
    /* padding: 10px 2px 45px;
    z-index: 1; */
}

.shop-icon {
    width: auto;
    height: 20px;
    object-fit: contain;
    padding: 0px 3px;
}



.sticky-190px {
    position: sticky;
    position: -webkit-sticky;
    top: 190px;
    z-index: 1;
}

.sticky-165px {
    position: sticky;
    position: -webkit-sticky;
    top: 165px;
    z-index: 1;
}

.sticky-150px {
    position: sticky;
    position: -webkit-sticky;
    top: 150px;
    z-index: 1;
}

.star-rating {
    position: absolute;
    right: 5px;
    z-index: 1;
    width: 40px;
    text-align: center;
    background-color: white;
    top: 5px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px;
}

.img_over_see {
    background: black;
    cursor: pointer;
    border-radius: 4px;
}

.img_over_see img {
    object-fit: cover;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 4px;
}

.img_over_see video {
    object-fit: cover;
    opacity: 0.5;
    cursor: pointer;
    border-radius: 4px;
}


.img_over_container {
    position: relative;
    text-align: center;
    cursor: pointer;
}

.img_over_container .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.yarl__navigation_prev,
.yarl__navigation_next {
    padding: var(--yarl__navigation_button_padding, 24px 8px) !important;
}

.yarl__slide {
    padding: 0px !important;
}

.review-user {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    /* border: 2px solid #fff; */
}

.yarl__toolbar {
    padding: var(--yarl__toolbar_padding, 8px, 0px) !important;
}

.descrip-text {
    width: 100%;
    max-height: 500px;
    overflow: visible;
    position: absolute;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    padding: 15px 10px;
}




.yarl__video_container video {
    max-height: 70% !important;
}

.gallery-tab {
    padding: 5px 15px;
    border: 1px solid #888;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.gallery-tab-active {
    padding: 5px 15px;
    border: 1px solid #007dae;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #f5fcff;
}

.ReactGridGallery_tile,
.ReactGridGallery_tile-viewport {
    border-radius: 6px;
}

.ReactGridGallery_tile-viewport img {
    object-fit: cover;
}


.other-seller-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
}

.color-prime1 {
    color: #007dae;
}

.over-img {
    position: absolute;
    top: 2px;
    right: 6px;
    background-color: #fff;
    padding: 0px 4px;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.MuiDialog-container {
    height: 100%;
    outline: 0;
    width: 100%;
}

.delivery-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 6px;
}

/* call #019119
bg #cbe8c2 */

.callButton {
    background-color: #ecedef;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #e4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    cursor: pointer;
}

.status-text {
    background-color: #efffef;
    padding: 6px 3px;
    text-align: center;
    border-radius: 5px;
}

.status-text p {
    letter-spacing: 0.4px;
}

.bg-light-custom-green {
    background-color: #efffef;
}

.bg-custom_green {
    background-color: #2e982e;
}

.resta-img {
    width: 30px;
    height: 30px;
}

.review-checkbox {
    margin-bottom: 16px;
    margin-right: 4px;
}


.review-checkbox input {
    position: absolute;
    display: none;
    color: #333;
}

.review-checkbox input:checked+span {
    color: #007dae;
    background-color: #fff;
    padding: 6px 14px !important;
    border: 1px solid #007dae;
    font-weight: 600;
}

.review-checkbox span {
    color: #444;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 6px 14px !important;
    border-radius: 6px;
    font-size: 12px;
    margin-right: 5px;
}

.visible-hidden-checkbox {
    visibility: hidden;
    height: 0;
}


.css-b62m3t-container {
    z-index: 0;
    left: 6px !important;
    width: 100% !important;
    border-radius: 20px !important;
    position: relative !important;
}

.css-13cymwt-control {
    border-radius: 20px !important;
}


.sticky-top-map {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 8;
    background-color: #fff;
}

.active-border-line {
    border: 2px solid #64b0ce;
    border-radius: 6px;
    width: 100%;
}

.border-line {
    border: 2px solid #eee;
    width: 100%;
    border-radius: 6px;
}

.custom-swipe-shadow {
    margin-bottom: 42px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.progress {
    width: 100%;
}

.progress {
    background: #E9E9E9;
    /* border-radius: 6px; */
    /* -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08); */
}

.progress-bar {
    height: 10px;
    border-radius: 4px;
    /* background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05)); */
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color;
    -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}


.b_seller {
    padding: 0px 5px;
    background-color: #fff6e6;
    border-radius: 5px;
}

.popover-body {
    padding: 0.5rem 0.7rem;
    color: #777;
    letter-spacing: 0.1px;
    font-family: "Roboto", Arial, sans-serif;
    font-size: 13px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.popover {
    border: 1px solid #ddd !important;
}

.border-yAxis-dashed {
    border-top: 1px dashed #bbb;
    border-bottom: 1px dashed #bbb;
}



.pay_success img {
    width: 50px;
    height: 50px;
    /* border-radius: 50%; */
    /* animation: pulse 10s linear infinite ; */
}

.price_details {
    width: 100%;
    height: auto;
    border: 1px solid #bbb;
    border-radius: 10px;
}

.detail-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
}



.main-container {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.check-container {
    width: 6.25rem;
    height: 7.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.check-background {
    width: 100%;
    height: calc(100% - 1.25rem);
    background: linear-gradient(to bottom right, #66ff6f, #00ac0b);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
        0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    transform: scale(0.84);
    border-radius: 50%;
    animation: animateContainer 0.75s ease-out forwards 0.75s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.check-background svg {
    width: 65%;
    transform: translateY(0.25rem);
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.check-shadow {
    bottom: calc(-15% - 5px);
    left: 0;
    border-radius: 50%;
    background: radial-gradient(closest-side, rgb(0, 177, 11), transparent);
    animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
    0% {
        opacity: 0;
        transform: scale(0);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    25% {
        opacity: 1;
        transform: scale(0.9);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    43.75% {
        transform: scale(1.15);
        box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    62.5% {
        transform: scale(1);
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
    }

    81.25% {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }

    100% {
        opacity: 1;
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
}

@keyframes animateCheck {
    from {
        stroke-dashoffset: 80;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes animateShadow {
    0% {
        opacity: 0;
        width: 100%;
        height: 15%;
    }

    25% {
        opacity: 0.25;
    }

    43.75% {
        width: 40%;
        height: 7%;
        opacity: 0.35;
    }

    100% {
        width: 85%;
        height: 15%;
        opacity: 0.25;
    }
}

.fav-img-list {
    width: 100%;
    height: 66px;
    object-fit: cover;
    border-radius: 6px;
}

.vendor-list {
    width: 100%;
    height: 66px;
    object-fit: contain;
    border-radius: 6px;
}

.border-lc-active {
    border: 1px solid #007dae !important;
}

.summary-content .MuiAccordionSummary-content {
    margin: 0 !important;
}

.summary-content .MuiAccordionSummary-root.Mui-expanded {
    border-bottom: none !important;
}

.wish-img-list {
    width: 100%;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
}

#map-test {
    height: 100vh;
    position: relative;
}

#location-pickup-map-100 {
    height: calc(100% - 49px) !important;
    position: relative;
}

#location-pickup-map-with-marker-100 {
    height: calc(100% - 49px) !important;
    position: relative;
}

#location-pickup-map {
    height: 80vh !important;
    position: relative;
}

/* #map {
        position: relative;
    } */

.pac-container {
    z-index: 1300 !important;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

/* .pac-container {
    background-color: #151515;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

.pac-item,
.pac-item-query {
    padding: 15px;
    /* color: #f7f7f7; */
}

.pac-item:hover {
    /* background: #6666; */
}

#location-pickup-map-100::after {
    width: 22px;
    height: 35px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background: url('https://cdn3.1superapp.com/superapp/markerpin.png');
    background-size: 22px 35px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

#location-pickup-map-with-marker-100 ::after {
    width: 22px;
    height: 35px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    /* background: url('https://cdn3.1superapp.com/superapp/markerpin.png'); */
    background-size: 22px 35px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

#location-pickup-map:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background: url('https://cdn3.1superapp.com/superapp/markerpin.png');
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

#map-test:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png');
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

.map-collection {
    width: 60px;
    height: 55px;
    object-fit: cover;
    border-radius: 10px;
}

.map-bottom-div {
    position: absolute;
    bottom: 45px;
    left: 20px;
    right: 20px;
}

.custom-map-bottom-div {
    position: absolute;
    bottom: 45px;
    left: 0px;
    right: 0px;
}

.map-top-div {
    position: absolute;
    top: 50px;
    left: 16px;
    right: 16px;
    z-index: 1;
}

.map-bottom-div .div,
.custom-map-bottom-div .div {
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
}

#map-live-track {
    /* height: 90vh; */
    height: 83vh;
    /* height : calc(var(--window-inner-height) - 1px); */
    position: relative;
}

#map-live-track:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}


#map-loction-pick {
    /* height: 100vh; */
    height: 70vh;
    position: relative;
}

#map-loction-pick:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

.w-fit {
    width: fit-content !important;
}

.pac-target-input {
    animation-name: endBrowserAutofill;
    margin-top: 10px;
}

.map-search-div {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    margin-left: 10px;
    margin-right: 10px;
    z-index: 1500;
    background: #fff;
    box-shadow: 0 1px 4px -1px rgb(0, 0, 0, 0.3);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.map-search-bar {
    position: absolute;
    left: 0;
    right: 0;
    /* margin-left: 10px;
    margin-right: 10px; */
    z-index: 1500;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.switch input {
    outline: none !important;
    box-shadow: none !important;
}


.pay-control {
    background-color: #fff;
    font-size: 12px;
    color: #555;
    border: none !important;
    /* display: flex; */
}


.pay-control:focus {
    border: none !important;
}

.pay-control .css-1u9des2-indicatorSeparator {
    display: none;
}

.pay-control .css-1xc3v61-indicatorContainer {
    padding: 0 !important;
}

.pay-control .css-tj5bde-Svg {
    width: 13px;
}


/* .pay-control .css-1hb7zxy-IndicatorsContainer{
    display: none;
} */

.pay-control .css-1dimb5e-singleValue {
    font-size: 12px;
}

.pay-control .css-13cymwt-control {
    min-height: 30px;
    border: none !important;
}

.pay-control .css-1fdsijx-ValueContainer {
    padding: none !important;
}

.pay-control::after {
    /* content: "⌄"; */
}

.embla {
    overflow: hidden;
}

.embla__container {
    display: flex;
}

.embla__slide {
    flex: 0 0 100%;
    min-width: 0;
}


.embla__slide {
    flex: 0 0 100%;
    /* Default slide covers 100% of the viewport */
}

@media (min-width: 768px) {
    .embla__slide {
        flex: 0 0 50%;
        /* Breakpoint SM slide covers 50% of the viewport */
    }

    .delivery_status_vertical time {
        width: 15% !important;
    }

    .delivery_status_vertical p {
        width: 85% !important;
    }

    .timeline-icon {
        position: absolute;
        z-index: 14;
        left: 14.4% !important;
        padding-top: 5px;
    }
}


.shipment-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover !important;
}

input[type="text"] {
    font-size: 13px !important;
}

select {
    font-size: 13px !important;
}

input[type="checkbox"] {
    color: var(--prime1);
}

.delivery_status_vertical li {
    display: flex;
    color: #999;
}

.delivery_status_vertical time {
    position: relative;
    padding: 0 0.8em 1.5em 0em;
    font-size: 12px;
    width: 25%;
    color: #444;
    letter-spacing: 0.5px;
}

.delivery_status_vertical time::after {
    content: "";
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #18e807;
    border: 2px #18e807 solid;
    width: 10px;
    height: 10px;
}

.delivery_status_vertical .timeline {
    padding: 0 0em 1.5em 0.8em;
    position: relative;
    /* font-size: 12px; */
    width: 75%;
    /* color: #444; */
    letter-spacing: 0.4px;
}

.delivery_status_vertical .timeline::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #13dd02 solid;
}

.delivery_status_vertical strong {
    /* display: block; */
    font-weight: bold;
    color: #555;
}

.delivery_status_vertical {
    margin: 1em 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.delivery_status_vertical,
.delivery_status_vertical *::before,
.delivery_status_vertical *::after {
    box-sizing: border-box;
    font-family: arial;
}

.timeline-icon {
    position: absolute;
    z-index: 14;
    left: 23%;
    padding-top: 5px;
}

.line-icon {
    border: 2px solid #333;
    border-radius: 50%;
    background-color: #fff;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.line-icon::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 14px;
    height: 100%;
    border-left: 1px #13dd02 solid;
    top: 0px;
}

.line-icon {
    position: relative;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
}

/* footer bottom sheet start*/

.main-hr-drawer {
    margin: 0 auto;
    /* height: 6px !important; */
    height: 3px !important;
    background: #c0c0c0;
    /* width: 36px; */
    width: 50px;
    border-radius: 4px;
}

.dashboard-app-btns {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-app-btns .dashboard-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    text-align: center;
    padding: 5px 2px;
    text-decoration: none;
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-app-btns .dashboard-app-btn-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 3px;
}

.dashboard-app-btns .dashboard-app-btn.food-btn img {
    max-height: 57px;
}

.dashboard-app-btns .dashboard-app-btn img {
    max-height: 40px;
}

.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: none;
    /* font-family: "Roboto-Medium"; */
    color: #222 !important;
}

.beta-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #007bff;
    color: #fff;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    border-radius: 5px;
}


.btn-menu-back {
    border-radius: 50%;
    height: 35px;
    /* width: 35px; */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;
    color: #343a40 !important;
    font-size: 12px;
}

.superapp_menu_tabs {
    border-top: 1px solid #f1f1f1;
}

.footer-bottomsheet {
    /* z-index:999 !important; */
    /* position: absolute !important; */
    padding: 5px !important;
}

.footer-back {
    height: 40px !important;
    padding: 5px 7px !important;
    position: fixed;
    bottom: 45px;
    left: 0;
    width: 100%;
    z-index: 1332;
}

/* footer bottom sheet end*/


ul.timeline>li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.z-index4 {
    z-index: 4 !important;
}

.wt-space {
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.border-left-white {
    border-left: 2px solid #fff;
}

.border-right-white {
    border-right: 2px solid #fff;
}

.res-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
}

.res-img-gray {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    filter: grayscale(100%);
}


.custom-input input[type="text"],
.custom-input.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0 !important;
    height: 30px !important;
    font-size: 12px !important;
}

.custom-input input[type="text"]:focus,
.custom-input.form-control:focus {
    -webkit-box-shadow: none;
    font-size: 12px !important;
    box-shadow: none;
    height: 30px !important;
}

.textarea-card {
    box-shadow: 1px 1px 8px 0px #ccc;
    border: none !important;
    font-size: 12px !important;

}

.textarea-card::placeholder {
    font-size: 12px !important;
}

.w-27px {
    width: 27px;
}


.w-30px {
    width: 30px;
}

.map-header {
    position: fixed;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    top: 50px;
}

.address-map-header {
    position: fixed;
    z-index: 9;
    width: 100%;
    background-color: #fff;
    top: 0px;
    justify-content: flex-start;
}


.text-darkorange {
    color: darkorange !important;
}

.border-radius-10 {
    border-radius: 10px !important;
}

fieldset .input-group {
    align-items: center !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-text {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px 6px !important;
    border: none !important;
    background-color: #fff !important;
    /* background-color: transparent !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
    cursor: pointer;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.input-group-text-last {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px 6px !important;
    border: none !important;
    background-color: #fff !important;
    /* background-color: transparent !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
    cursor: pointer;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.input-group .form-control {
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.input-group .form-control1 {
    border: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

html.is-locked,
html.is-locked body {
    /* want to fix the height to the window height */
    height: calc(var(--window-inner-height) - 1px);

    /* want to block all overflowing content */
    overflow: hidden;
    user-select: none;
}

.menu-icon {
    width: 16px !important;
    height: 16px;
}

.footer_icon {
    width: 19px !important;
    margin: 5px;
}

.address_container {
    width: 600px !important;
}

.status-panel {
    margin-bottom: 200px !important;
}

.footer_visible {
    display: flex !important;

    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
}

.footer_hidden {
    display: none !important;
    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
}

.box-border {
    border: 1px solid #aaa;
    border-radius: 6px;
}

.filter-div .react-checkbox-tree li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.filter-div .react-checkbox-tree li label {
    width: 100% !important;
    padding: 8px 2px;
    font-family: "Celias" !important;
    color: #333;
}

.filter-div .react-checkbox-tree li label span.rtc-title {
    padding: 0 6px !important;
}

.filter-div .react-checkbox-tree li .rct-collapse {
    display: none !important;
}

.filter-div .react-checkbox-tree li .rtc-title {}

/*.rct-checkbox * {
    color: white;
    border: 1px solid #777;
    border-radius: 3px;
}

.fa-check-square:before {
    content: "\f14a";
    content: "\2713";
    width: 100%;
    display: flex;
    color: green;
    height: 100%;
    align-items: center;
    justify-content: center;
} */

.smallfont {
    font-size: 8px !important;
    font-family: "Celias-Light" !important;
    color: #777 !important;
    letter-spacing: 0.2px;
}

.fw-400 {
    font-weight: 400 !important;
}


.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.prod-quick-view .ReactSwipeableBottomSheet--open {
    background-color: transparent !important;
}

.close-circle {
    width: 40px;
    height: 40px;
    background-color: #373737;
    display: flex;
    justify-content: center;
    align-items: center;
    /* scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s; */
    /* scroll-behavior: smooth;
    transition: 0.5s; */
    border-radius: 50%;
}

.prod-quick-img {
    width: 100%;
    height: 300px;
    /* object-fit: contain; */
}


.prod-quick-view>div>div {
    backdrop-filter: blur(60px);
    /* background-image: linear-gradient(#919191, #ffffff 100%, #ffffff 3%, #efeded) !important; */
    opacity: 1 !important;
    background-color: #565656 !important;
}

.l-none {
    left: none !important;
}

.btn-save-address {
    /* left: 0; */
    bottom: 45px;
    position: fixed !important;
    border: none;
    z-index: 1;
    width: 100%;
    border-radius: 0 !important;
    color: #000;
}


div.MuiPopover-paper {
    transition: none !important;
    top: auto !important;
    width: calc(100% - 32px) !important;
    left: 16px !important;
}

.footer_fixed {
    display: flex !important;

    scroll-behavior: smooth;
    -webkit-transition: 0.95s;
    -moz-transition: 0.95s;
    transition: 0.95s;
}

.all-select input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-color: #000;
}

.all-select .form-check-input:checked {
    background-color: #00ac0b;
    border-color: #00ac0b !important;
}

.filter-div input[type="text"] {
    /* font: 15px/24px "Lato", Arial, sans-serif; */
    color: #333;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
}

.filter-div input[type="text"]:focus {
    outline: none;
}

.search-effect {
    border: 0;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
}

.search-effect~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4caf50;
    transition: 0.4s;
}

.search-effect:focus~.focus-border {
    width: 100%;
    transition: 0.4s;
}

.search-close {
    padding: 6px 0;
    border-bottom: 1px solid #ccc;

}

.filter-div input[type="text"].search-effect::placeholder {
    font-family: "Celias" !important;
    font-size: 12px !important;
    letter-spacing: 0.2px !important;
}

.filter-price .MuiSlider-root {
    color: #02850b !important;
    width: 85% !important;
    display: flex !important;
    align-items: center !important;
}

.filter-price .MuiSlider-track {
    border-color: #00ac0b !important;
    border: 1px solid #6ba7f4;
}

.filter-price .MuiSlider-thumb {
    width: 15px;
    height: 15px;
    margin-top: -2px;
}

.filter-price .MuiSlider-valueLabel>span {
    width: 40px !important;
    height: 40px !important;
}


.filter-common label input[type="checkbox"]:checked::before {
    content: '✓' !important;
    font-size: 14px;
    outline: none;
    transition: all .2s;
    color: #00ac0b !important;
}

.filter-common label input[type="checkbox"]:not(:checked)::after

/* .filter-common label input[type="checkbox"]::after  */
    {
    content: '✓' !important;
    font-size: 14px;
    color: #cccccc;
    transition: all .2s;
    outline: none;
}

.filter-common label input[type="checkbox"] {
    width: 0;
    height: 0;
    position: relative;
    top: -15px;
    padding: 0 5px;
}

.filter-common label {
    padding: 8px;
    font-size: 12px;
}

.filter-common label span {
    padding: 0 5px;
}


.text-light-gray {
    color: #606060;

}

.bottom-custom-fixed {
    left: 0;
    /* bottom: 50px !important; */
    bottom: 45px !important;
    position: fixed !important;
    width: 100%;
    background-color: #fff;
    /* justify-content: center; */
    /* display: flex; */
    align-items: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    /* height: 50px !important; */
    border: none;
    z-index: 1;
}

.react-swipeable-view-container::-webkit-scrollbar {
    display: none;
}

.no-shop-img .from-group input::placeholder {
    color: #555 !important;
}



.variant-sheet .ReactSwipeableBottomSheet--open {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.o_img {
    height: 56px;
    object-fit: cover;
}

.customize-sheet .form-check-input:checked {
    background-color: #00ac0b !important;
    border-color: #00ac0b !important;
}

.order_summary .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}

.cart-variant-sheet .form-check-input:checked {
    /* background-color: #007dae !important;
    border-color: #007dae !important; */
    background-color: #00ac0b !important;
    border-color: #00ac0b !important;
}

.f-13px {
    font-size: 13px;
    color: #212529;
}

.l-0 {
    left: 0px !important;
}

.input-d-none {
    display: none;
}

.light-green {
    background-color: #dbf4e5 !important;
}

.h-26px {
    height: 26px !important;
}

.h-80px {
    height: 80px;
}

.fs-9px {
    font-size: 9px;
}

.fs-10px {
    font-size: 10px !important;
}

.fs-11px {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13px {
    font-size: 13px !important;
}

.fs-14px {
    font-size: 14px !important;
}

.fs-15px {
    font-size: 15px !important;
}

.fs-16px {
    font-size: 16px !important;
}

.fs-7px {
    font-size: 7px;
}


.fs-9px {
    font-size: 9px !important;
}

.fs-8px {
    font-size: 8px !important;
}

.fs-19px {
    font-size: 19px !important;
}

/* .feedback-star .MuiRating-label {
    font-size: 66px !important;
} */

.feedback-star .MuiSvgIcon-root {
    width: 2.7em !important;
    height: 1.4em !important;
}

.showRating {
    background-color: #fff1da;
    width: fit-content;
    padding: 2px 6px !important;
}

.showRating p {
    padding-right: 2px;
    font-weight: 600 !important;
}

/* .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    z-index: 1 !important;
} */

.wishlist-accordion .MuiAccordionSummary-root {
    min-height: fit-content !important;
    padding: 0px 5px !important;
    align-items: center;
    display: flex;
}


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}


/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    width: max-content;
}

/* Input */
.pure-material-switch>input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 10px;
    top: -2px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /* background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38); */
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch>span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Track */
.pure-material-switch>span::before {
    content: "";
    float: left;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch>span::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch>input:checked {
    left: 21px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-switch>input:checked+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch>input:checked+span::after {
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transform: translateX(16px);
}

/* Hover, Focus */
.pure-material-switch:hover>input {
    opacity: 0.04;
}

.pure-material-switch>input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover>input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch>input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch>input:active+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch>input:checked:active+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Disabled */
.pure-material-switch>input:disabled {
    opacity: 0;
}

.pure-material-switch>input:disabled+span {
    color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch>input:disabled+span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch>input:checked:disabled+span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.filter-constrast-10 {
    filter: contrast(10);
}


.cart-deli-img {
    width: 25px;
    height: 25px;
}


.add-address-form .form-control,
.add-address-form textarea,
.add-address-form .css-13cymwt-control {
    border-radius: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: none;
    border-bottom: 1px solid #dcdcdc;

}

.tied-icon {
    width: 10px;
    height: 10px;
}

.form-floating>.form-control,
.form-floating>.form-select {
    height: calc(1.8rem + 2px) !important;
    line-height: 1.25;
}

.makeStyles-iconContainer-6 button {
    margin: 5px 20px 5px 0px !important;
}

.box-shadow-none {
    box-shadow: none !important;
}

.cod-2-pay-img {
    width: 30px;
    height: 30px;
}

@keyframes ldio-2w3dkfxa0wg {
    0% {
        transform: translate(6px, 40px) scale(0);
    }

    25% {
        transform: translate(6px, 40px) scale(0);
    }

    50% {
        transform: translate(6px, 40px) scale(1);
    }

    75% {
        transform: translate(40px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(1);
    }
}

@keyframes ldio-2w3dkfxa0wg-r {
    0% {
        transform: translate(74px, 40px) scale(1);
    }

    100% {
        transform: translate(74px, 40px) scale(0);
    }
}

@keyframes ldio-2w3dkfxa0wg-c {
    0% {
        background: #007dae
    }

    25% {
        background: #007dae
    }

    50% {
        background: #007dae
    }

    75% {
        background: #007dae
    }

    100% {
        background: #007dae
    }
}

.ldio-2w3dkfxa0wg div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(40px, 40px) scale(1);
    background: #007dae;
    animation: ldio-2w3dkfxa0wg 2.6315789473684212s infinite cubic-bezier(0, 0.5, 0.5, 1);
}

.ldio-2w3dkfxa0wg div:nth-child(1) {
    background: #007dae;
    transform: translate(74px, 40px) scale(1);
    animation: ldio-2w3dkfxa0wg-r 0.6578947368421053s infinite cubic-bezier(0, 0.5, 0.5, 1), ldio-2w3dkfxa0wg-c 2.6315789473684212s infinite step-start;
}

.ldio-2w3dkfxa0wg div:nth-child(2) {
    animation-delay: -0.6578947368421053s;
    background: #007dae;
}

.ldio-2w3dkfxa0wg div:nth-child(3) {
    animation-delay: -1.3157894736842106s;
    background: #007dae;
}

.ldio-2w3dkfxa0wg div:nth-child(4) {
    animation-delay: -1.9736842105263157s;
    background: #007dae;
}

.ldio-2w3dkfxa0wg div:nth-child(5) {
    animation-delay: -2.6315789473684212s;
    background: #007dae;
}

.loadingio-spinner-ellipsis-8xzvrehcro6 {
    width: 40px;
    height: 40px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-2w3dkfxa0wg {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.4);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-2w3dkfxa0wg div {
    box-sizing: content-box;
}

.new-img-fluid {
    width: 45px !important;
    margin-bottom: 5px;
}


/* Radio & Checkbox new style */

.md-checkbox {
    position: relative;
    margin: 1em 0;
    text-align: left;
}

.md-checkbox.md-checkbox-inline {
    display: inline-block;
}

.md-checkbox label {
    cursor: pointer;
    display: inline;
    line-height: 1.25em;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
}

.md-checkbox label:not(:empty) {
    padding-left: 0.75em;
}

.md-checkbox label:before,
.md-checkbox label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

.md-checkbox label:before {
    width: 1.25em;
    height: 1.25em;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 0.125em;
    cursor: pointer;
    transition: background-color .3s;
}

.md-checkbox input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
}

.md-checkbox input[type="checkbox"]:checked+label:before {
    background-color: #007bff;
    border: none;
}

.md-checkbox input[type="checkbox"]:checked+label:after {
    transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
    width: 0.75em;
    height: 0.375em;
    border: 0.125em solid #fff;
    border-top-style: none;
    border-right-style: none;
}

.md-checkbox input[type="checkbox"]:disabled+label:before {
    border-color: rgba(0, 0, 0, 0.26);
}

.md-checkbox input[type="checkbox"]:disabled:checked+label:before {
    background: rgba(0, 0, 0, 0.26);
}



@keyframes rippleRadio {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }

    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
    }
}

.md-radio.md-radio-inline {
    display: inline-block;
}

.md-radio input[type="radio"] {
    display: none;
}

.md-radio input[type="radio"]:checked+label:before {
    border-color: #007bff;
    animation: rippleRadio 0.2s linear forwards;
}

.md-radio input[type="radio"]:checked+label:after {
    transform: scale(1);
}

.md-radio label {
    display: inline-block;
    min-height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
}

.md-radio label:before,
.md-radio label:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all .3s ease;
    transition-property: transform, border-color;
}

.md-radio label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(218, 218, 218, 0.8);
}

.md-radio label:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: #007bff;
}

.map-layer-div {
    position: absolute;
    /* z-index: 1000000; */
    bottom: 100px;
    right: 20px;
}

.pick-map-container {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
    height: 200px !important;
}

.pick-map-container>img {
    max-height: 200px;
    border-radius: 10px;
}

.pick-map-content {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
}

.pick-map-content:hover {
    background-color: rgb(255 255 255 / 15%);
}

.text-shadow-1 {
    text-shadow: 0px 2px 10px #9e9e9e;
}

.changepick-map-container {
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-bottom: -5px;
}

.changepick-map-content {
    padding: 2px 8px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    font-weight: 600;
    font-size: 10px;
    background-color: #dcdcdcb5;
    border-bottom-left-radius: 10px;
    font-family: "Celias" !important;
}

.changepick-map-footer {
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 6px 6px 3px 6px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tutorial-tooltip-overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #00000080;
    z-index: 1040;
}

.tutorial-tooltip {
    background-color: transparent;
    text-align: center;
    border-radius: 6px;
    padding: 0;
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.br-20 {
    border-radius: 20px !important;
}

.br-15 {
    border-radius: 15px !important;
}

.textarea-skelton-card {
    height: 60px;
    border-radius: 10px;
}

.mb-2_8rem {
    margin-bottom: 2.8rem !important;
}

.pb-130px {
    padding-bottom: 130px;
}

.drawer-fixed-1icon {
    position: fixed;
    background-color: #fff;
    z-index: 10;
    width: 100%;
    /* padding: 12px 0px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.icon-drawer {
    background-color: #fff;
}

.right-0 {
    right: 0px !important;
}

.bg-light-orange {
    background-color: #fdf4ea !important;
}

/* .error-msg-triangle {
    animation: pulse 2s infinite;
} */

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.error-msg-triangle::after {
    position: absolute;
    left: 7px;
    /* margin-left: -20px; */
    bottom: -9px;
    width: 5px;
    height: 5px;
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #ffe4c4;

}


/** Cupertino Panes Css */
.cupertino-pane-wrapper.swipeable-drawer .pane {
    cursor: default !important;
    padding: 0;
}

.swipeable-drawer-content {
    height: 100%;
}

.swipeable-drawer-content.rounded-corner {
    border-radius: 10px;
}

.cupertino-pane-wrapper .bar {
    height: 3px;
    width: 50px;
    background: #ccc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.swipeable-drawer-container-footer .cupertino-pane-wrapper {
    z-index: 1331;
}

.btn-1drawer-content {
    padding-top: 6px;
}

.btn-1drawer-bar {
    margin-bottom: 6px;
}

.bottom-45 {
    bottom: 45px !important;
}

.card_accordian_li {
    list-style-type: none !important;
}



.btn:after {
    content: "";
    background: #e1e1e1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.btn:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

a img {
    /* border: 0; */
    border-color: transparent;
}

.slick-track {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
}

.slick-list {
    width: 100%;
}

.category-double {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px !important;
}

.resta_shop_img_gray {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    filter: grayscale(100%);
}

.badge-2 {
    background: #c1e9ff;
    border-radius: 5px;
    color: #909191;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 5px 1px;
    text-transform: uppercase;
    margin-left: 5px;
}

.unavailable-detail {
    /* border-top: 1px solid #ebf2ff; */
    /* padding: 2px 0px; */
    margin-top: 1px;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: #777777;
    font-family: "Celias";
}

.unavailable-detail .v-small {
    padding-top: 5px;
}

.unv-closed-img {
    position: relative;
}

.unv-closed {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.4);
    width: 100%;
    color: #ffffff;
    line-height: 15px;
    padding: 3px 5px;
    text-transform: uppercase;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.unv-closed-t1 {
    font-size: 13px;
    font-weight: 600;
}

.unv-closed-t2 {
    font-size: 11px;
}

.box-1 {
    border: 1px solid #f7f7f7;
    border-radius: 7px;
    box-shadow: 1px 2px 5px #ededed;
}

.box-2 {
    border: 1px solid #efefef;
    border-radius: 7px;
    box-shadow: 3px 2px 8px #eaeaea;
}

.box-3 {
    border-radius: 7px;
    box-shadow: 0 0 4px #e6e6e6;
}

.font-noto-sans {
    font-family: "Noto Sans", sans-serif !important;
}

.font-celias-medium {
    font-family: "Celias-Medium";
}

.home-section-name {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #646464;
}

.home-section-box {
    position: relative;
    overflow: hidden;
}

.home-section-img-thumb {
    width: 70px;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px
}

/* .badge-soon{
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 500;
    font-size: 9px;
    background: #a0bb23;
    color: #ffffff;
    padding: 2px 6px 1px 6px;
    border-radius: 4px;
    box-shadow: 0px 2px 3px #f0f0f0;
} */
.badge-soon {
    position: absolute;
    top: 0px;
    right: 4px;
    font-weight: 500;
    font-size: 7px;
    background: #a0bb23;
    color: #ffffff;
    padding: 1px 3px 1px 3px;
    border-radius: 4px;
    box-shadow: 0px 2px 3px #f0f0f0;
    /* transform: rotate(45deg); */
}





/* Grocery New css */
.custom-orange {
    background-color: #fff3e9;
}

.custom-blue {
    background-color: #e2f0ff;
}

.pop-font {
    font-family: "Poppins" !important;
}

.pop-font-light {
    font-family: "Poppins-light" !important;
}

.pop-bold {
    font-family: "Poppins-bold" !important;
}

.pop-semi {
    font-family: "Poppins-semi-bold" !important;
}

.pop-regular {
    font-family: "Poppins-regular" !important;
}

.cate-circle {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #efefef;
    padding: 5px;
}

.cate-circle img,
.active-cate-img img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.cate-block {
    position: relative;
}

.active-cate {
    border-left: 4px solid #00970c;
    border-radius: 20px;
    padding-left: 2px;
    position: absolute;
    top: 0;
    right: -2px;
    height: 100%;
}

.active-cate-img {
    width: 50px;
    height: 50px;
    background-color: #00970c26;
    border-radius: 50%;
    /* padding: 5px; */
}

.cate-scroll {
    -ms-overflow-style: none !important;
    /* for Internet Explorer, Edge */
    scrollbar-width: none !important;
    /* for Firefox */
    overflow-y: scroll;
    height: 90vh;
    padding-bottom: 45px;
    position: fixed;
    width: 72px;
    overflow-x: hidden;
    padding-bottom: 100px !important;
}

.cate-scroll::-webkit-scrollbar {
    display: none;
}

.cate-products-show {
    width: calc(100% - 72px);
    margin-left: 72px;
    padding-right: 1px;
}

.prods {
    margin-bottom: 6px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
}

.prods:nth-child(odd) {
    padding-left: 6px;
    padding-right: 3px;
}

.prods:nth-child(even) {
    padding-left: 3px;
    padding-right: 6px;
}

@media(min-width: 786px) {
    .prods {
        margin-bottom: 12px;
    }

    .prods,
    .prods:nth-child(odd),
    .prods:nth-child(even) {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fixed-header-50px {
        width: 35% !important;
    }
}

.prod-card {
    border-radius: 7px;
    padding-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 9px;
    background-color: #fff;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* width: 190px; */
}

.prod-card .alice-carousel__dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding-left: 7px;
    padding-right: 7px;
}

.prod-card .alice-carousel__dots-item:not(.__custom) {
    width: 4px;
    height: 4px;
}

.prod-card .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 4px !important;
}

.prod-card .today .alice-carousel__dots-item:not(.__custom):hover,
.prod-card .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #313131 !important;
}

.border-lightgray {
    border: 1px solid rgb(234, 234, 234);
}

.prod-image {
    width: 100%;
    height: 140px;
    object-fit: contain;
}

.prod-image-gray {
    width: 100%;
    height: 140px;
    object-fit: contain;
    background-color: #fff3f3 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.prod-add-btn {
    border: 2px solid #00970c;
    border-radius: 6px;
    color: #00970c;
    padding: 6px;
    font-size: 12px;
    /* font-weight: 600; */
    letter-spacing: 0.4px;
    margin-bottom: 0 !important;
    text-align: center;
    font-family: "Poppins" !important;
    width: 100%;
}

.prod-add-btn p,
.prod-add-btn p b {
    color: #00970c !important;
}

.center-text-in-border {
    /* width: 70px; */
    border: none !important;
    margin-top: 0px !important;
}

.center-text-in-border legend {
    font-size: 7px;
    color: #777;
    top: 0px !important;
    right: 0 !important;
    left: 0 !important;
}

.strike {
    text-decoration: line-through;
}

.round-border {
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.h-90vh {
    height: 90vh;
}

.prod-quick-image {
    width: 100%;
    height: 300px;
    /* object-fit: cover; */
}

.animate-smooth-scroll {

    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.h-140px {
    height: 140px;
}

.h-150px {
    height: 150px;
}

.bg-green-div {
    /* background-color: #eaffef;
    color: #00c028 !important; */
    background-color: #84ff9e;
    color: #313131 !important;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 9px;
    width: fit-content;
    /* font-family: "Poppins"; */
    letter-spacing: 0.2px;
    font-weight: 600;
}

.fs-11px {
    font-size: 11px;
}

.fs-12px {
    font-size: 12px;
}

.gro-unit-1 {
    border: 1px solid #dedede;
    padding: 8px;
    border-radius: 11px;
}

.gro-unit-1-selected {
    border: 1px solid #005b13;
    padding: 8px;
    border-radius: 11px;
}

.w-80px {
    width: 80px;
}

.pb-8 {
    padding-bottom: 100px;
}

.groc-unit-bottom-div {
    background-color: #005b13;
    /* bottom: 50px; */
    bottom: 45px !important;
    position: fixed;
    padding: 16px 10px;
    margin-top: 10px;
    color: #fff;
    z-index: 3;
    /* align-items: center;
    justify-content: center;
    display: flex; */
    scroll-behavior: smooth;
    transition: 0.5s;
}

.addon_add_btn {
    background-color: #005b13;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
}

.MuiDialog-root {
    z-index: 1360 !important;
}

.min-h-full {
    min-height: calc(100vh - 110px);
}

.p-6 {
    padding: 6px;
}

.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
}

.py-7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.pt-7 {
    padding-top: 7px;
}

.text-green-0 {
    color: #00ac0b !important;
}

.home-slider {
    position: relative;
}

.home-slider .alice-carousel__dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: 7px;
    padding-right: 7px;
}

.home-slider .alice-carousel__dots-item:not(.__custom) {
    width: 4px;
    height: 4px;
}

.home-slider .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 4px !important;
}

.home-slider .today .alice-carousel__dots-item:not(.__custom):hover,
.home-slider .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #313131 !important;
}

.home-slider .alice-carousel__dots-item:not(.__custom).__active {
    width: 10px;
    border-radius: 5px;
}

.home-sections {
    display: flex;
    flex-wrap: wrap;
    font-family: "Noto Sans", sans-serif;
}

.home-card-style {
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    height: 100%;
    padding: 6px 2px 0px;
    border-radius: 6px;
    background-color: #fff;
}

.home-section-card {
    display: block;
    cursor: pointer;
    width: 25%;
    position: relative;
    overflow: hidden;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.home-section-card-title {
    color: #646464;
    font-size: 11px;
    height: 30px;
}

.groc-categories {
    display: flex;
    flex-wrap: wrap;
    font-family: "Poppins";
}

.groc-category {
    display: block;
    cursor: pointer;
    width: 25%;
    position: relative;
    overflow: hidden;
    padding: 10px 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.groc-category-img {
    max-width: 55px;
    min-height: 55px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    /* background: #f0f0f0; */
}

.groc-category-img img {
    max-height: 55px;
}

.groc-category {
    color: #646464;
}

.bottom-cart-btn-icon {
    padding: 6px 10px;
    background: rgb(255, 255, 255, 0.18);
    border-radius: 7px;
}


/* Lazy Loading Css */
.lazy-load:has(.lazy),
.lazy-img,
.lazy-div {
    --bg: #f1f1fa;
    --glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.5), transparent);
    position: relative;
    background-color: var(--bg);
    overflow: hidden;
}

.lazy-load:has(.lazy)::before,
.lazy-img::before,
.lazy-div::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
}

@keyframes skeleton-glare {
    to {
        transform: translateX(100%)
    }
}

.lazy-load:has(.lazy)>*,
.lazy-img>*,
.lazy-div>* {
    visibility: hidden;
}

.vendor-page-header {
    background-color: #ffffff;
}

.vendor-page-header-cover {
    height: 175px;
    background-color: #ebf2ff;
    overflow: hidden;
}

.vendor-page-header-card {
    background-color: #ffffff;
    box-shadow: rgb(0, 0, 0, 0.1) 1px 4px 6px;
    padding-top: 25px;
    margin: 10px 25px;
    position: relative;
    top: -35px;
    border-radius: 9px;
}

.vendor-page-header-card-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.vendor-page-header-card-info {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #ebf2ff;
}

.vendor-page-header-profile-content {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vendor-page-header-profile {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 3px solid #ffffff;
    background-color: #ebf2ff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.vph-name {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 8px;
}

.vph-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7px;
    margin-right: 12px;
}

.vph-timeline-dot {
    background-color: #bababa;
    height: 7px;
    width: 7px;
    border-radius: 100%;
}

.vph-timeline-line {
    background-color: #d0d0d0;
    width: 1px;
    height: 20px;
}

.vph-menu {
    text-transform: uppercase;
    padding-left: 22px;
    padding-right: 20px;
    letter-spacing: 3px;
    font-size: 13px;
    color: #606870;
}

.c-dot {
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background-color: #6c757d;
    margin-bottom: 3px;
    margin-left: 3px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* .c-dot:after{
    content: "";
    height: 2px;
    width: 2px;
    background-color: #000000;
} */

.text-grey-2,
.text-grey-2::placeholder {
    color: #4d5359 !important;
}

.carousel-products-2 .alice-carousel__stage {
    display: flex;
}

.carousel-products-2 .alice-carousel__stage-item {
    flex: 1 0 auto;
    height: auto;
}

.carousel-products-2 .alice-carousel__stage-item .prods {
    height: 100%;
}

.lazy-hw-180 {
    min-height: 180px;
}

.m-addr-list {
    padding: 10px;
}

.m-addr-item {
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
}

.m-addr-check {
    height: 20px;
    width: 20px;
    border: 2px solid #ccc;
    border-radius: 100%;
}

.m-addr-icon {
    height: 25px;
    width: 25px;
    border: 2px solid #ccc;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.m-addr-icon img {
    margin-bottom: 1px;
}

.m-addr-icon-others {
    border: 2px solid #333;
}

.m-addr-icon-home {
    border: 2px solid #333;
}

.m-addr-icon-work {
    border: 2px solid #333;
}

.m-addr-map {
    height: 75px;
    width: 75px;
}

.navfooter img.filled-icon-img,
.navfooter .bottom-tab-active img.outlined-icon-img {
    display: none;
}

.navfooter .bottom-tab-active img.filled-icon-img {
    display: inline;
}

.header-navbar-center-title {
    width: calc(100% - 48px);
    text-align: center;
    padding-right: 48px;
    font-family: "Celias-Medium";
}

.border-bottom-1 {
    border-bottom: 1px solid #eff7ff;
}

/* Sendbird chat css */
.chat-header {
    height: 60px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

.chat-header .profile-image {
    max-width: 30px;
    max-height: 30px;
    border-radius: 100%;
    margin-right: 10px;
}

.sendbird-hide-header .sendbird-conversation .sendbird-chat-header {
    display: none;
}

.sendbird-app-container .sendbird-fileviewer {
    top: 60px;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__avatar,
.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header__left__sender-name {
    display: none !important;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename {
    max-width: 150px;
    font-size: 15px;
}

.sendbird-app-container .sendbird-fileviewer .sendbird-fileviewer__content {
    height: calc(100% - 172px);
}


.map-search-sugg-coords {
    position: absolute;
    top: 50px;
    z-index: 2130;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
}


.w-20px {
    width: 20px;
}

.w-18px {
    width: 18px;
}

.w-11px {
    width: 11px;
    height: 11px;
}

.w-16px {
    width: 16px;
    height: 16px;
}

.w-14px {
    width: 14px;
    height: 14px;
}

.w-28px {
    width: 28px;
    height: 28px;
}

.w-25px {
    width: 25px;
    height: 25px;
}

.vendor-page-header-cover img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.cart-swipesheet-bottom-div {
    background-color: #17791e;
    /* position: sticky; */
    position: fixed;
    /* position: -webkit-sticky; */
    bottom: 0;
    z-index: 1339;
}

.cart-swipesheet-bottom-div-gray {
    background-color: gray;
    /* position: sticky; */
    position: fixed;
    /* position: -webkit-sticky; */
    bottom: 0;
    z-index: 1339;
}


.darkgreen {
    background-color: #17791e;
}

@media (min-width: 960px) {
    .makeStyles-paper-5 {
        scrollbar-width: none !important;
    }

    .website-carousel .alice-carousel__stage-item {
        width: 400px !important;
    }
    

}

@media (max-width : 960px) {
    /* .website-carousel .alice-carousel__stage-item {
        width: 260px !important;
    } */
}

.makeStyles-paper-22,
.makeStyles-paper-18,
.MuiDialog-paperWidthSm {
    scrollbar-width: none;
}

.cart-sidemenu-scroll {
    /* right: 0; */
    -ms-overflow-style: none !important;
    /* for Internet Explorer, Edge */
    scrollbar-width: none !important;
    /* for Firefox */
    overflow-y: scroll;
    height: 90vh;
    padding-bottom: 45px;
    position: fixed;
    width: 20%;
    overflow-x: hidden;
    padding-bottom: 100px !important;
}

.cart-sidemenu-scroll::-webkit-scrollbar {
    display: none;

}

.bg-warnig {
    background-color: gold;
}

.mar-mt-60px {
    margin-top: 60px !important;
}


.foodmenusticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.background-clr-cart {
    background-color: #f6f9ff;
}

.del-person-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.review_textarea {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: none !important;
    scrollbar-width: none;
}

.review_textarea.active,
.review_textarea::after,
.review_textarea:hover {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-color: #000 !important;
}

textarea.review_textarea {
    border-radius: 0px !important;
    /* font-family: "Poppins"; */
    padding-left: 2px;
}



.bottom-0px {
    bottom: 0;
    position: absolute;
    width: 100%;
}

.sec_name {
    color: #424146 !important;
    font-family: "Poppins-bold" !important;
    font-weight: bold !important;
    font-size: 15px;
    letter-spacing: -0.1px;
    text-transform: uppercase !important;
}

.sec_des {
    color: #aeaeae;
    font-family: "Poppins";
    /* font-weight: 400; */
    font-size: 9px;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 6px;
    height: 16px;
}

.sec_img img {
    height: 105px;
    object-fit: contain;
    /* width: 170px; */
    border-bottom-right-radius: 10px;
}

.p-10px {
    padding: 10px;
}

.cart-filter-head {
    font-family: "Poppins-light";
    font-size: 9px;
    color: #666666;
    letter-spacing: 0.3px;
}

.cart-filter-option {
    font-family: "Celias";
    font-size: 10px;
    color: #000000;
    letter-spacing: 0.3px;
    font-weight: 500;
}

.cart_select {
    left: 0 !important;
    border-radius: 0px !important;
}

.cart_select .css-13cymwt-control {
    border: none !important;
    min-height: 30px !important;
}

.css-1fdsijx-ValueContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.css-1hb7zxy-IndicatorsContainer {
    width: 15px !important;
}

.css-1xc3v61-indicatorContainer {
    color: #484848 !important;
    padding: 0px !important;
}

.css-1dimb5e-singleValue {
    color: #0C0C0C !important;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 500;
}

.cart-head {
    font-family: "Poppins-semi-bold" !important;
    font-size: 14px;
    color: #0C0C0C;

}

.cart-sub {
    font-size: 10px;
    font-family: "Poppins-regular";
    color: #666666;
}

.cart-check .form-check-input:checked {
    background-color: #1649AB !important;
    border-color: #1649AB !important;
}

.cart-check .form-check-input {
    width: 0.9em !important;
    height: 0.9em !important;
}

.cart-res-img {
    width: 50px;
    height: 40px;
    border-radius: 5px;
    object-fit: contain;
}

.cart-prod-name {
    color: #414141;
    /* letter-spacing: 0.2px; */
    font-size: 11px;
    font-family: "Poppins";
    padding-top: 2px;
}

.gray-accordion-icon {
    filter: opacity(0.5);
    width: 10px;
    margin-left: 6px;
}

.custom-gray {
    color: #838383;
}

.let_space_03 {
    letter-spacing: 0.3px !important;
}

.let_space_06 {
    letter-spacing: 0.6px !important;
}

.black-text {
    color: #000000;
}

.customize-accrodion {
    color: #838383;
    letter-spacing: 0.3px;
    font-size: 10px;
    font-family: "Celias" !important;
    padding-top: 2px;
    cursor: pointer;
}

.badge-1 {
    text-transform: uppercase;
    font-size: 9px;
    padding: 1px 7px 1px 7px;
    border-radius: 5px;
    font-weight: 500;
    background: #0057ff;
    color: #fff;
    letter-spacing: 0.3px;
}

.badge-1.badge-green {
    background: #0a855f;
}

.badge-1.badge-green-2 {
    background: #8fb55f;
}

.badge-1.badge-red {
    background: #cd422a;
}

.badge-1.badge-red-2 {
    background: #e20f0f;
}

.badge-1.badge-purple {
    background: #8e5bdb;
}

.badge-1.badge-pink {
    background: #d530cb;
}

.badge-1.badge-blue {
    background: #3044d5;
}

.letter-spacing-normal {
    letter-spacing: normal;
}

.p-12 {
    padding: 12px;
}

.order-img {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
}

.order_stauts {
    padding: 8px 6px;
    border-radius: 6px;
    text-align: center;
    width: 100%;
    font-size: 10px;
    margin-top: 4px;
    /* font-family: "pop-font"; */
    text-transform: uppercase;
    color: #000;
}

.stauts-red {
    background-color: #ffcece;
}

.stauts-pink {
    background-color: #ffb5dd;
}

.stauts-green-2 {
    background-color: #8fb55f;
}

.stauts-green-1 {
    background-color: #d5ffdc;
}

.stauts-purple {
    background-color: #e8d8ff;
}

.stauts-red-2 {
    background-color: #e20f0f;
}

.stauts-green {
    background-color: #0a855f;
}

.pay_type {
    background-color: #2C2C2C;
    color: #fff;
    font-size: 10px;
    padding: 2px 8px 1px;
    border-radius: 4px;
    font-family: "Poppins-regular";
    text-transform: uppercase;
}

.del-type {
    color: #444242;
    font-size: 12px;
    font-family: "Poppins-regular";
}

.o-date {
    font-family: "Poppins-light";
    font-size: 11px;
    color: #444242;
}

.os-img {
    width: 50px;
    height: 40px;
    border-radius: 4px;
}

.os-del-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.os-name {
    font-family: "Poppins-semi-bold";
    font-size: 13px;
    color: #000000;
    text-transform: capitalize;
}

.shop-div {
    background-color: #F3F6FC;
    padding: 8px 4px;
}

.os-sta {
    font-family: "Poppins-regular";
    font-size: 11px;
    color: #535353;
    padding-left: 4px;
    line-height: 12px;
    letter-spacing: 0.3px;
}

.os-icon {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #0057FF;
}

.line-h-17px {
    line-height: 17px;
}

.os-pname {
    color: #303030;
    font-size: 12px;
    font-family: "Poppins-regular";
    padding-left: 5px;
}

.os-qty {
    color: #949393;
    font-family: "Poppins";
    font-size: 12px;
    /* width: 35px; */
    padding-left: 5px;
}

.os-hr {
    color: #acacac;
}

.os-tot {
    color: #000000;
    font-family: "Poppins-semi-bold";
    font-size: 14px;
}

.os-status {
    color: #00991C;
    font-size: 12px;
    font-family: "Poppins";
}

.os-status-red {
    color: #FF0D11;
    font-size: 12px;
    font-family: "Poppins";
}

.os-status-red-sm {
    color: #FF0D11;
    font-size: 10px;
    font-family: "Poppins";
}

.os-rr-div {
    background-color: #0057ff;
    padding: 10px;
    border-radius: 4px;
}

.os-rr-border {
    border-right: 1px solid #fff;
}

.os-rate {
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-family: "Poppins-semi-bold";
    /* font-weight: 600; */
}

.os-rate-head {
    color: #777777;
    font-family: "Poppins-regular";
    font-size: 9px;
}

.os-rate-sub {
    color: #000;
    font-family: "Poppins-semi-bold";
    font-size: 11px;
    text-transform: capitalize;
}

.os-rr-border-left {
    border-left: 1px solid #000;

}

.os-dark {
    color: #050505;
    font-family: "Poppins-semi-bold";
    font-size: 13px;
}

.os-light {
    color: #444242;
    font-family: "Poppins-regular";
    font-size: 10px;
}

.overflowy-auto {
    overflow-y: auto o !important;
}

.max-w-55p {
    max-width: 55px;
}

.os-instra {
    font-size: 13px;
    font-family: "Poppins";
    text-transform: capitalize;
    letter-spacing: 0.1px;
}

.os-dialog-head {
    color: #000;
    font-family: "Poppins-semi-bold";
    text-transform: capitalize;
    letter-spacing: 0.1px;
    font-size: 18px;

}

.os-save-btn {
    background-color: #007AFF;
    color: #fff;
    text-transform: capitalize;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    border-radius: 6px !important;
}

.od-id {
    font-family: "Poppins";
    font-size: 12px;
    color: #000;
    font-weight: 600;
}

.od-status-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.od-status {
    font-size: 12px;
    font-family: "Poppins";
}

.blue-bg-clr {
    background-color: #007AFF;
}

.green-bg-clr {
    background-color: #00CB25;
}

.red-bg-clr {
    background-color: #FF0D11;
}

.green-clr {
    color: #00CB25;
}

.blue-clr {
    color: #007AFF;
}

.red-clr {
    color: #FF0D11;
}

.od-qty {
    font-size: 12px;
    font-family: "Poppins-semi-bold";
    color: #000;
}

.od-mrp {
    color: #777777;
    font-family: "Poppins";
    font-size: 10px;
}

.od-prod-name {
    color: #000;
    font-family: "Poppins-semi-bold";
    font-size: 12px;
}

.od-dark {
    color: #050505;
    font-family: "Poppins-semi-bold";
    font-size: 12px;
}

.od-sub-prod {
    color: #777777;
    font-family: "Poppins";
    font-size: 12px;
}

.od-head {
    color: #000;
    font-size: 15px;
    font-family: "Poppins-bold";
}

.od-track-step {
    display: flex;
    /* height: 6rem; */
}

.od-track-step:last-child {
    overflow: hidden;
    /* height: 7rem; */
}

.od-track-step:last-child .od-track-status span:last-of-type {
    display: none;
}

.od-track-step-timeline {
    display: flex;
    height: 4rem !important;
}

.od-track-step-timeline:last-child {
    overflow: hidden;
    /* height: 7rem; */
}

.cancel-head {
    font-family: "Poppins-bold";
    font-size: 18px;
    color: #000;
    text-transform: capitalize;
    padding-left: 0;
}

.cancel-sub {
    font-family: "Poppins";
    font-size: 14px;
    color: #2E2E2E;
}

.cancel-banner-img {
    height: 100px;
    width: auto;
}

.cancel-order-btn-gray {
    background-color: #EEEEEE;
    padding: 10px;
    border-radius: 10px;
    color: #888888;
    width: 100%;
    font-family: "Poppins-semi-bold" !important;
    font-size: 14px;
    text-transform: capitalize !important;
}

.cancel-order-btn-prime {
    background-color: #E0EBFF;
    padding: 10px;
    border-radius: 10px;
    color: #0057FF;
    width: 100%;
    font-family: "Poppins-semi-bold" !important;
    font-size: 14px;
    text-transform: capitalize !important;
}

.address-search-bar {
    border: 1px solid #DADADA !important;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.address-search-bar input::placeholder {
    color: #9D9D9D;
    font-size: 13px;
    font-family: "Poppins-regular";
    letter-spacing: 0.3px;
}

.custom-input-text {
    border: none !important;
    width: 95%;
    font-size: 13px;
    font-family: "Poppins-regular";
    letter-spacing: 0.3px;
    color: #000;
}

.address-search-bar input:focus {
    outline: none !important;
}

.loc-add {
    color: #000;
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 500;
    letter-spacing: 0.3px;
}

.loc-head {
    color: #000;
    font-size: 14px;
    font-family: "Poppins-semi-bold";
    font-weight: 500;
    letter-spacing: 0.1px;
}

.sub-head-text {
    font-size: 13px;
    font-family: "Poppins-regular";
    color: #585858;
    letter-spacing: 0.3px;
}


.modal.in .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.address-text {
    color: #6A6A6A;
    font-size: 13px;
    font-family: "Poppins-semi-bold";
    text-transform: capitalize;
    /* margin-bottom: 5px; */
}

.address-sub-text {
    font-family: "Poppins-regular";
    color: #888888;
    font-size: 12px;
}

.sub-text {
    font-family: "Poppins-regular";
    font-size: 12px;
    color: #777777;
}

.head-text {
    font-family: "Poppins-semi-bold";
    font-size: 16px;
    color: #000;

}

.small-text-primary {
    color: #76A5FF;
    font-size: 9px;
    font-family: "Poppins-regular";
}

.active-address-card {
    border: 1px solid #0057FF;
    background-color: #E0EBFF;
    padding: 6px 18px;
    color: #0057FF;
    border-radius: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    font-family: "Poppins";
}

.disable-address-card {
    border: 1px solid #D2D2D2;
    background-color: #fff;
    padding: 6px 18px;
    color: #353535;
    border-radius: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    font-family: "Poppins";
    opacity: 0.4;
}

.address-card {
    border: 1px solid #D2D2D2;
    background-color: #fff;
    padding: 6px 18px;
    color: #353535;
    border-radius: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    font-family: "Poppins";
}

.hr-text {
    display: flex;
    margin: 20px 20px 35px 20px;
    /* font-family: "Poppins-regular";
    color: #888888;
    font-size: 12px; */
}
.hr-text span{
    position: relative;
    top: 7px;
    font-family: "Poppins-regular";
    color: #888888;
    font-size: 12px;
}

.hr-text::before,
.hr-text::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.hr-text::before {
    margin-right: 10px;
}

.hr-text::after {
    margin-left: 10px;
}



.h-14px {
    height: 14px;
}


input.outside,
input[type=text].outside,
textarea.outside {
    color: #000000;
    width: 100%;
    font-size: 13px;
    height: 44px;
    line-height: normal;
    border: #ddd solid 1px;
    border-radius: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: -1px;
    padding: 10px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    z-index: 1;
    font-family: "Poppins";
}

input:focus,
textarea:focus,
select:focus {
    outline: 0 !important;
    color: #555 !important;
    border-color: #9e9e9e;
    /* z-index: 2 */
}

input:focus~.floating-label-outside input:not(:focus):valid~.floating-label-outside {
    top: 12px;
    left: 16px;
    font-size: 10px;
    opacity: 1;
    font-weight: 400
}

input:focus~.floating-label-outside,
input:valid~.floating-label-outside {
    top: -7px;
    font-size: 11px;
    color: #A6A6A6;
    background: #fff;
    padding: 0px 5px;
    font-family: "Poppins-regular";
}

input:focus~.floating-label-outside,
input:not(:focus):valid~.floating-label-outside {
    left: 12px
}

.floating-label-outside {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 14px;
    transition: .2s ease all;
    color: #afafaf;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .5px;
    z-index: 3;
    text-transform: capitalize;
    font-family: "Poppins-regular";

}

.input-icon-outside {
    position: absolute;
    top: 18px;
    left: 15px;
    z-index: 3;
    color: #727272;
}


.primary-button {
    background-color: #0057FF;
    color: #fff;
    font-family: "Poppins-semi-bold";
    width: 100%;
    border-radius: 10px;
    padding: 12px 16px;
    text-transform: uppercase;
    border: none;
    font-size: 13px;
    letter-spacing: 0.2px;
}

.disabled-button {
    background-color: #cccccc;
    color: #fff;
    font-family: "Poppins-semi-bold";
    width: 100%;
    border-radius: 10px;
    padding: 12px 16px;
    text-transform: uppercase;
    border: none;
    font-size: 13px;
}

.location-head {
    color: #000;
    font-size: 20px;
    font-family: "Poppins-semi-bold";
    text-transform: capitalize;
    line-height: 24px;
}

.align-items-flex-start {
    align-items: flex-start;
}

.search-dish-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;

}

.search-restaurant-img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
}

.active-tab-black {
    border-bottom: 3px solid #000000;

}

.rotate-180 {
    rotate: 180deg;
}

.rotate45 {
    rotate: 45deg;
}

.rotate90minus {
    rotate: -90deg;
}

.coupon-name {
    color: #00bc16;
    text-transform: uppercase;
    font-family: "Poppins-bold";
    font-size: 13px;
    font-weight: 600;
}

.text-tranform-none {
    text-transform: None !important;
}

.w-40px {
    width: 40px;
}

.h-auto {
    height: auto;
}

.border-right-none {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.border-radius-right {

    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.recent_search:last-child {
    border-bottom: none !important;
}

.new_mark_highlight {
    padding: 0.2em;
    background-color: #ece0a4;
}

.position-fixed-top50px {
    position: fixed;
    top: 50px;
    z-index: 1;
}

.radius-left-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-icon-absoulate {
    position: absolute;
    left: 16px;
    z-index: 100;
    margin-top: 11px;
}

.icon-padding {
    padding: 10px 30px !important;
}

.package-over-text {
    position: absolute;
    top: -10px;
    width: fit-content;
    padding: 2px 8px;
    background-color: #fff;
    font-weight: 500;
    font-size: 11px;
    left: 13px;
    margin-bottom: 0px !important;
    border-radius: 6px;
    letter-spacing: 0.3px;
    color: #444242;
    font-family: "Poppins-regular";
}

.fixed-top-60px {
    position: fixed;
    width: 80%;
    top: 60px;
}

.fixed-top-50px_search {
        position: fixed;
width: 30%;
top: 50px;
}

.mt-70px {
    margin-top: 70px;
}

/* .tablist .alice-carousel__stage-item {
    width: 35%!important;
} */

.order-img {
    object-fit: cover;
    border-radius: 6px;
    height: 51px;
}

.left-18 {
    left: 18px;
}

.r-0 {
    right: 0;
}

.l-10 {
    left: 10px;
}



.offerpercentage {
    rotate: -90deg;
    font-size: 18px;
    font-family: 'Poppins-semi-bold';
    color: #ffff;
    letter-spacing: 0.4px;
}

.border-left-radius10 {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.let-space_05 {
    letter-spacing: 0.5px;
}

.light-primary-btn {
    background-color: #e0ebff;
    color: #0057FF;
    border-radius: 10px;
    padding: 10px 6px;
    font-family: "Celias-medium";
}

.light-gray-btn {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 6px;
    font-family: "Celias-medium";
}

.overflowY-80vh {
    max-height: 90vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

.pb-2_8rem {
    padding-bottom: 2.8rem !important;
}

.pb-4rem {
    padding-bottom: 4rem !important;
}

.justify-content-space-between {
    justify-content: space-between;
}

.md-cart-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #333;
}

.md-cart-checkbox input[type="checkbox"] {
    display: none;
}

.md-cart-checkbox .checkmark {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid #9e9e9e;
    border-radius: 2px;
    margin-right: 12px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.md-cart-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: #6200ea;
    border-color: #6200ea;
}

.md-cart-checkbox .checkmark::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: width 0.3s ease, height 0.3s ease;
}

.md-cart-checkbox input[type="checkbox"]:checked+.checkmark::after {
    width: 12px;
    height: 6px;
}

.md-cart-checkbox .label-text {
    color: #424242;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.fixed-header-50px {
    position: fixed;
    top: 50px;
    width: 100%;
    background: #f5f6f8;

}

.side-menu-border-none {
    border: 0px;
    outline: 0px;
}

.l-2per {
    left: 2% !important;
}

.search_border-bottom {
    border-bottom: 1px solid #ccc;
}

.search_border-bottom:last-child {
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

.h-160px {
    height: 160px;
}

.feature_style_img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    color: #ffffff;
    opacity: 0.5;
}

.feature_style {
    font-size: 12px;
    font-family: "Poppins";
    color: #ffffff7d;
    letter-spacing: 0.5px;
}

.cupertino-pane-wrapper .pane {
box-shadow: var(--cupertino-pane-shadow, 0 4px 9px rgba(0,0,0,.12)) !important;
}

.user-img {
    width: 30px;
    height: 30px;
    border-radius: 6px;
}

.bottom-text {
    position: absolute;
    bottom: 20px;
}

.web-menu-icon {
    width: 22px;
    height: 22px;
}

.r-16px {
    right: 16px;
}

.l-16px {
    left: 16px;
}

.web-logo {
    width: 28px;
    height: 28px;
    margin: 5px;
}


.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .w-95per {
    width: 95%;
  }

  .w-5per {
    width: 5%;
  }

  .quick_access_style {
    width: 350px;
    right: 0px;
    position: fixed;
    height: 100vh;
    z-index: 20;
    position: absolute;
    border-radius: 6px;
    transition: position 0.7s, left 0.7s
    /* animation: ;
    animation-name: slide;
    animation-duration: 2s; */
  }
  
 .r-100px {
    right: 100px;
 }

 .r-85px {
    right: 85px;
 }

 .MuiBadge-badge {
    padding: 6px !important;
    font-size: 10px !important;
    width: 20px;
 }

 .position-sticky {
    position: sticky;
    top: 0;
 }

 .h-80vh {
    height: 80%;
 }

 .bottom-data {
    bottom: 0;
    position: fixed;
    margin-top: auto;
 }

 .t-0 {
    top: 0;
 }

 @media (min-width: 768px) {
    .page-content {
        /* margin-left: 220px; */
    }
}

.filter-invert {
    filter: invert(1);
}

.flip-icon {
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.prod-accordion .MuiAccordionSummary-root {
    min-height: 40px !important;
}

.align-baseline {
    align-items: baseline;
}

@media (min-width: 960px) {
    .makeStyles-paper-13 {
        left: 9px;
        max-width: 35% !important;
    }

    .makeStyles-paper-25 {
        max-width: 35% !important;
    }
}

.mt-100 {
    margin-top: 100px;
}

.box-even:nth-child(even) {
    padding-left: 0.5rem;
}

.box-even:nth-child(odd) {
    padding-left: 0;
}

@keyframes smooth-appear {
    to{
      bottom: 20px;
      opacity:1;
    }
  }

  .react-swipeable-view-container {
    /* scroll-behavior: smooth; */
  /* animation: smooth-appear 1s ease forwards;
  animation: 2s animation; */

  transition-property: opacity, left;
  transition-duration: 3s, 5s, 2s, 1s;
  }

  .prod-quick-view-name {
    line-height: 21px;
    letter-spacing: 0.3px;
    font-family: "Poppins" !important;
}

.w-250px {
    width: 250px !important;
}

.w-100vw {
    width: 100vw ;
}


.horizontal-scroll-box::after {
    scroll-behavior: smooth; /* Smooth scrolling effect */
    scrollbar-width: thin; /* Reduces scrollbar thickness */
  scrollbar-color: #888 #f1f1f1;
  }

.horizontal-scroll-box::-webkit-scrollbar {
    height: 8px; /* Adjust height for horizontal scrollbar */
  }
  
  .horizontal-scroll-box::-webkit-scrollbar-thumb {
    background: #888; /* Scroll bar color */
    border-radius: 10px;
  }
  
  .horizontal-scroll-box::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .horizontal-scroll-box::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }

  .bottom-fixed-30px {
    bottom: 30px;
    position: fixed;
    z-index: 9;
    left: auto;
    margin-left: -8px;
  }

  .bottom-fixed-40px {
    bottom: 40px;
    position: fixed;
    z-index: 9;
    left: 0;
  }

  .w-100px{
    width: 100px;
  }

  .w-150px {
    width: 150px;
  }

  .l-auto {
    left: auto !important;
  }

  .scroll-right {
    position: absolute;
    right: 0px;
    top: 70%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgb(255, 255, 255);
    color: rgb(23, 121, 30);
    /* border: 1px solid rgb(23, 121, 30); */
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: rgba(99, 99, 99, 0.29) 0px 2px 9px 0px !important;
    border: none;
  }

  .scroll-left {
    position: absolute;
    left: 0px;
    top: 70%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgb(255, 255, 255);
    color: rgb(23, 121, 30);
    /* border: 1px solid rgb(23, 121, 30); */
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: rgba(99, 99, 99, 0.29) 0px 2px 9px 0px !important;
    border: none;
  }

  .prod-varaint-img {
    width: 100%;
    height: 80px;
    background-color: #F2F7FF;
    border-radius: 6px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    div[ref="containerRef"]::-webkit-scrollbar {
      display: none;
    }
    div[ref="containerRef"] {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .Custom-prod-img {
        min-width: 160px !important;
        height: 130px;
        object-fit: contain;
                  flex-shrink: 0,
      }
  }

  .alice-carousel__stage {
    scroll-behavior: smooth;
  }

  .Custom-prod-img {
    min-width: 235px;
    width: 100px;
    height: 130px;
    object-fit: contain;
  }

  .top-relative {
    position: relative;
    top: 3px;
  }

  .bottom-relative {
    position: relative;
    bottom: 3px;
  }
  
  .prod-no-image-gray {
    width: 100%;
    height: 140px;
    object-fit: contain;
    /* background-color: #dfdfdf !important; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.text-light-black {
    color: #0C0C0C;
}

.attch-img-div { 
    width: 50px;
    height: 50px;
    border-radius: 6px;
}
.attch-img-div img {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    object-fit: cover;
    border: 2px solid #fff;
}

.w15px-icon {
    width: 15px;
    height: 15px;
}

.bg-status {
    padding: 3px 10px;
    border-radius: 6px;
    display: flex;
    color: #fff;
    font-weight: 600;
    justify-content: center;
    width: max-content;
    font-size: 11px;
}

.h-24px {
    height: 24px;
}

.send-div {    
    padding: 8px 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-right: 3px;
}

.send-div-icon{
    width: 16px;
    height: 16px;
}

.send-div.active {
    border: 1px solid #0057FF;
    background-color: #F0F5FF;
}

.package-input-style {
    background-color: #F4F5FA;
    border-radius: 10px;
    border: 1px solid #F4F5FA;
    padding: 8px 10px;
    width: 100%;
    font-family: "pop-medium";
    color: #0c0c0c;
    letter-spacing: 0.3px;
    font-weight: 500;
}

.package-input-style::placeholder {
    color: #777777;
    font-family: "Poppins-regular";
    font-weight: 400 !important;
    letter-spacing: 0.5px !important;
}

.package-input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .package-input-container input {
    background-color: #F4F5FA;
    border-radius: 10px;
    border: 1px solid #F4F5FA;
    padding: 8px 10px 8px 24px;
    color: white;
    width: 100%;

  }

  .package-input-container .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #000; /* Adjust icon color */
  }

  .attach-add-div {
    background-color: #F4F5FA;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 10px;
  }

  .attach-add {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: "poppins";
    background-color: #fff;
  }

  .attach-add-div img {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: 2px solid #fff;
  }

  .vertical-dot-line {
    height: 100%; /* Automatically adjust height */
    background-color: #bbb; /* Line color */
    margin: 0 auto; /* Center horizontally */
    display: block;
  }

  .w-35px {
    width: 35px;
  }

  .h-35px {
    height: 35px;
  }

  .close-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    top: -6px;
    right: -6px;
    cursor: pointer;
  }


  .share {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    /* right: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    z-index: 1;
  }

  .t-60 {
    top: 70px !important;
  }

  .alice-carousel {
    /* height: 300px; */
  }



  .border-prime {
    border: 1px solid #0057ff !important;
  }

  .t-130px {
    top: 130px;
  }

  .in_html ul p, .in_html p{
    font-family: "Poppins-regular" !important;
    font-size: 13px !important;
    color: gray !important;
    letter-spacing: 0.3px;
  } 

  .in_html strong {
    color: #000;
  }

  .row.equal-height .col-4 {
    height: max-content; /* Ensures the height matches */
    background-color: #000;
  }

  .scrollable-container {
    height: 100vh;
    overflow-y: auto;
  }

  .visible-none {
    visibility: hidden;
    height: 0;
    width: 0;
  }
  
  .addon-modal{
    min-height: 100vh;
    background-color: white;
  }

  .customize-sheet-varient {
    margin-top : 100px
  }

  @media (min-width: 768px){
    .cart-swipesheet-bottom-div{
        width: 35% !important;
    }
  }