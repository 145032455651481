.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.full {
    transition: opacity 400ms ease 0ms;
}

.lazy-load-thumb {
    /* filter: blur(1px); */
    /* transform: scale(1.1); */
    transition: visibility 0ms ease 400ms;
    visibility: visible;
    background: #e8e7e7;
    /* animation: skeleton-loading 1s linear infinite alternate; */
}

@keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 90%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }